import * as PropTypes from "prop-types";

import { Info, Delete, Add } from "@material-ui/icons";
import { Tooltip, IconButton } from "@material-ui/core";

import * as gbu from "../../GB/GBUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pic from "../NonComponents/PIConst";
import * as pip from "../NonComponents/PIProps";
import * as pisc from "../NonComponents/PIServerConst";
import * as piu from "../NonComponents/PIUtil";
import * as SC from "../../../data/strings/PIStringConst";
import * as Theme from "../../../app/Theme";

import { onCalculate } from "../NonComponents/PICalc";
import { RS } from "../../../data/strings/global";

export const PICustomRowTableProps = {
  allowEditsBoolC: "allowEditsBool",
};

export const firstRow = 0;
export const hiddenColOffset = 1; // offset columns by 1 since we're hiding the first column so we can type directly into the first visible column

export const sharedPropTypes = {
  [PICustomRowTableProps.allowEditsBoolC]: PropTypes.bool,

  [pias.onCalculatingChange]: PropTypes.func,
  [pias.onDialogChange]: PropTypes.func,

  [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
  [pias.onModVarsChange]: PropTypes.func,
  [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),

  [pip.tableKey]: PropTypes.string,
};

export const sharedDefaultProps = {
  [PICustomRowTableProps.allowEditsBoolC]: true,

  [pias.onCalculatingChange]: () => console.log(`PICustomRowsUtil: ${pias.onCalculatingChange}`),
  [pias.onDialogChange]: () => console.log(`PICustomRowsUtil: ${pias.onDialogChange}`),
  [pias.modVarObjList]: [],
  [pias.onModVarsChange]: () => console.log(`PICustomRowsUtil: ${pias.onModVarsChange}`),
  [pias.origModVarObjArr]: [],
};

export const initialState = {
  /* Note: Fixed rows are NOT included. All numbers are zero-based. */
  [pip.focusedCell]: {
    [pip.rowFocus]: 0,
    [pip.colFocus]: 1 + hiddenColOffset,
  },
  [pip.selectedRegions]: [
    {
      [pip.rowStart]: 0,
      [pip.rowEnd]: 0,
      [pip.columnStart]: 1 + hiddenColOffset,
      [pip.columnEnd]: 1 + hiddenColOffset,
    },
  ],
  [pip.rDec]: [],
};

export const getDeleteBtnCol = (props, lastColumn) => {
  let colNum = -1;

  const allowEditsBool = props[PICustomRowTableProps.allowEditsBoolC];

  if (allowEditsBool) {
    colNum = lastColumn + 1;
  }

  return colNum;
};

export const getNumCols = (props, lastColumn) => {
  let colNum = -1;

  const allowEditsBool = props[PICustomRowTableProps.allowEditsBoolC];

  if (allowEditsBool) {
    colNum = getDeleteBtnCol(props, lastColumn) + 1;
  } else {
    colNum = lastColumn + 1;
  }

  return colNum;
};

export const AddButton = ({ itemType, props, state, setState }) => (
  <IconButton
    key={"addCurveBtn"}
    aria-label={"add"}
    onClick={() => onAddCurveBtnClick(itemType, props, state, setState)}
    style={{
      marginLeft: "25px",
      marginTop: "10px",
      background: Theme.PI_TertiaryColor,
      color: "#FFFFFF",
    }}
  >
    <Add />
  </IconButton>
);

const onAddCurveBtnClick = (itemType, props, state, setState) => {
  try {
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onModVarsChange = props[pias.onModVarsChange];
    const onDialogChange = props[pias.onDialogChange];
    const origModVarObjArr = gbu.cloneObj(props[pias.origModVarObjArr]);
    const modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);

    const focusedCell = gbu.cloneObj(state[pip.focusedCell]);
    const selectedRegions = gbu.cloneObj(state[pip.selectedRegions]);
    let rDec = gbu.cloneObj(state[pip.rDec]);

    let mstID2 = "";
    if (itemType === pic.priorPopItems) {
      if (piu.itemVariesByMethod(itemType)) {
        mstID2 = piasu.getModVarValue(modVarObjListClone, pisc.selectedMethodMVTag);
      }
    }

    const itemObjList = piasu.getItemModVarValue(itemType, modVarObjListClone);
    const maxNumItems = piu.getMaxNumItems(itemType);
    const customItemCurrID1DIntArray = piasu.getCustomItemCurrIDArray(itemType, itemObjList, mstID2);

    setState(
      {
        [pip.focusedCell]: focusedCell,
        [pip.selectedRegions]: selectedRegions,
        [pip.rDec]: rDec,
      },
      () => {
        if (customItemCurrID1DIntArray.length < maxNumItems) {
          const itemToAddAfterCurrID = piasu.getTotalNumItems(itemType, itemObjList, mstID2);
          piasu.addCustomItem(itemType, modVarObjListClone, origModVarObjArr, itemToAddAfterCurrID, mstID2);

          onModVarsChange(modVarObjListClone, true, () => {
            onCalculatingChange(true, () => {
              onCalculate(
                modVarObjListClone,
                "",
                onDialogChange,
                (response) => {
                  onModVarsChange(response, false, () => {
                    onCalculatingChange(false);
                  });
                },
                () => onCalculatingChange(false)
              );
            });
          });
        } else {
          let dialogObj = pias.getDefaultDialogObj();
          dialogObj[pias.contentStr] = RS(SC.GB_stMaxNumItemsIs).replace(pic.numItemsStr, maxNumItems);
          dialogObj[pias.headerStr] = RS(SC.GB_stNote);
          dialogObj[pias.maxWidthStr] = "sm";
          dialogObj[pias.showBool] = true;
          dialogObj[pias.styleObj] = { width: 500 };

          onDialogChange(dialogObj);
        }
      }
    );
  } catch (exception) {
    alert(exception.name + ": " + exception.message);
  }
};

export const DeleteButton = ({ id, row, itemType, props, state, setState }) => (
  <IconButton key={id} aria-label={"delete"} onClick={() => onDeleteBtnClick(row, itemType, props, state, setState)}>
    <Delete
      style={{
        color: Theme.PI_TertiaryColor,
      }}
    />
  </IconButton>
);

const onDeleteBtnClick = (row, itemType, props, state, setState) => {
  try {
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onModVarsChange = props[pias.onModVarsChange];
    const onDialogChange = props[pias.onDialogChange];
    const origModVarObjArr = gbu.cloneObj(props[pias.origModVarObjArr]);
    let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);

    let focusedCell = gbu.cloneObj(state[pip.focusedCell]);
    let selectedRegions = gbu.cloneObj(state[pip.selectedRegions]);
    let rDec = gbu.cloneObj(state[pip.rDec]);

    let itemObjList = piasu.getItemModVarValue(itemType, modVarObjListClone);
    const numItems = piasu.getTotalNumItems(itemType, itemObjList, "");

    if (typeof rDec !== "undefined") {
      rDec.splice(row, 1);
    }

    setState(
      {
        [pip.focusedCell]: focusedCell,
        [pip.selectedRegions]: selectedRegions,
        [pip.rDec]: rDec,
      },
      () => {
        /* If there is at least two items, continue (otherwise, the server will break). */
        if (numItems > 1) {
          const removedItemCurrID1DIntArray = [];
          removedItemCurrID1DIntArray.push(row);

          piasu.deleteCustomItems(itemType, modVarObjListClone, origModVarObjArr, removedItemCurrID1DIntArray);

          // The following setTimeout is a complete and utter hack
          // If you set the time to 0 you will see that the supertable tries to rerender at the wrong time and throws an error
          // But if you set it to 500, it works fine
          setTimeout(() => {
            onModVarsChange(modVarObjListClone, true, () => {
              onCalculatingChange(true, () => {
                onCalculate(
                  modVarObjListClone,
                  "",
                  onDialogChange,
                  (response) => {
                    onModVarsChange(response, false, () => {
                      onCalculatingChange(false);
                    });
                  },
                  () => onCalculatingChange(false)
                );
              });
            });
          }, 500);
        } else {
          let dialogObj = pias.getDefaultDialogObj();
          dialogObj[pias.contentStr] = RS(SC.GB_stOneContCurveReq);
          dialogObj[pias.headerStr] = RS(SC.GB_stError);
          dialogObj[pias.maxWidthStr] = "sm";
          dialogObj[pias.showBool] = true;
          dialogObj[pias.styleObj] = { width: 500 };

          onDialogChange(dialogObj);
        }
      }
    );
  } catch (exception) {
    alert(exception.name + " (PICustomRowsUtil.onDeleteBtnClick) : " + exception.message);
  }
};

export const InformationTooltip = ({ id, message, color }) => (
  <Tooltip key={id} title={message}>
    <div>
      <Info
        style={{
          color: color ?? Theme.PI_PrimaryColor,
        }}
      />
    </div>
  </Tooltip>
);

export const updateStateOnPackTableChange = (
  setState,
  newState,
  onCalculatingChange,
  onModVarsChange,
  modVarObjListClone,
  onCalculate,
  onDialogChange
) => {
  setState(newState, () => {
    onCalculatingChange(true, () => {
      onModVarsChange(modVarObjListClone, false, () => {
        onCalculate(
          modVarObjListClone,
          "",
          onDialogChange,
          (response) => {
            onModVarsChange(response, false, () => {
              onCalculatingChange(false);
            });
          },
          () => onCalculatingChange(false)
        );
      });
    });
  });
};
