import React, { Component } from "react";
import * as PropTypes from "prop-types";
import TComboBox from "../common/TComboBox";
import * as gbtu from "./GBTableUtil";

import SuperTableShim from "../common/SuperTableShim";

class GbStdTableWithComboBoxes extends Component {
  static propTypes = {
    packTable: PropTypes.object.isRequired,
    types: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    headerBackgroundColor: PropTypes.string,
    subheadingRowBackgroundColor: PropTypes.string,
    oddRowBackgroundColor: PropTypes.string,
    rounded: PropTypes.bool,
    font: PropTypes.string,
    onPackTableChanged: PropTypes.func.isRequired,
    onCellValueChanged: PropTypes.func,
    onCheckboxToggled: PropTypes.func,
    onPrecisionChanged: PropTypes.func,
    onDuplicateAcrossRows: PropTypes.func,
    onDuplicateDownColumns: PropTypes.func,
    onInterpolate: PropTypes.func,
    onMultiply: PropTypes.func,
    onNormalize: PropTypes.func, //mark
    onSourceRequested: PropTypes.func,
    onSelectCells: PropTypes.func, // mark2
    style: PropTypes.object,
    pagination: PropTypes.bool,
    paginationPageSize: PropTypes.number,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onCellClicked: PropTypes.func,
    filterText: PropTypes.string,
    showTitle: PropTypes.bool,
    readOnly: PropTypes.bool,
    wrapperClassNames: PropTypes.string,
    autosizeColumns: PropTypes.bool,
    banding: PropTypes.bool,
    removedMenuNames: PropTypes.arrayOf(PropTypes.string),
    customContextMenu: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        action: PropTypes.func,
        disabled: PropTypes.bool,
      })
    ),
    frameworkComponents: PropTypes.object,
    captionStyles: PropTypes.object,
    disablePopupMenu: PropTypes.bool,

    comboBoxStyle: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
    comboBoxInfo: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any))),
    comboBoxItems: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))),
    comboBoxIndices: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    comboBoxDisabled: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.bool)),
    debug: PropTypes.bool,
    onComboBoxChange: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.func)),
  };

  static defaultProps = {
    rounded: false,
    pagination: false,
    paginationPageSize: 3,
    font: "14px Arial",
    width: "100%",
    // height: "auto",
    onCellClicked: () => {},
    filterText: "",
    showTitle: true,
    readOnly: false,
    autosizeColumns: false,
    banding: true,
    subheadingRowBackgroundColor: "#CCCCCC",
    captionStyles: {},
    onSelectCells: () => {},
    disablePopupMenu: false,

    /* For comboboxes */
    comboBoxStyle: {},
    comboBoxDisabled: undefined, // others should be this too, but don't want to check them all right now to make sure nothing breaks
    comboBoxInfo: [],
    comboBoxItems: [],
    comboBoxIndices: [],
    onComboBoxChange: [],
  };

  state = {
    suppressPackTableChangedNotification: false,
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  renderGbStdTableWithComboBoxes = () => {
    let props = this.props;
    let packTable = props.packTable;

    let frameworkComponents = {};
    if (props.frameworkComponents) {
      frameworkComponents = props.frameworkComponents;
    }

    const numRows = gbtu.getNumRows(packTable);
    const numCols = gbtu.getNumCols(packTable);

    const GetComboBox = ({ row, col, comboBoxKey }) => {
      /* Needs to be declared here again or the combobox will use the props from before
               onComboBoxChange gets fired.  */
      const props = this.props;

      const contCurveComboBox = (
        <TComboBox
          onChange={(...params) => {
            console.log(params);

            props.onComboBoxChange[row][col]?.(...params);
          }}
          info={props.comboBoxInfo[row][col]}
          items={props.comboBoxItems[row][col]}
          itemIndex={props.comboBoxIndices[row][col]}
          key={comboBoxKey}
          style={props.comboBoxStyle[row][col]}
          disabled={props.comboBoxDisabled && props.comboBoxDisabled[row][col]}
        />
      );

      return contCurveComboBox;
    };

    for (let r = 0; r < numRows; r++) {
      for (let c = 0; c < numCols; c++) {
        const hasComboBox = gbtu.getHasComboBox(packTable, r, c);

        if (hasComboBox) {
          /* Need to save these into variables before passing or the combobox will
                       be sent the wrong row, col. */
          const rowPassed = r;
          const colPassed = c;

          const comboBoxKey = "comboBox_ row" + rowPassed + "_ col" + colPassed;

          const comboBox = (props) => <GetComboBox row={rowPassed} col={colPassed} comboBoxKey={comboBoxKey} />;
          frameworkComponents = gbtu.addFrameworkComponent(frameworkComponents, comboBoxKey, comboBox);
          packTable = gbtu.setComponent(packTable, r, c, comboBoxKey);
          gbtu.lockCell(packTable, r, c, true);
        }
      }
    }

    return (
      <SuperTableShim
        {...props}
        frameworkComponents={frameworkComponents}
        onPackTableChanged={(packTable) => {
          if (this.state.suppressPackTableChangedNotification) {
            this.setState({
              suppressPackTableChangedNotification: false,
            });

            return;
          }

          props.onPackTableChanged?.(packTable);
        }}
        onCellValueChanged={(params) => {
          if (params.type === "dd") {
            this.setState({
              suppressPackTableChangedNotification: true,
            });
          }
          const rowIndex = params.rowIndex;
          const columnIndex = params.columnIndex;

          const options = props.comboBoxItems[rowIndex][columnIndex];
          // eslint-disable-next-line eqeqeq
          const optionIndex = options.findIndex((option) => option == params.updatedValue);

          const information = props.comboBoxInfo[rowIndex][columnIndex];

          if (params.type === "dd") {
            props.onComboBoxChange[rowIndex][columnIndex](optionIndex, params.updatedValue, information[optionIndex]);
          }
        }}
      />
    );
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  render() {
    try {
      return <React.Fragment>{this.renderGbStdTableWithComboBoxes()}</React.Fragment>;
    } catch (exception) {
      return (
        <div
          style={{
            marginLeft: 20,
            marginTop: 20,
          }}
        >
          <h3>{exception.name + ": " + exception.message}</h3>
        </div>
      );
    }
  }
}

export default GbStdTableWithComboBoxes;
