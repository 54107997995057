import * as gbc from "../../GB/GBConst";
//import * as pic from "./PIConst";
import * as php from "./PIHelp";
//import * as gbu from "../../GB/GBUtil";
import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

/* Defines App State (AS) - Constants below greatly reduce chance of error and makes it faster to specify
   things in component props. Alphabetical order helps make things easier to find. */

/* onChange events  - General */

export const onAddTasks = "onAddTasks";
//export const onAllInputMVsLoaded   = "onAllInputMVsLoaded";
export const onCalculatingChange = "onCalculatingChange";
export const onCalcContCurvePercOnPrEPChange = "onCalcContCurvePercOnPrEPChange";
export const onDrawerChange = "onDrawerChange";
export const onModVarChange = "onModVarChange";
export const onModVarsChange = "onModVarsChange";
export const onOrigModVarsChange = "onOrigModVarsChange";
export const onPageChange = "onPageChange";
export const onRunInitialRESTCalls = "onRunInitialRESTCalls";
export const onSideBarArrowClick = "onSideBarArrowClick";
export const onStateChange = "onStateChange";

/* onChange events - App specific */

export const onCountryChange = "onCountryChange";
export const onDialogChange = "onDialogChange";
export const onDialogClose = "onDialogClose";
export const onHelpAreaChange = "onHelpAreaChange";
export const onHelp = "onHelp";
export const onShowRedirectChange = "onShowRedirectChange";
export const onValidationItemChange = "onValidationItemChange";

/* Constants  -- General */

export const apiTasks = "apiTasks";
export const appState = "appState";
export const drawerID1DStrArray = "drawerID1DStrArray";
export const drawerOpen1DBoolArray = "drawerOpen1DBoolArray";
export const failedToLoad = "failedToLoad";
export const heartBeat = "heartBeat";
export const key = "key";
export const loading = "loading";
export const pageID = "pageID";
export const screenSize = "screenSize";
export const sideBarOpen = "sideBarOpen";
export const wideModeBool = "wideModeBool";

/* Constants -- App specific */

export const calculatingBool = "calculatingBool";
export const countryObjList = "countryObjList";
export const helpAreaStr = "helpAreaStr";
export const modVarObjList = "modVarObjList";
export const origModVarObjArr = "origModVarObjArr";
export const recalcBool = "recalcBool";
export const showHelp = "showHelp";
export const helpPage = "helpPage";
export const dialogObj = "dialogObj";
export const captionStr = "captionStr";
export const showBool = "showBool";
export const actions1DStrArr = "actions1DStrArr";
export const closeBtnCaptionStr = "closeBtnCaptionStr";
export const onCloseEvent = "onCloseEvent";
export const contentStr = "contentStr";
export const keyStr = "keyStr";
export const maxWidthStr = "maxWidthStr";
export const styleObj = "styleObj";
export const modalBool = "modalBool";
export const visibleBool = "visibleBool";
export const headerStr = "headerStr";
export const tableKeyObj = "tableKeyObj";
export const validationItem = "validationItem";
export const allInputMVsLoadedBool = "allInputMVsLoadedBool";
export const calcContCurvePercOnPrEPBool = "calcContCurvePercOnPrEPBool";
export const showRedirectBool = "showRedirectBool";
export const versionStr = "versionStr";

/* Table keys */
export const priorPopsTableKey = "priorPopsTableKey";
export const methodUnitsTableKey = "methodUnitsTableKey";
export const contCurvePercOnPrEPTableKey = "contCurvePercOnPrEPTableKey";
export const assignContCurvesToPriorPopsTableKey = "assignContCurvesToPriorPopsTableKey";
export const persMinDetTableKey = "persMinDetTableKey";
export const assignMinPatToStratDetTableKey = "assignMinPatToStratDetTableKey";
export const assignSchedToStratDetTableKey = "assignSchedToStratDetTableKey";
export const assignVisitToSchedDetTableKey = "assignVisitToSchedDetTableKey";
export const assignTrendsPopsDetTableKey = "assignTrendsPopsDetTableKey";
export const scaleUpTrendsTableKey = "scaleUpTrendsTableKey";
export const contVisitSchedTableKey = "contVisitSchedTableKey";
export const assignContSchedToPriorPopLiteTableKey = "assignContSchedToPriorPopLiteTableKey";
export const assignContVisitsToSchedLiteTableKey = "assignContVisitsToSchedLiteTableKey";
export const assignAboveAnnualCostsTableKey = "assignAboveAnnualCostsTableKey";
export const AGYWGeoPriorityTableKey = "AGYWGeoPriorityTableKey";
export const customItemTableKey = "customItemTableKey";
export const percReachedDetTableKey = "percentReachedDetTableKey";
export const covConstraintsTableKey = "covConstraintsTableKey";
export const optionsTargTableKey = "optionsTargTableKey";
export const assignImpPriorPopsToPriorPopsTableKey = "assignImpPriorPopsToPriorPopsTableKey";
export const disagPriorPopTableKey = "disagPriorPopTableKey";
export const PrEPEfficacyAGYWTableKey = "PrEPEfficacyAGYWTableKey";

/* 'Constructor' */

export function createAppState() {
  return {
    [allInputMVsLoadedBool]: false,
    [calcContCurvePercOnPrEPBool]: false,
    [countryObjList]: [],
    [drawerID1DStrArray]: ["", "", ""],
    [drawerOpen1DBoolArray]: [false, false, false],
    [failedToLoad]: false,
    [helpAreaStr]: php.welcome_HP,
    [helpPage]: "",
    [modVarObjList]: [],
    [origModVarObjArr]: [],
    [recalcBool]: false,
    [screenSize]: gbc.lgScreen,
    [dialogObj]: getDefaultDialogObj(),
    [showHelp]: false,
    [showRedirectBool]: false,
    [sideBarOpen]: false,
    [tableKeyObj]: {
      [contCurvePercOnPrEPTableKey]: "",
      [assignContCurvesToPriorPopsTableKey]: "",
      [persMinDetTableKey]: "",
      [assignMinPatToStratDetTableKey]: "",
      [assignSchedToStratDetTableKey]: "",
      [assignVisitToSchedDetTableKey]: "",
      [assignTrendsPopsDetTableKey]: "",
      [scaleUpTrendsTableKey]: "",
      [assignContSchedToPriorPopLiteTableKey]: "",
      [assignContVisitsToSchedLiteTableKey]: "",
      [AGYWGeoPriorityTableKey]: "",
      [customItemTableKey]: "",
      [percReachedDetTableKey]: "",
      [covConstraintsTableKey]: "",
      [optionsTargTableKey]: "",
      [assignImpPriorPopsToPriorPopsTableKey]: "",
      [disagPriorPopTableKey]: "",
      [PrEPEfficacyAGYWTableKey]: "",
    },
    [validationItem]: "",
    [versionStr]: "",
    [wideModeBool]: false,
  };
}

export function getDefaultDialogObj() {
  return {
    [actions1DStrArr]: ["mrClose"],
    [captionStr]: "",
    [closeBtnCaptionStr]: RS(SC.GB_stClose),
    [onCloseEvent]: () => {},
    [contentStr]: [],
    [headerStr]: "",
    [keyStr]: "dialogObj",
    [maxWidthStr]: "md",
    [modalBool]: true,
    [showBool]: false,
    [styleObj]: {
      width: 700,
    },
    [visibleBool]: true,
  };
}
