import { makeStyles } from "@material-ui/core";
import MDEditor from "@uiw/react-md-editor";
import PropTypes from "prop-types";
import { RS } from "../../../data/strings/global";
import useStore from "../../../hooks/useStore";
import DraggableWindow from "../../common/DraggableWindow";

const useStyles = makeStyles((theme) => ({
  component: {
    fontWeight: "normal",
    "& .document": {
      height: "calc(100% - 65px) !important",
    },
  },
  icon: {
    color: theme.palette.primary.main,
  },
  window: {
    minWidth: 600,
  },
}));

const NoteEditor = (props) => {
  const [noteEditorOpen, activeNote, updateNoteEditorOpen, updateActiveNote] = useStore((state) => [
    state.noteEditorOpen,
    state.notes[state.activeArea],
    state.updateNoteEditorOpen,
    state.updateActiveNote,
  ]);

  const classes = useStyles();

  return (
    <div data-color-mode="light" className={classes.component}>
      <DraggableWindow
        open={noteEditorOpen}
        anchorRef={props.anchorEl}
        defaultPositionOffset={{
          x: -10,
          y: 74,
        }}
        title={RS("GB_stNotes")}
        height={400}
        onClose={() => {
          updateNoteEditorOpen(false);
        }}
        onDragStart={() => {}}
        onDragStop={() => {}}
      >
        <MDEditor
          height="100%"
          value={activeNote}
          preview="edit"
          visibleDragbar={false}
          onChange={updateActiveNote}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onMouseUp={(e) => {
            e.stopPropagation();
          }}
          onTouchStart={(e) => {
            e.stopPropagation();
          }}
          onTouchEnd={(e) => {
            e.stopPropagation();
          }}
        />
      </DraggableWindow>
    </div>
  );
};

NoteEditor.propTypes = {
  anchorEl: PropTypes.object,
};

NoteEditor.defaultProps = {
  value: "",
};

export default NoteEditor;
