import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as pip from "../NonComponents/PIProps";
import * as gbtu from "../../GB/GBTableUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";

import GbStdTableWithComboBoxes from "../../GB/GbStdTableWithComboBoxes";
import * as pitu from "../NonComponents/PITableUtil";

import { generateHeterogeneousTypes } from "../../../utilities";

const priorPopCurrIDC = "priorPopCurrID";
const methodCurrIDC = "methodCurrID";

class PIAssignScaleUpTrendsToPriorPopsTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pip.tableKey]: PropTypes.string,

    [pias.onDialogChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: PropTypes.func,

    [pip.tableKey]: "",

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),
  };

  state = {
    /* Note: Fixed rows are NOT included. All numbers are zero-based. */
    [pip.focusedCell]: {
      [pip.rowFocus]: 0,
      [pip.colFocus]: 1,
    },
    [pip.selectedRegions]: [
      {
        [pip.rowStart]: 0,
        [pip.rowEnd]: 0,
        [pip.columnStart]: 1,
        [pip.columnEnd]: 1,
      },
    ],
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onScaleUpTrendComboBoxChange = (value, text, info) => {
    const props = this.props;
    const onModVarsChange = props[pias.onModVarsChange];

    let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
    let priorPopObjList = piasu.getModVarValue(modVarObjListClone, pisc.priorPopsMVTag);
    let scaleUpTrendsObjList = piasu.getModVarValue(modVarObjListClone, pisc.scaleUpTrendsMVTag);

    const scaleUpTrendCurrID = value + 1;
    const priorPopCurrID = info[priorPopCurrIDC];
    const methodCurrID = info[methodCurrIDC];

    const mstID = piasu.getScaleUpTrendMstID(scaleUpTrendsObjList, scaleUpTrendCurrID);
    piasu.setPriorPopScaleUpTrendMstID(priorPopObjList, methodCurrID, priorPopCurrID, mstID);

    onModVarsChange(modVarObjListClone, false);
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      let stdTable;

      const props = this.props;

      const modVarObjList = props[pias.modVarObjList];
      const tableKey = props[pip.tableKey];

      const scaleUpTrendsObjList = piasu.getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);
      const priorPopObjList = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      const methodObjList = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopMethodEligMVTag);

      const state = this.state;

      let packTable = gbtu.getNewPackTable();

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);
      const numMethods = piasu.getTotalNumMethods(methodObjList);
      const numRows = numPriorPops + 1; // includes one header

      const firstRow = 0;

      const priorPopCol = 0;
      const numCols = numMethods + 1;

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Set column headings */
      gbtu.setValue(packTable, firstRow, priorPopCol, RS(SC.GB_stPriorityPop));

      for (let m = 1; m <= numMethods; m++) {
        gbtu.setValue(packTable, firstRow, m, piasu.methodName(methodObjList, m));
      }

      let comboBoxObj = pitu.getComboBoxObj(numRows, numCols);

      for (let pp = 1; pp <= numPriorPops; pp++) {
        /* Set row headings. */
        const priorPopName = piasu.getPriorPopName(priorPopObjList, pp);
        gbtu.setValue(packTable, pp, priorPopCol, priorPopName);

        for (let m = 1; m <= numMethods; m++) {
          const methodMstID = piasu.methodMstID(methodObjList, m);
          const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, methodMstID, pp);

          if (methodEligMstIDStr !== pisc.yesCVOMstID) {
            gbtu.lockCell(packTable, pp, m, true, true);
            const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
            gbtu.setCellBGColor(packTable, pp, m, gainsboroBase10);

            continue;
          }

          gbtu.setHasComboBox(packTable, pp, m, true);

          comboBoxObj[pitu.style2DObjArray][pp][m] = {
            color: Theme.blackColor,
            fontFamily: Theme.fontFamily,
          };

          let priorPopCurrIDArray = [];
          priorPopCurrIDArray.length = piasu.getTotalNumScaleUpTrends(scaleUpTrendsObjList);
          priorPopCurrIDArray.fill({
            [priorPopCurrIDC]: pp,
            [methodCurrIDC]: m,
          });
          comboBoxObj[pitu.info3DIntArray][pp][m] = priorPopCurrIDArray;

          comboBoxObj[pitu.item3DStrArray][pp][m] = piasu.getScaleUpTrendNames(scaleUpTrendsObjList);

          const scaleUpTrendMstID = piasu.getPriorPopScaleUpTrendMstID(priorPopObjList, m, pp);
          const scaleUpTrendCurrID = piasu.getScaleUpTrendCurrID(scaleUpTrendsObjList, scaleUpTrendMstID); // markProblem
          comboBoxObj[pitu.idx2DIntArray][pp][m] = scaleUpTrendCurrID - 1;

          comboBoxObj[pitu.onChange2DFuncArray][pp][m] = this.onScaleUpTrendComboBoxChange;
        }
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 0);
      gbtu.setColWidths(packTable, Theme.itemNameColWidth);
      gbtu.setColWidth(packTable, priorPopCol, Theme.itemNameColWidthWide);
      gbtu.setWordWrappedCols(packTable, true);
      gbtu.setMinAllowedValForTable(packTable, 0);
      gbtu.setMaxAllowedValForTable(packTable, 0);
      gbtu.setID(packTable, "assignScaleUpTrendsToPriorPopsPackTable");
      gbtu.setKey(packTable, "assignScaleUpTrendsToPriorPopsPackTable");

      if (window.DebugMode) {
        console.log("Component: PIAssignScaleUpTrendsToPriorPopsTable");
        console.log("ModVar(s):");
        console.log(pisc.scaleUpTrendsMVTag);
        console.log(scaleUpTrendsObjList);
        console.log(pisc.priorPopsMVTag);
        console.log(priorPopObjList);
        console.log("");
      }

      stdTable = (
        <GbStdTableWithComboBoxes
          focusedCell={state[pip.focusedCell]}
          onCellFocused={(focusedCell) => pitu.onCellFocused(this, focusedCell)}
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          gridKey={tableKey}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateHeterogeneousTypes(packTable, "dd")}
          onPackTableChanged={() => {}}
          removedMenuNames={pitu.tableHideMenuItems}
          selectedRegions={state[pip.selectedRegions]}
          onSelectionChanged={(selectedRegions) => pitu.onSelectionChanged(this, selectedRegions)}
          style={{
            tableFont: Theme.tableFont,
            marginLeft: Theme.leftIndent,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
          comboBoxStyle={comboBoxObj[pitu.style2DObjArray]}
          comboBoxInfo={comboBoxObj[pitu.info3DIntArray]}
          comboBoxItems={comboBoxObj[pitu.item3DStrArray]}
          comboBoxIndices={comboBoxObj[pitu.idx2DIntArray]}
          onComboBoxChange={comboBoxObj[pitu.onChange2DFuncArray]}
          width={0}
          limitToTableWidth={true}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIAssignScaleUpTrendsToPriorPopsTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIAssignScaleUpTrendsToPriorPopsTable;
