import * as PropTypes from "prop-types";
import * as React from "react";

import TAppPage from "../../APP/TAppPage";

import ErrorBoundaryPage from "../../common/ErrorBoundaryPage";

import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pic from "../NonComponents/PIConst";
import * as pip from "../NonComponents/PIProps";
import * as pisc from "../NonComponents/PIServerConst";

import PIAggregateForm from "../Forms/PIAggregateForm";
import PIAGYWForm from "../Forms/PIAGYWForm";
import PICapacityForm from "../Forms/PICapacityForm";
import PICommoditiesForecastingForm from "../Forms/PICommoditiesForecastingForm";
import PIConfigForm from "../Forms/PIConfigForm";
import PICostsLiteForm from "../Forms/PICostsLiteForm";
import PIDisagTargetsForm from "../Forms/PIDisagTargetsForm";
import PIGettingStartedForm from "../Forms/PIGettingStartedForm";
import PIImpactForm from "../Forms/PIImpactForm";
import PIResourcesForm from "../Forms/PIResourcesForm";
import PIResultsDashboardForm from "../Forms/PIResultsDashboardForm";
import PITargetsForm from "../Forms/PITargetsForm";
import PITestForm from "../Forms/PITestForm";
import PIWelcomeForm from "../Forms/PIWelcomeForm";

class PIAppContent extends React.Component {
  //==================================================================================================================
  //
  //                                             Props and State
  //
  //==================================================================================================================

  static propTypes = {
    [pip.appSideBarWidth]: PropTypes.number,

    [pias.appState]: PropTypes.object,

    [pias.onDialogChange]: PropTypes.func,
    [pias.onDrawerChange]: PropTypes.func,
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.onCalcContCurvePercOnPrEPChange]: PropTypes.func,

    [pias.onCountryChange]: PropTypes.func,

    [pias.onHelpAreaChange]: PropTypes.func,
    [pias.onHelp]: PropTypes.func,

    [pias.onModVarChange]: PropTypes.func,
    [pias.onModVarsChange]: PropTypes.func,
    [pias.onOrigModVarsChange]: PropTypes.func,

    [pias.onPageChange]: PropTypes.func,

    [pias.onRunInitialRESTCalls]: PropTypes.func,

    [pias.onSideBarArrowClick]: PropTypes.func,

    [pias.onValidationItemChange]: PropTypes.func,

    language: PropTypes.string,
  };

  static defaultProps = {
    [pip.appSideBarWidth]: 0,

    [pias.appState]: {},

    [pias.onDialogChange]: () => console.log(`PIAppContent: ${pias.onDialogChange}`),
    [pias.onDrawerChange]: () => console.log(`PIAppContent: ${pias.onDrawerChange}`),
    [pias.onCalculatingChange]: () => console.log(`PIAppContent: ${pias.onCalculatingChange}`),

    [pias.onCalcContCurvePercOnPrEPChange]: () => console.log(`PIAppContent: ${pias.onCalcContCurvePercOnPrEPChange}`),

    [pias.onCountryChange]: () => console.log(`PIAppContent: ${pias.onCountryChange}`),

    [pias.onHelpAreaChange]: () => console.log(`PIAppContent: ${pias.onHelpAreaChange}`),
    [pias.onHelp]: () => console.log(pias.onHelp),

    [pias.onModVarChange]: () => console.log(`PIAppContent: ${pias.onModVarChange}`),
    [pias.onModVarsChange]: () => console.log(`PIAppContent: ${pias.onModVarsChange}`),

    [pias.onOrigModVarsChange]: () => console.log(`PIAppContent: ${pias.onOrigModVarsChange}`),

    [pias.onPageChange]: () => console.log(`PIAppContent: ${pias.onPageChange}`),

    [pias.onRunInitialRESTCalls]: () => console.log(pias.onRunInitialRESTCalls),

    [pias.onSideBarArrowClick]: () => console.log(pias.onSideBarArrowClick),

    [pias.onValidationItemChange]: () => console.log(`PIAppContent: ${pias.onValidationItemChange}`),
  };

  //==================================================================================================================
  //
  //                                                Events
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                Render
  //
  //==================================================================================================================

  renderFormLv1 = () => {
    let formLv1 = null;

    /* props */
    const props = this.props;

    const appSideBarWidth = props[pip.appSideBarWidth];
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onCalcContCurvePercOnPrEPChange = props[pias.onCalcContCurvePercOnPrEPChange];
    const onCountryChange = props[pias.onCountryChange];
    const onDialogChange = props[pias.onDialogChange];
    const onDrawerChange = props[pias.onDrawerChange];
    const onHelpAreaChange = props[pias.onHelpAreaChange];
    const onHelp = props[pias.onHelp];
    const onModVarChange = props[pias.onModVarChange];
    const onModVarsChange = props[pias.onModVarsChange];
    const onOrigModVarsChange = props[pias.onOrigModVarsChange];
    const onPageChange = props[pias.onPageChange];
    const onRunInitialRESTCalls = props[pias.onRunInitialRESTCalls];
    const onSideBarArrowClick = props[pias.onSideBarArrowClick];
    const onValidationItemChange = props[pias.onValidationItemChange];

    /* appState */
    const appState = props[pias.appState];

    const calcContCurvePercOnPrEPBool = appState[pias.calcContCurvePercOnPrEPBool];
    const countryObjList = appState[pias.countryObjList];
    const helpAreaStr = appState[pias.helpAreaStr];
    const modVarObjList = appState[pias.modVarObjList];
    const origModVarObjArr = appState[pias.origModVarObjArr];
    const pageID = appState[pias.pageID];
    const screenSize = appState[pias.screenSize];
    const sideBarOpen = appState[pias.sideBarOpen];
    const tableKeyObj = appState[pias.tableKeyObj];
    const validationItem = appState[pias.validationItem];

    let contCurveObjList = undefined;
    let HIVPrev1DFltArray = undefined;
    let percIndicPrEP1DFltArray = undefined;
    let percPrEPElig1DFltArray = undefined;
    let popSize1DFltArray = undefined;
    let potUsersToTakePrEP1DFltArray = undefined;
    let priorityPopObjList = undefined;
    let progDataSettingPeriodObj = undefined;
    let scaleUpTrendsObjList = undefined;
    let targClientsInit1DFltArray = undefined;
    let targSetOptionMstIDStr = undefined;
    let targSettingPeriodObj = undefined;
    let totalCosts1DIntArray = undefined;
    let totalInits1DIntArray = undefined;

    let countryISO3Str = "";

    if (piasu.getModVarValue(modVarObjList, pisc.allInputMVsLoadedMVTag) && piasu.getCountrySelected(modVarObjList)) {
      const countryCodeInt = piasu.getModVarValue(modVarObjList, pisc.countryISOMVTag);
      countryISO3Str = piasu.getCountryISO3(countryObjList, countryCodeInt);

      /* If Elric asks "what's the tag", here's the place to find out. */
      contCurveObjList = piasu.getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);
      HIVPrev1DFltArray = piasu.getModVarValue(modVarObjList, pisc.percentHIVPrevalenceMVTag);
      percIndicPrEP1DFltArray = piasu.getModVarValue(modVarObjList, pisc.percentForPrepMVTag);
      percPrEPElig1DFltArray = piasu.getModVarValue(modVarObjList, pisc.percentPrepEligibleMVTag);
      popSize1DFltArray = piasu.getModVarValue(modVarObjList, pisc.priorityPopSizeMVTag);
      potUsersToTakePrEP1DFltArray = piasu.getModVarValue(modVarObjList, pisc.coverageByPriorityPopMVTag);
      priorityPopObjList = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      progDataSettingPeriodObj = piasu.getProgDataPeriodObj(modVarObjList);
      scaleUpTrendsObjList = piasu.getModVarValue(modVarObjList, pisc.scaleUpTrendsMVTag);
      targSetOptionMstIDStr = piasu.getModVarValue(modVarObjList, pisc.targetSettingMVTag);
      targSettingPeriodObj = piasu.getTargSettingPeriodObj(modVarObjList);
      targClientsInit1DFltArray = piasu.getModVarValue(modVarObjList, pisc.targetsByPriorityPopMVTag);
      totalCosts1DIntArray = piasu.getModVarValue(modVarObjList, pisc.costsByPopTypeMVTag);
      totalInits1DIntArray = piasu.getModVarValue(modVarObjList, pisc.initByPopTypeMVTag);
    }

    const styles = {
      appPage: {
        left: appSideBarWidth,
      },
    };

    /* Note: In general, it's better to only pass down what we need. However, with the
           modVarObjList, we have several ModVars that affect each other and calculations
           currently calculate all ModVars and give them all back. These two things mean
           it's better to just pass all the ModVars for now and worry about optimization later
           if and when we have time. */
    switch (pageID) {
      case pic.resourcesForm:
        formLv1 = <PIResourcesForm {...{}} />;
        break;

      case pic.gettingStartedForm:
        formLv1 = (
          <PIGettingStartedForm
            {...{
              [pias.onCalculatingChange]: onCalculatingChange,

              [pias.countryObjList]: countryObjList,

              [pias.onDialogChange]: onDialogChange,

              [pias.modVarObjList]: modVarObjList,
              [pias.onModVarChange]: onModVarChange,
              [pias.onModVarsChange]: onModVarsChange,

              [pias.origModVarObjArr]: origModVarObjArr,
              [pias.onOrigModVarsChange]: onOrigModVarsChange,

              [pias.onPageChange]: onPageChange,
            }}
            language={props.language}
          />
        );
        break;

      case pic.welcomeForm:
        formLv1 = (
          <PIWelcomeForm
            {...{
              [pip.appSideBarWidth]: appSideBarWidth,
              [pias.onPageChange]: onPageChange,
              [pias.screenSize]: screenSize,
              [pias.onSideBarArrowClick]: onSideBarArrowClick,
              [pias.sideBarOpen]: sideBarOpen,
              [pias.modVarObjList]: modVarObjList,
              [pias.onModVarsChange]: onModVarsChange,
              [pias.onRunInitialRESTCalls]: onRunInitialRESTCalls,
              [pias.helpAreaStr]: helpAreaStr,
              [pias.onHelpAreaChange]: onHelpAreaChange,
            }}
          />
        );
        break;

      case pic.configForm:
        formLv1 = (
          <PIConfigForm
            {...{
              [pias.onDialogChange]: onDialogChange,
              [pias.onDrawerChange]: onDrawerChange,
              [pias.onCalculatingChange]: onCalculatingChange,

              [pias.calcContCurvePercOnPrEPBool]: calcContCurvePercOnPrEPBool,
              [pias.onCalcContCurvePercOnPrEPChange]: onCalcContCurvePercOnPrEPChange,

              [pias.countryObjList]: countryObjList,
              [pias.onCountryChange]: onCountryChange,

              [pias.helpAreaStr]: helpAreaStr,
              [pias.onHelpAreaChange]: onHelpAreaChange,
              [pias.onHelp]: onHelp,

              [pias.modVarObjList]: modVarObjList,
              [pias.origModVarObjArr]: origModVarObjArr,
              [pias.onModVarChange]: onModVarChange,
              [pias.onModVarsChange]: onModVarsChange,

              [pias.onPageChange]: onPageChange,

              [pias.tableKeyObj]: tableKeyObj,
            }}
          />
        );
        break;

      case pic.targetsForm:
        formLv1 = (
          <PITargetsForm
            {...{
              [pias.onCalculatingChange]: onCalculatingChange,

              [pip.contCurveObjList]: contCurveObjList,

              [pias.onDialogChange]: onDialogChange,

              [pias.onDrawerChange]: onDrawerChange,

              [pip.HIVPrev1DFltArray]: HIVPrev1DFltArray,

              [pias.helpAreaStr]: helpAreaStr,
              [pias.onHelpAreaChange]: onHelpAreaChange,
              [pias.onHelp]: onHelp,

              [pip.countryISO3Str]: countryISO3Str,

              [pias.modVarObjList]: modVarObjList,
              [pias.origModVarObjArr]: origModVarObjArr,
              [pias.onModVarsChange]: onModVarsChange,

              [pias.onPageChange]: onPageChange,

              [pip.percIndicPrEP1DFltArray]: percIndicPrEP1DFltArray,

              [pip.percPrEPElig1DFltArray]: percPrEPElig1DFltArray,

              [pip.popSize1DFltArray]: popSize1DFltArray,

              [pip.potUsersToTakePrEP1DFltArray]: potUsersToTakePrEP1DFltArray,

              [pip.priorPopObjList]: priorityPopObjList,

              [pip.progDataPeriodObj]: progDataSettingPeriodObj,

              [pip.scaleUpTrendsObjList]: scaleUpTrendsObjList,

              [pias.tableKeyObj]: tableKeyObj,

              [pip.targClientsInit1DFltArray]: targClientsInit1DFltArray,

              [pip.targSetOptionMstIDStr]: targSetOptionMstIDStr,

              [pip.targSettingPeriodObj]: targSettingPeriodObj,

              [pip.totalCosts1DIntArray]: totalCosts1DIntArray,

              [pip.totalInits1DIntArray]: totalInits1DIntArray,

              [pias.validationItem]: validationItem,
              [pias.onValidationItemChange]: onValidationItemChange,
            }}
          />
        );
        break;

      case pic.disagTargetsForm:
        formLv1 = (
          <PIDisagTargetsForm
            {...{
              [pias.onCalculatingChange]: onCalculatingChange,

              [pias.helpAreaStr]: helpAreaStr,
              [pias.onHelpAreaChange]: onHelpAreaChange,
              [pias.onHelp]: onHelp,

              [pias.modVarObjList]: modVarObjList,
              [pias.origModVarObjArr]: origModVarObjArr,
              [pias.onModVarsChange]: onModVarsChange,

              [pias.onPageChange]: onPageChange,

              [pias.tableKeyObj]: tableKeyObj,

              [pias.onDialogChange]: onDialogChange,
            }}
          />
        );
        break;

      case pic.costsLiteForm:
        formLv1 = (
          <PICostsLiteForm
            {...{
              [pias.onCalculatingChange]: onCalculatingChange,

              [pias.onDialogChange]: onDialogChange,

              [pias.helpAreaStr]: helpAreaStr,
              [pias.onHelpAreaChange]: onHelpAreaChange,
              [pias.onHelp]: onHelp,

              [pias.modVarObjList]: modVarObjList,
              [pias.origModVarObjArr]: origModVarObjArr,
              [pias.onModVarsChange]: onModVarsChange,

              [pias.onPageChange]: onPageChange,

              [pias.tableKeyObj]: tableKeyObj,

              [pias.validationItem]: validationItem,
              [pias.onValidationItemChange]: onValidationItemChange,
            }}
          />
        );
        break;

      case pic.impactForm:
        formLv1 = (
          <PIImpactForm
            {...{
              [pias.onCalculatingChange]: onCalculatingChange,

              [pias.helpAreaStr]: helpAreaStr,
              [pias.onHelpAreaChange]: onHelpAreaChange,
              [pias.onHelp]: onHelp,

              [pias.modVarObjList]: modVarObjList,
              [pias.origModVarObjArr]: origModVarObjArr,
              [pias.onModVarsChange]: onModVarsChange,

              [pias.onPageChange]: onPageChange,

              [pias.tableKeyObj]: tableKeyObj,

              [pias.onDialogChange]: onDialogChange,
            }}
          />
        );
        break;

      case pic.AGYW_Form:
        formLv1 = (
          <PIAGYWForm
            {...{
              [pias.onCalculatingChange]: onCalculatingChange,

              [pip.countryISO3Str]: countryISO3Str,

              [pias.helpAreaStr]: helpAreaStr,
              [pias.onHelpAreaChange]: onHelpAreaChange,

              [pias.modVarObjList]: modVarObjList,
              [pias.origModVarObjArr]: origModVarObjArr,
              [pias.onModVarsChange]: onModVarsChange,

              [pias.onPageChange]: onPageChange,

              [pias.tableKeyObj]: tableKeyObj,

              [pias.onDialogChange]: onDialogChange,
            }}
          />
        );
        break;

      case pic.resultsDashboardForm:
        formLv1 = (
          <PIResultsDashboardForm
            {...{
              [pias.onCalculatingChange]: onCalculatingChange,

              [pias.onDialogChange]: onDialogChange,

              [pias.helpAreaStr]: helpAreaStr,
              [pias.onHelpAreaChange]: onHelpAreaChange,

              [pias.modVarObjList]: modVarObjList,
              [pias.origModVarObjArr]: origModVarObjArr,
              [pias.onModVarsChange]: onModVarsChange,

              [pias.onPageChange]: onPageChange,
            }}
          />
        );
        break;

      case pic.capacityForm:
        formLv1 = (
          <PICapacityForm
            {...{
              [pias.onCalculatingChange]: onCalculatingChange,

              [pias.onDialogChange]: onDialogChange,

              [pias.helpAreaStr]: helpAreaStr,
              [pias.onHelpAreaChange]: onHelpAreaChange,

              [pias.modVarObjList]: modVarObjList,
              [pias.origModVarObjArr]: origModVarObjArr,
              [pias.onModVarChange]: onModVarChange,
              [pias.onModVarsChange]: onModVarsChange,

              [pias.onPageChange]: onPageChange,

              [pias.tableKeyObj]: tableKeyObj,
            }}
          />
        );
        break;

      case pic.commoditiesForecastForm:
        formLv1 = (
          <PICommoditiesForecastingForm
            {...{
              [pias.onCalculatingChange]: onCalculatingChange,

              [pias.onDialogChange]: onDialogChange,

              [pias.helpAreaStr]: helpAreaStr,
              [pias.onHelpAreaChange]: onHelpAreaChange,

              [pias.modVarObjList]: modVarObjList,
              [pias.origModVarObjArr]: origModVarObjArr,
              [pias.onModVarChange]: onModVarChange,
              [pias.onModVarsChange]: onModVarsChange,

              [pias.onPageChange]: onPageChange,

              [pias.tableKeyObj]: tableKeyObj,
            }}
          />
        );
        break;

      case pic.aggregateForm:
        formLv1 = (
          <PIAggregateForm
            {...{
              [pias.onDialogChange]: onDialogChange,

              [pias.helpAreaStr]: helpAreaStr,
              [pias.onHelpAreaChange]: onHelpAreaChange,

              [pias.modVarObjList]: modVarObjList,
              [pias.origModVarObjArr]: origModVarObjArr,
              [pias.onModVarsChange]: onModVarsChange,

              [pias.onPageChange]: onPageChange,
            }}
          />
        );
        break;

      case pic.testForm:
        formLv1 = (
          <PITestForm
            {...{
              [pias.modVarObjList]: modVarObjList,
              [pias.origModVarObjArr]: origModVarObjArr,
              [pias.onModVarsChange]: onModVarsChange,

              [pias.onPageChange]: onPageChange,
            }}
          />
        );
        break;

      default:
        formLv1 = <TAppPage style={styles.appPage}>The specified form could not be found.</TAppPage>;
        break;
    }

    return formLv1;
  };

  renderPage = () => {
    /* props */

    const props = this.props;
    const appSideBarWidth = props[pip.appSideBarWidth];

    /* appState */

    const appState = props[pias.appState];
    const pageID = appState[pias.pageID];

    const styles = {
      appPage: {
        left: appSideBarWidth,
      },
    };

    const formLv1 = this.renderFormLv1();

    return (
      <div
        className={"PIAppContent"}
      >
        <ErrorBoundaryPage key={pageID}>
          <TAppPage key={pageID} style={styles.appPage}>
            <div
              style={{
                zIndex: 1,
              }}
            >
              {formLv1}
            </div>
          </TAppPage>
        </ErrorBoundaryPage>
      </div>
    );
  };

  render() {
    const page = this.renderPage();

    return page;
  }
}

export default PIAppContent;
