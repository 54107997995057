import { useEffect, useRef } from "react";
import useStore from "../../../hooks/useStore";
import NoteEditor from "./NoteEditor";
import PIMain from "./PIMain";

const MainWrapper = (props) => {
  const ref = useRef();

  const [updateActiveArea, notes, updateNotes] = useStore((state) => [
    state.updateActiveArea,
    state.notes,
    state.updateNotes,
  ]);

  useEffect(() => {
    const key = props.appState.helpAreaStr?.replace(/HP$/, "AREA") ?? "unknown_AREA";

    updateActiveArea(key);
  }, [props.appState.helpAreaStr, updateActiveArea]);

  return (
    <>
      <div ref={ref}>
        <PIMain {...props} notes={notes} onNotesChange={updateNotes} />
      </div>

      <NoteEditor anchorEl={ref} />
    </>
  );
};

export default MainWrapper;
