import { makeRequest, makeRESTRequest, makeUpload } from "../components/API/makeRequest";
import axios from "axios";

import { promisify } from "../utilities";
import * as pic from "../components/PI/NonComponents/PIConst";
import { constantCovMstID, ninetyX3CovMstID } from "../components/PI/NonComponents/PIServerConst";

/* Show console.log messages for each step (debugging purposes) */
const CONSOLE_LOGGING = false;

let API_URL = window.API_URL;
export let API_URL_BASE = window.API_URL_BASE;

//======================================================================================================================
//
//                                             HELPER CALLS
//
//======================================================================================================================

function uuidv4() {
  let crypto = window.crypto || window.msCrypto;

  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    // eslint-disable-next-line no-mixed-operators
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
}

export const CreateAPITask = (flag, params, success, error) => {
  let guid = "{" + uuidv4() + "}";

  return {
    flag: flag,
    params: params,
    successFn: success,
    errorFn: error,
    GUID: guid,
    running: false,
    complete: false,
  };
};

//======================================================================================================================
//
//                                             SERVER CALLS
//
//======================================================================================================================

let CLIENT_ID = window.CLIENT_ID;

export const LoginService = {
  getAccountInfo: (params, successFn, errorFn) =>
    makeRESTRequest("GBLoginService.getAccountInfo", {}, {}, successFn, errorFn),

  getStatus: (params, successFn, errorFn) => makeRESTRequest("GBLoginService.Ping", {}, {}, successFn, errorFn),

  signedIn: (params, successFn, errorFn) => makeRESTRequest("GBLoginService.SignedIn", {}, {}, successFn, errorFn),

  login: (params, successFn, errorFn) =>
    makeRESTRequest(
      "GBLoginService.Login",
      {},
      {
        appID: "AIM",
        username: params.username,
        password: params.password,
      },
      successFn,
      errorFn
    ),

  loginAsGuest: (params, successFn, errorFn) =>
    makeRESTRequest(
      "GBLoginService.Login",
      {},
      {
        appID: "AIM",
        username: "guest",
        password: 123456,
      },
      successFn,
      errorFn
    ),

  logout: (params, successFn, errorFn) => makeRESTRequest("GBLoginService.Logout", {}, {}, successFn, errorFn),
};

export const SpectrumService = {
  keepAlive: (params, successFn, errorFn) => makeRESTRequest("GBSpectrumService.Alive", {}, {}, successFn, errorFn),

  getEverything: (params, successFn, errorFn) =>
    makeRESTRequest("GBSpectrumService.GetEverything", {}, {}, successFn, errorFn),

  getActiveProjectionList: (params, successFn, errorFn) =>
    makeRESTRequest("GBSpectrumService.GetActiveProjectionList", {}, {}, successFn, errorFn),

  getStoredProjectionList: (params, successFn, errorFn) =>
    makeRESTRequest("GBSpectrumService.GetStoredProjectionList", {}, {}, successFn, errorFn),

  //------------------------------------------------------------------------------------------------------------------

  getGBData: (params, successFn, errorFn) =>
    makeRESTRequest(
      "GBSpectrumService.GetGB",
      {},
      {},
      (response) => {
        delete response.CountryRegionObjList;
        successFn(response);
      },
      errorFn
    ),

  getModvars: (params, successFn, errorFn) =>
    makeRESTRequest(
      "GBSpectrumService.GetModvars",
      {},
      {
        modvars: params.arr,
      },
      successFn,
      errorFn
    ),

  setModvars: (params, successFn, errorFn) =>
    makeRESTRequest(
      "GBSpectrumService.SetModvars",
      {},
      {
        modvars: params.arr,
      },
      successFn,
      errorFn
    ),

  getEditors: (params, successFn, errorFn) =>
    makeRequest(
      API_URL,
      CLIENT_ID,
      "GBSpectrumService.GetEditors",
      {},
      {
        editors: params.arr,
      },
      successFn,
      errorFn
    ),

  setEditors: (params, successFn, errorFn) =>
    makeRESTRequest(
      "GBSpectrumService.SetEditors",
      {},
      {
        editors: params.arr,
      },
      successFn,
      errorFn
    ),

  getResultsData: (params, successFn, errorFn) =>
    makeRESTRequest(
      "GBSpectrumService.GetResults",
      {},
      {
        results: params.arr,
      },
      successFn,
      errorFn
    ),

  //------------------------------------------------------------------------------------------------------------------

  upload: (file, mySuccess, myError) => makeUpload(API_URL_BASE, CLIENT_ID, file, mySuccess, myError),

  createProjection: (params, successFn, errorFn) =>
    makeRESTRequest(
      "GBSpectrumService.CreateProjection",
      {},
      {
        countryISO: params.CountryISO,
        firstYear: params.FirstYear,
        finalYear: params.FinalYear,
        fileName: params.FileName,
        modList: params.ModList,
      },
      successFn,
      errorFn
    ),

  openProjection: (params, successFn, errorFn) =>
    makeRESTRequest(
      "GBSpectrumService.OpenProjection",
      {},
      {
        fileTitle: params.FileTitle,
      },
      successFn,
      errorFn
    ),

  saveProjection: (params, successFn, errorFn) =>
    makeRESTRequest(
      "GBSpectrumService.SaveProjection",
      {},
      {
        proj: params.Proj,
        fileTitle: params.FileTitle,
      },
      successFn,
      errorFn
    ),

  closeProjection: (params, successFn, errorFn) =>
    makeRESTRequest(
      "GBSpectrumService.CloseProjection",
      {},
      {
        proj: params.Proj,
      },
      successFn,
      errorFn
    ),

  downloadProjection: (params, successFn, errorFn) =>
    makeRESTRequest(
      "GBSpectrumService.DownloadProjection",
      {},
      {
        fileTitle: params.FileTitle,
      },
      successFn,
      errorFn
    ),

  deleteProjection: (params, successFn, errorFn) =>
    makeRESTRequest(
      "GBSpectrumService.DeleteProjection",
      {},
      {
        fileTitle: params.FileTitle,
      },
      successFn,
      errorFn
    ),

  calculateProjection: (params, successFn, errorFn) =>
    makeRESTRequest(
      "GBSpectrumService.CalculateProjection",
      {},
      {
        proj: params.Proj,
      },
      successFn,
      errorFn
    ),

  getResultTable: (params, successFn, errorFn) =>
    makeRESTRequest(
      "GBSpectrumService.GetResultTable",
      {},
      {
        proj: params.proj,
        modID: params.modID,
        resultID: params.resultID,
        // chartOptions : ChartOptions
      },
      successFn,
      errorFn
    ),

  getAllResults: (params, successFn, errorFn) =>
    makeRESTRequest("GBSpectrumService.getAllResults", {}, {}, successFn, errorFn),

  getResultMenus: (params, successFn, errorFn) =>
    makeRESTRequest("GBSpectrumService.GetResultMenus", {}, {}, successFn, errorFn),
};

export const RESTService = {
  ping: (params, successFn, errorFn) => makeRESTRequest("RESTService.Ping", {}, {}, successFn, errorFn),

  getVersion: (params, successFn, errorFn) => makeRESTRequest("RESTService.GetVersion", {}, {}, successFn, errorFn),

  getRunServices: (params, successFn, errorFn) =>
    makeRESTRequest(
      "RESTService.RunServices",
      {},
      {
        serviceList: params.serviceList,
      },
      successFn,
      errorFn
    ),

  getCountries: (params, successFn, errorFn) =>
    makeRESTRequest(
      "RESTService.GetCountries",
      {},
      {
        modID: params.modID,
        ignoreSampleCountry: true,
      },
      successFn,
      errorFn
    ),
};

export const getCountries = (params, successFn, errorFn) => {
  let startTime = Date.now();
  let url = window.API_URL_BASE;
  let data = JSON.stringify(params);
  let method = "PI/Countries";

  if (CONSOLE_LOGGING) {
    console.log({
      _: "SEND",
      methodName: method,
      url: url,
      data: data,
      timeSent: Date(startTime).toString(),
    });
  }

  /* "put" will put the response contents into response.config.data. */
  axios
    .create({
      baseURL: url, //"http://localhost",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .get("/" + method)
    .then((response) => {
      //let dataIn = JSON.parse(response.data);
      let result = response.data;

      if (typeof result === "undefined") {
        result = undefined;
      } else if (typeof result === "string" && (result.charAt(0) === "{" || result.charAt(0) === "[")) {
        result = JSON.parse(response.data);
      } else {
        result = response.data;
      }

      if (CONSOLE_LOGGING) {
        let output = {};
        output._ = "GET";
        output.methodName = method;
        output.data = result; //dataIn;
        output.result = result;
        output.Time = Date.now() - startTime;
        output.timeReceived = Date(Date.now().toString());
        console.log(output);
        console.log(""); // for readability
      }

      successFn(result);
    })
    .catch((e) => {
      if (typeof errorFn !== "undefined") {
        errorFn(e);
      }
      console.log(e);
    });
};

export const getProjDefaults = (params, successFn, errorFn) => {
  let startTime = Date.now();
  let url = window.API_URL_BASE;
  let data = JSON.stringify(params);
  let method = "PI/Data/" + params[pic.countryCode]; //"Countries";

  if (CONSOLE_LOGGING) {
    console.log({
      _: "SEND",
      methodName: method,
      url: url,
      data: data,
      timeSent: Date(startTime).toString(),
    });
  }

  /* "put" will put the response contents into response.config.data. */
  axios
    .create({
      baseURL: url, //"http://localhost",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .get("/" + method)
    .then((response) => {
      //let dataIn = JSON.parse(response.data);
      let result = response.data;

      if (typeof result === "undefined") {
        result = undefined;
      } else if (typeof result === "string" && (result.charAt(0) === "{" || result.charAt(0) === "[")) {
        result = JSON.parse(response.data);
      } else {
        result = response.data;
      }

      if (CONSOLE_LOGGING) {
        let output = {};
        output._ = "GET";
        output.methodName = method;
        output.data = result; //dataIn;
        output.result = result;
        output.Time = Date.now() - startTime;
        output.timeReceived = Date(Date.now().toString());
        console.log(output);
        console.log(""); // for readability
      }

      successFn(result);
    })
    .catch((e) => {
      if (typeof errorFn !== "undefined") {
        errorFn(e);
      }
      console.log(e);
    });
};

export const getProjDefaultsAsync = promisify(getProjDefaults);

export const makeRequestByGET = (url, methodName, params, successFn, errorFn) => {
  let startTime = Date.now();
  let data = JSON.stringify(params);

  if (CONSOLE_LOGGING) {
    console.log({
      _: "SEND",
      methodName: methodName,
      url: url,
      data: JSON.parse(data),
      timeSent: Date(startTime).toString(),
    });
  }

  axios
    .create({
      baseURL: window.API_URL_BASE, //url,//"http://localhost",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .get(url) //"/" + methodName)
    .then((response) => {
      //let dataIn = JSON.parse(response.data);
      let result = response.data;

      if (typeof result === "undefined") {
        result = undefined;
      } else if (typeof result === "string" && (result.charAt(0) === "{" || result.charAt(0) === "[")) {
        result = JSON.parse(response.data);
      } else {
        result = response.data;
      }

      if (CONSOLE_LOGGING) {
        let output = {};
        output._ = "GET";
        output.methodName = methodName;
        output.data = result; //dataIn;
        output.result = result;
        output.Time = Date.now() - startTime;
        output.timeReceived = Date(Date.now().toString());
        console.log(output);
        console.log(""); // for readability
      }

      successFn(result);
    })
    .catch((e) => {
      if (typeof errorFn !== "undefined") {
        errorFn(e);
      }
      console.log(e);
    });
};

export const makeRequestByPUT = (url, methodName, params, successFn, errorFn) => {
  let startTime = Date.now();
  let data = JSON.stringify(params);

  if (CONSOLE_LOGGING) {
    console.log({
      _: "SEND",
      methodName: methodName,
      url: url,
      data: JSON.parse(data),
      timeSent: Date(startTime).toString(),
    });
  }

  axios
    .create({
      baseURL: url,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .put(url, data)
    .then((response) => {
      let dataIn = JSON.parse(response.config.data);
      let result = response.data;

      if (typeof result === "undefined") {
        result = undefined;
      } else if (typeof result === "string" && (result.charAt(0) === "{" || result.charAt(0) === "[")) {
        result = JSON.parse(response.data);
      } else {
        result = response.data;
      }

      if (CONSOLE_LOGGING) {
        let output = {};
        output._ = "GET";
        output.methodName = methodName;
        output.data = dataIn;
        output.result = result;
        output.Time = Date.now() - startTime;
        output.timeReceived = Date(Date.now().toString());
        console.log(output);
        console.log(""); // for readability
      }

      successFn(result);
    })
    .catch((e) => {
      if (typeof errorFn !== "undefined") {
        errorFn(e);
      } else {
        alert(e);
      }
    });
};

export const makeRequestByPUTParamStr = (url, methodName, file, successFn, errorFn) => {
  let startTime = Date.now();

  if (CONSOLE_LOGGING) {
    console.log({
      _: "SEND",
      methodName: methodName,
      url: url,
      data: file,
      timeSent: Date(startTime).toString(),
    });
  }

  const formData = new FormData();

  formData.append("file", file);

  axios
    .create({
      baseURL: url,
    })
    .put(url, formData)
    .then((response) => {
      let result = response.data;

      if (typeof result === "undefined") {
        result = undefined;
      } else if (typeof result === "string" && (result.charAt(0) === "{" || result.charAt(0) === "[")) {
        result = JSON.parse(response.data);
      } else {
        result = response.data;
      }

      if (CONSOLE_LOGGING) {
        let output = {};
        output._ = "GET";
        output.methodName = methodName;
        //output.data = dataIn;
        output.result = result;
        output.Time = Date.now() - startTime;
        output.timeReceived = Date(Date.now().toString());
        console.log(output);
        console.log(""); // for readability
      }

      successFn(result);
    })
    .catch((e) => {
      if (typeof errorFn !== "undefined") {
        errorFn(e);
      } else {
        alert(e);
      }
    });
};

export const makeRequestPyPOST = (url, methodName, params, successFn, errorFn) => {
  let startTime = Date.now();
  let data = JSON.stringify(params);

  if (CONSOLE_LOGGING) {
    console.log({
      _: "SEND",
      methodName: methodName,
      url: url,
      data: JSON.parse(data),
      timeSent: Date(startTime).toString(),
    });
  }

  axios
    .create({
      baseURL: url,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .post(url, data)
    .then((response) => {
      let dataIn = JSON.parse(response.config.data);
      let result = response.data;

      if (typeof result === "undefined") {
        result = undefined;
      } else if (typeof result === "string" && (result.charAt(0) === "{" || result.charAt(0) === "[")) {
        result = JSON.parse(response.data);
      } else {
        result = response.data;
      }

      if (CONSOLE_LOGGING) {
        let output = {};
        output._ = "GET";
        output.methodName = methodName;
        output.data = dataIn;
        output.result = result;
        output.Time = Date.now() - startTime;
        output.timeReceived = Date(Date.now().toString());
        console.log(output);
        console.log(""); // for readability
      }

      successFn(result);
    })
    .catch((e) => {
      if (typeof errorFn !== "undefined") {
        errorFn(e);
      } else {
        alert(e);
      }
    });
};

export const makeRequestPyPOSTAsync = promisify(makeRequestPyPOST)

export const makeFileRequestPost = (url, methodName, params, fileName, successFn, errorFn) => {
  let startTime = Date.now();
  let data = JSON.stringify(params);

  if (CONSOLE_LOGGING) {
    console.log({
      _: "SEND",
      methodName: methodName,
      url: url,
      data: JSON.parse(data),
      timeSent: Date(startTime).toString(),
    });
  }

  axios
    .create({
      baseURL: url,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .post(url, data)
    .then((response) => {
      let csvText = response.data;
      let csvContent = "data:text/csv;charset=utf-8," + csvText;
      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", fileName);
      document.body.appendChild(link); // Required for FF
      link.click();

      if (CONSOLE_LOGGING) {
        let output = {};
        output._ = "GET";
        output.methodName = methodName;
        output.result = csvText;
        output.Time = Date.now() - startTime;
        output.timeReceived = Date(Date.now().toString());
        console.log(output);
        console.log(""); // for readability
      }

      if (typeof successFn !== "undefined") {
        successFn(csvContent);
      }
    })
    .catch((e) => {
      if (typeof errorFn !== "undefined") {
        errorFn(e);
      } else {
        alert(e);
      }
    });
};

export const makeFileRequestPostXLSX = (url, methodName, params, fileName, successFn, errorFn) => {
  let startTime = Date.now();
  let data = JSON.stringify(params);

  if (CONSOLE_LOGGING) {
    console.log({
      _: "SEND",
      methodName: methodName,
      url: url,
      data: JSON.parse(data),
      timeSent: Date(startTime).toString(),
    });
  }

  axios
    .create({
      url: url,
      responseType: "blob", // important
    })
    .post(url, JSON.parse(data))
    .then((response) => {
      /* If the file is successfully created, there's nothing useful to return. However,
               if an exception occurred, response.data will say "application/json" instead of
               the having the excel file. If that's the case, return an appropriate object from
               this function so we know an exception occurred. */
      if (response.data.type === "application/json" && typeof successFn !== "undefined") {
        successFn({
          msg: "Error",
        });
      } else {
        const objUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = objUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link); // Required for FF
        link.click();
        //window.URL.revokeObjectURL(objUrl);
      }
    })
    .catch((e) => {
      if (typeof errorFn !== "undefined") {
        errorFn(e);
      } else {
        alert(e);
      }
    });
};

export const makeRequestPyPOSTParamStr = (url, methodName, fileAsString, successFn, errorFn) => {
  let startTime = Date.now();
  //let data = JSON.stringify(params);

  if (CONSOLE_LOGGING) {
    console.log({
      _: "SEND",
      methodName: methodName,
      url: url,
      data: fileAsString, //JSON.parse(data),
      timeSent: Date(startTime).toString(),
    });
  }

  axios
    .create({
      baseURL: url,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .post(url, fileAsString)
    .then((response) => {
      //let dataIn = JSON.parse(response.config.data);
      let result = response.data;

      if (typeof result === "undefined") {
        result = undefined;
      } else if (typeof result === "string" && (result.charAt(0) === "{" || result.charAt(0) === "[")) {
        result = JSON.parse(response.data);
      } else {
        result = response.data;
      }

      if (CONSOLE_LOGGING) {
        let output = {};
        output._ = "GET";
        output.methodName = methodName;
        //output.data = dataIn;
        output.result = result;
        output.Time = Date.now() - startTime;
        output.timeReceived = Date(Date.now().toString());
        console.log(output);
        console.log(""); // for readability
      }

      successFn(result);
    })
    .catch((e) => {
      if (typeof errorFn !== "undefined") {
        errorFn(e);
      } else {
        alert(e);
      }
    });
};

export const makeFileRequestGet = (url, methodName, params, fileName, successFn, errorFn) => {
  let startTime = Date.now();
  let data = JSON.stringify(params);

  if (CONSOLE_LOGGING) {
    console.log({
      _: "SEND",
      methodName: methodName,
      url: url,
      data: JSON.parse(data),
      timeSent: Date(startTime).toString(),
    });
  }

  axios
    .create({
      baseURL: url,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .get(url, { params: data })
    .then((response) => {
      let csvText = response.data;
      let csvContent = "data:text/csv;charset=utf-8," + csvText;
      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", fileName);
      document.body.appendChild(link); // Required for FF
      link.click();

      if (CONSOLE_LOGGING) {
        let output = {};
        output._ = "GET";
        output.methodName = methodName;
        output.result = csvText;
        output.Time = Date.now() - startTime;
        output.timeReceived = Date(Date.now().toString());
        console.log(output);
        console.log(""); // for readability
      }

      if (typeof successFn !== "undefined") {
        successFn(csvContent);
      }
    })
    .catch((e) => {
      if (typeof errorFn !== "undefined") {
        errorFn(e);
      } else {
        alert(e);
      }
    });
};

/* If passing in multiple modVars, separate with commas. */
export const getModVar = (countryCode, modVarTags, successFn, errorFn) => {
  //const url = window.API_URL_BASE + "/PI/Modvars/" + countryCode + "/" + modVarTag;
  const url = "/PI/Modvars/" + countryCode + "/" + modVarTags;
  const fnName = "getModVar";
  const wrappedParams = {};
  makeRequestByGET(url, fnName, wrappedParams, successFn, errorFn);
};

export const getVersion = (successFn, errorFn) => {
  const url = "/Version";
  const fnName = "getVersion";
  const wrappedParams = {};
  makeRequestByGET(url, fnName, wrappedParams, successFn, errorFn);
};

export const calculate = (params, successFn, errorFn) => {
  const url = window.API_URL_BASE + "/PI/Calculate";
  const fnName = "calculate";
  const wrappedParams = {
    modvars: params,
  };
  makeRequestPyPOST(url, fnName, wrappedParams, successFn, errorFn);
};

export const calculateCostDetailTable = (params, successFn, errorFn) => {
  const url = window.API_URL_BASE + "/PI/CalculateCostDetailTable";
  const fnName = "calculateCostDetailTable";
  const wrappedParams = {
    modvars: params,
  };
  makeRequestPyPOST(url, fnName, wrappedParams, successFn, errorFn);
};

export const calculateCostLiteTable = (params, successFn, errorFn) => {
  const url = window.API_URL_BASE + "/PI/CalculateCostLiteTable";
  const fnName = "calculateCostLiteTable";
  const wrappedParams = {
    modvars: params,
  };
  makeRequestPyPOST(url, fnName, wrappedParams, successFn, errorFn);
};

export const calculateAGYWThresholdsTable = (params, successFn, errorFn) => {
  const url = window.API_URL_BASE + "/PI/CalculateAGYWAreas";
  const fnName = "calculateAGYWThresholdsTable";
  const wrappedParams = {
    modvars: params,
  };
  makeRequestPyPOST(url, fnName, wrappedParams, successFn, errorFn);
};

export const calculateTargetImpact = async (params, successFn, errorFn) => {
  try {
    const fnName = "calculateTargetImpact";
    const wrappedParams = {
      modvars: params,
    };

    const urlCov = window.API_URL_BASE + "/PI/CalculateImpact/" + constantCovMstID;
    const urlX3 = window.API_URL_BASE + "/PI/CalculateImpact/" + ninetyX3CovMstID;

    const [covResult, x3Result] = await Promise.all([
      makeRequestPyPOSTAsync(urlCov, fnName, wrappedParams),
      makeRequestPyPOSTAsync(urlX3, fnName, wrappedParams)
    ])

    successFn({modvars: [...covResult.modvars, ...x3Result.modvars]})
  } catch(err) {
    errorFn()
  }
};

export const calculateTargetImpactAsync = promisify(calculateTargetImpact);

export const calculateTargetsWithConstraints = (params, successFn, errorFn) => {
  const url = window.API_URL_BASE + "/PI/CalculateWithConstraints";
  const fnName = "calculateTargetsWithConstraints";
  const wrappedParams = {
    modvars: params,
  };
  makeRequestPyPOST(url, fnName, wrappedParams, successFn, errorFn);
};

export const downloadTargetsOutputPEPFARCSV = (
  params,
  methodMstIDStr,
  _methodName,
  prep_status,
  successFn,
  errorFn
) => {
  const url = window.API_URL_BASE + "/PI/PEPFARTargetsFile/" + methodMstIDStr;

  const fnName = "downloadTargetsOutputPEPFARCSV";

  const wrappedParams = {
    modvars: params,
  };

  const filename = "PEPFAR_Targets.csv";

  makeFileRequestPost(url, fnName, wrappedParams, filename, successFn, errorFn);
};

export const downloadProgDataCSV = (params, successFn, errorFn) => {
  const url = window.API_URL_BASE + "/PI/Initiations";
  const fnName = "downloadProgDataCSV";
  const wrappedParams = {
    modvars: params,
  };
  makeFileRequestPost(url, fnName, wrappedParams, "ProgramDataTemplate.csv", successFn, errorFn);
};

export const downloadProgDataTemplateXLSX = (params, successFn, errorFn) => {
  const url = window.API_URL_BASE + "/PI/InitiationsExcel";
  const fnName = "downloadProgDataTemplateXLSX";
  const wrappedParams = {
    modvars: params,
  };
  makeFileRequestPostXLSX(url, fnName, wrappedParams, "ProgramDataTemplate.xlsx", successFn, errorFn);
};

export const uploadProgDataTemplateXLSX = (xlsxAsBinaryString, successFn, errorFn) => {
  const url = window.API_URL_BASE + "/PI/UploadInitiations";
  const fnName = "uploadProgDataTemplateXLSX";
  makeRequestPyPOSTParamStr(url, fnName, xlsxAsBinaryString, successFn, errorFn);
};

export const validateProgDataTemplate = (modVars, successFn, errorFn) => {
  const url = window.API_URL_BASE + "/PI/ValidateProgramData";
  const fnName = "validateProgDataTemplate";
  const wrappedParams = {
    modvars: modVars,
  };
  makeRequestPyPOST(url, fnName, wrappedParams, successFn, errorFn);
};

export const downloadPrEPitDataTemplateXLSX = (params, successFn, errorFn) => {
  const url = `${window.API_URL_BASE}/PI/PrEPITDataTemplate/${params.language ?? "en"}`;
  const fnName = "downloadPrEPitDataTemplateXLSX";
  const wrappedParams = {
    modvars: params.modVars,
  };
  makeFileRequestPostXLSX(url, fnName, wrappedParams, "PrEPitDataTemplate.xlsx", successFn, errorFn);
};

export const uploadPrEPitDataTemplateXLSX = (countryCode, xlsxAsString, successFn, errorFn) => {
  const url = window.API_URL_BASE + "/PI/UploadPrEPITDataAllModvars/" + countryCode; // UploadPrEPITData
  const fnName = "uploadPrEPitDataTemplateXLSX";
  makeRequestByPUTParamStr(url, fnName, xlsxAsString, successFn, errorFn);
};

export const downloadAGYWTemplateXLSX = (params, successFn, errorFn) => {
  const url = window.API_URL_BASE + "/PI/AGYWTemplate";
  const fnName = "downloadAGYWTemplateXLSX";
  const wrappedParams = {
    modvars: params,
  };
  makeFileRequestPostXLSX(url, fnName, wrappedParams, "AGYW Data Template.xlsx", successFn, errorFn);
};

export const validateModVars = (params, successFn, errorFn) => {
  const url = window.API_URL_BASE + "/PI/Validate";
  const fnName = "validateModVars";
  const wrappedParams = {
    modvars: params,
  };
  makeRequestPyPOST(url, fnName, wrappedParams, successFn, errorFn);
};

export const updateUserSession = (params, successFn, errorFn) => {
  const url = window.API_URL_BASE + "/PI/Updater";
  const fnName = "updateUserSession";
  const wrappedParams = {
    modvars: params,
  };
  makeRequestPyPOST(url, fnName, wrappedParams, successFn, errorFn);
};

export const aggregateSessionFiles = (modVarsAllFiles, fileNames, successFn, errorFn) => {
  const url = window.API_URL_BASE + "/PI/Aggregate";
  const fnName = "aggregateSessionFiles";
  const wrappedParams = {
    aggregate_this: modVarsAllFiles,
    file_names: fileNames,
  };
  makeRequestPyPOST(url, fnName, wrappedParams, successFn, errorFn);
};

export const getTargetEstimates = (countryCode, successFn, errorFn) => {
  const url = "/PI/Estimates/" + countryCode;
  const fnName = "getTargetEstimates";
  const wrappedParams = {};
  makeRequestByGET(url, fnName, wrappedParams, successFn, errorFn);
};
