import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import * as React from "react";
import PIApp from "../components/PI/Main/PIApp";
import muiTheme from "./Theme";

class Root extends React.Component {
  state = {
    access: false,
  };

  onHandleLogin = ({ userName, password }, successFn, errorFn) => {
    if (password === "test0226") {
      this.setState(
        {
          access: true,
        },
        () => {
          successFn(true);
        }
      );
    } else {
      successFn(false);
    }
  };

  render() {
    return (
      <MuiThemeProvider theme={muiTheme}>
        <PIApp />
      </MuiThemeProvider>
    );
  }
}

export default Root;
