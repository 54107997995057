import { IconButton, Tooltip, makeStyles } from "@material-ui/core";
import { Description as NoteIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import { RS } from "../../data/strings/global";
import useStore from "../../hooks/useStore";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.main,
  },
}));

const NoteEditorButton = () => {
  const updateNoteEditorOpen = useStore((state) => state.updateNoteEditorOpen);

  const classes = useStyles();

  return (
    <Tooltip title={RS("GB_stMakeSessionNotes")}>
      <IconButton
        onClick={() => {
          updateNoteEditorOpen(true);
        }}
      >
        <NoteIcon className={classes.icon} />
      </IconButton>
    </Tooltip>
  );
};

NoteEditorButton.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

NoteEditorButton.defaultProps = {
  value: "",
};

export default NoteEditorButton;
