import { PropTypes } from "prop-types";

import * as piasu from "../NonComponents/PIAppStateUtil";
import * as piu from "../NonComponents/PIUtil";
import * as gbu from "../../GB/GBUtil";
import * as pisc from "../NonComponents/PIServerConst";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import TButton from "../../common/TButton";
import * as Theme from "../../../app/Theme";

import { promisify } from "../../../utilities";

import { getProjDefaultsAsync } from "../../../api/server_calls";
import { onCalculateAsync } from "../NonComponents/PICalc";

const PIResetDefaultContCurvesBtn = (props) => {
  const onModVarsChange = promisify(props.onModVarsChange);

  const onResetClick = async () => {
    try {
      if (props.onCalculatingChange) props.onCalculatingChange(true);

      const modVarObjList = gbu.cloneObj(props.modVarObjList);

      // Fetch defaults from server, inc. hoop jumping
      const countryCode = piasu.getModVarValue(modVarObjList, "PI_CountryISO");
      const { modvars: defaults } = await getProjDefaultsAsync({ countryCode });

      const defModVars = await onCalculateAsync(defaults, "", props.onDialogChange);

      // Rebuild curves with defaults
      const defContCurves = piasu.getModVarValue(defModVars, "PI_ContinuationCurves");

      // New curves == default curves + custom curves
      const newCurves = defContCurves.map((v) => {
        const name = piu.getDefContCurveNameFromMstID(v.mstID);
        if (name === "") return v;

        return {
          ...v,
          name,
        };
      });

      piasu.setModVarValue(modVarObjList, "PI_ContinuationCurves", newCurves);

      // Reset curve assignment to priority pops (cloning due to inplace replacement that follows)
      const newPriorPop = gbu.cloneObj(piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag));
      const defPriorPop = gbu.cloneObj(piasu.getModVarValue(defModVars, pisc.priorPopsMVTag));

      // Replace any prior pop contCurve with the corresponding default contCurve
      for (const pp of newPriorPop) {
        const defPP = defPriorPop.find(v => v.mstID === pp.mstID)

        for (let [ppIdx, ppCurve] of pp.contCurve.entries()) {
          // custom priority pops default all methods to first available curve
          if (defPP === undefined) {
            pp.contCurve[ppIdx] = {curveID: defContCurves[0].mstID, mstID: ppCurve.mstID}
            continue
          }

          // methods all revert to same curve as first method of the corresponding priority pop
          pp.contCurve[ppIdx] = {curveID: defPP.contCurve[0].curveID, mstID: ppCurve.mstID}
        }
      }

      piasu.setModVarValue(modVarObjList, pisc.priorPopsMVTag, newPriorPop);

      // Re-calculate to fix anything caused by the above
      const newModVars = await onCalculateAsync(modVarObjList, "", props.onDialogChange);

      // Update mod vars
      await onModVarsChange(newModVars, true);
    } catch (err) {
      console.log(err);
    } finally {
      if (props.onCalculatingChange) props.onCalculatingChange(false);
    }
  };

  return (
    <TButton
      caption={RS(SC.GB_stResetToDefault)}
      key={"defaultCostsBtn"}
      onClick={onResetClick}
      containerStyle={{
        display: "inline-block",
        marginRight: 10,
        marginTop: 0,
      }}
      style={{
        backgroundColor: Theme.PI_TertiaryColor,
        padding: 0,
      }}
      {...props}
    />
  );
};

PIResetDefaultContCurvesBtn.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
  onModVarsChange: PropTypes.func,
  onDialogChange: PropTypes.func,
  onCalculatingChange: PropTypes.func,
};

PIResetDefaultContCurvesBtn.defaultProps = {
  onModVarsChange: () => {},
  onDialogChange: () => {},
  onCalculatingChange: () => {},
};

export default PIResetDefaultContCurvesBtn;
