import preval from "preval.macro";

function uuidv4() {
  let crypto = window.crypto || window.msCrypto;
  let x = [1e7] + -1e3 + -4e3 + -8e3 + -1e11;
  let y = /[018]/g;

  // => did not exist for IE.
  return x.replace(y, (c) => {
    let z = crypto.getRandomValues(new Uint8Array(1));
    let a = z[0] & (15 >> (c / 4));
    let b = c ^ a;
    // eslint-disable-next-line no-mixed-operators
    return b.toString(16);
  });
}

const configure = () => {
  window.DebugMode = Boolean(process.env.REACT_APP_DEBUG_MODE);

  window.API_URL_BASE = process.env.REACT_APP_API_URL_BASE?.replace(/\/$/, "");
  window.API_URL = window.API_URL_BASE + "/JSON";

  window.HIDE_PREP_RECIPIENTS =
    process.env.REACT_APP_HIDE_PREP_RECIPIENTS === "true" || parseInt(process.env.REACT_APP_HIDE_PREP_RECIPIENTS) === 1;

  /* For REST calls, we always want a new GUID every time the site is visited. */
  window.CLIENT_ID = "{" + uuidv4() + "}";

  const build_timestamp = preval`module.exports = new Date().toUTCString();`;
  console.log(`Build Timestamp: ${build_timestamp}`);
};

export default configure;
