import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as piasu from "../NonComponents/PIAppStateUtil";
import GBStdChart from "../../common/GBStdChart";
import { downloadCSV } from "../../GB/GBGraphUtil";
import * as piu from "../NonComponents/PIUtil";

const PIDataByMonthGraph = (props) => {
  const modVarObjList = props.modVarObjList;

  const progDataPeriod = piasu.getProgDataPeriodObj(modVarObjList);
  const targSetPeriod = piasu.getTargSettingPeriodObj(modVarObjList);
  const displayRange = piasu.getDateRangeDisplayObj(modVarObjList);

  if (props.showPDP) {
    displayRange.startMonthInt = progDataPeriod.startMonthInt;
    displayRange.startYearInt = progDataPeriod.startYearInt;
  }

  const pdp = piu.getDateObjectAsJSDates(progDataPeriod);
  const graph = piu.getDateObjectAsJSDates(displayRange);
  const targetSet = piu.getDateObjectAsJSDates(targSetPeriod);

  /**
   * Convert date to index into the series data
   * @param {Date} date Date to convert
   * @returns {number} Index
   */
  const dateToIndex = (date) => {
    return piu.getMonthsBetweenDates(pdp.start, date);
  };

  const t1 = dateToIndex(graph.start);
  const t2 = t1 + piu.getMonthsBetweenDates(graph.start, graph.end) + 1;

  // Labels
  const pointLabels = [];
  let month = displayRange.startMonthInt;
  let year = displayRange.startYearInt;
  for (let t = t1; t < t2; t++) {
    pointLabels.push(piu.getMonthAbbrName(month) + " " + year);

    if (month === 12) {
      month = 1;
      year++;
    } else {
      month++;
    }
  }

  // Plot bands
  const plotBands = [];
  if (props.showPDP && graph.start < pdp.end) {
    // Program data period
    plotBands.push({
      // color: "lightblue",
      label: {
        text: RS(SC.GB_stProgDataPeriod),
      },
      from: 0,
      to: dateToIndex(pdp.end) - t1,
    });
  }

  if (piu.getMonthsBetweenDates(pdp.end, targetSet.start) > 1) {
    // Gap between PDP and TSP
    plotBands.push({
      color: "lightblue",
      from: dateToIndex(pdp.end) - t1,
      to: dateToIndex(targetSet.start) - t1,
    });
  }

  // Series
  const series = props.series
    .map((opts) => {
      return {
        name: opts.title,
        data: opts.data.slice(t1, t2),
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  // Chart options
  const chartOptions = {
    chart: {
      type: "line",
      height: "900px",
      //zoom   : "xy",
    },

    title: {
      text: props.title,
    },

    plotOptions: {
      column: {
        stacking: "normal",
      },
      series: {
        lineWidth: Theme.lineWidthGraph,
      },
    },

    exporting: {
      enabled: true,
    },

    legend: {
      align: "center", //"right",
      layout: "horizontal", //"vertical",
      verticalAlign: "top",
      x: 0,
      y: 0, // 50
    },

    xAxis: {
      plotBands,
      categories: pointLabels,
    },

    yAxis: {
      min: 0,
    },

    ...props.chartOptions,

    /* subsets */
    series: series,
  };

  return (
    <GBStdChart
      chartType={"line"}
      id={props.id}
      minHeight={500}
      maxHeight={500}
      options={chartOptions}
      showDownloadCSV={true}
      onDownloadCSVClick={(chartOptions) => {
        let sheet = [[chartOptions.title.text.replace(",", "(") + ")"], ["", ...chartOptions.xAxis.categories]];
        chartOptions.series.forEach((dat) => {
          let info = [dat.name, ...dat.data];
          sheet.push(info);
        });
        downloadCSV(sheet, chartOptions.title.text, 0);
      }}
      showLegend={true}
      style={{
        //marginLeft   : Theme.leftIndent,
        marginBottom: 20,
        marginTop: 20,
      }}
    />
  );
};

PIDataByMonthGraph.propTypes = {
  /** Mod vars */
  modVarObjList: PropTypes.array.isRequired,
  /** ID */
  id: PropTypes.string,
  /** Data series */
  series: PropTypes.arrayOf(
    PropTypes.shape({
      /** Title for the series */
      title: PropTypes.string.isRequired,
      /** Full data by month, will be sliced to the date range for display */
      data: PropTypes.array.isRequired,
    })
  ).isRequired,
  /** Show the PDP as a plot band if true */
  showPDP: PropTypes.bool,
  /** Chart title */
  title: PropTypes.string,
  /** HighChart optionss */
  chartOptions: PropTypes.object,
};

export default PIDataByMonthGraph;
