import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import GBStdChart from "../../common/GBStdChart";
import { downloadCSV } from "../../GB/GBGraphUtil";
import * as piu from "../NonComponents/PIUtil";

const PIDataByMethodPopMonthGraph = (props) => {
  const modVarObjList = props.modVarObjList;

  const progDataPeriod = piasu.getProgDataPeriodObj(modVarObjList);
  const targSetPeriod = piasu.getTargSettingPeriodObj(modVarObjList);
  const displayRange = piasu.getDateRangeDisplayObj(modVarObjList);
  const methods = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
  const selectedMethods = piasu.getModVarValue(modVarObjList, pisc.targSelectedMethodsMVTag);
  const popNames = piasu.getModVarValue(modVarObjList, "PI_PriorityPop").map((v) => v.name);

  if (props.showPDP) {
    displayRange.startMonthInt = progDataPeriod.startMonthInt;
    displayRange.startYearInt = progDataPeriod.startYearInt;
  }

  const pdp = piu.getDateObjectAsJSDates(progDataPeriod);
  const graph = piu.getDateObjectAsJSDates(displayRange);
  const targetSet = piu.getDateObjectAsJSDates(targSetPeriod);

  /**
   * Convert date to index into the series data
   * @param {Date} date Date to convert
   * @returns {number} Index
   */
  const dateToIndex = (date) => {
    return piu.getMonthsBetweenDates(pdp.start, date);
  };

  const t1 = dateToIndex(graph.start);
  const t2 = t1 + piu.getMonthsBetweenDates(graph.start, graph.end) + 1;

  // Labels
  const pointLabels = [];
  let month = displayRange.startMonthInt;
  let year = displayRange.startYearInt;
  for (let t = t1; t < t2; t++) {
    pointLabels.push(piu.getMonthAbbrName(month) + " " + year);

    if (month === 12) {
      month = 1;
      year++;
    } else {
      month++;
    }
  }

  // Plot bands
  const plotBands = [];
  if (graph.start < pdp.end) {
    // Program data period
    plotBands.push({
      // color: "lightblue",
      label: {
        text: RS(SC.GB_stProgDataPeriod),
      },
      from: 0,
      to: dateToIndex(pdp.end) - t1,
    });
  }

  if (piu.getMonthsBetweenDates(pdp.end, targetSet.start) > 1) {
    // Gap between PDP and TSP
    plotBands.push({
      color: "lightblue",
      from: dateToIndex(pdp.end) - t1,
      to: dateToIndex(targetSet.start) - t1,
    });
  }

  // Series
  let series = [];

  for (const seriesOpts of props.series) {
    if (!Array.isArray(seriesOpts.data) || seriesOpts.data.length === 0) continue;

    if (seriesOpts.byMethod) {
      // All pops combined, by method and month
      const mn = seriesOpts.method ?? 0;
      const mx = seriesOpts.method ? seriesOpts.method + 1 : methods.length;
      for (let m = mn; m < mx; ++m) {
        if (!selectedMethods[m]) continue;

        const methodName = methods[m].name;
        const monthData = [];

        // Data
        for (let t = t1; t < t2; t++) {
          const allPops =
            (seriesOpts.data[m].reduce((acc, cur) => {
              return acc + cur[t];
            }, 0) *
              (seriesOpts.multiplier ?? 1)) /
            (seriesOpts.divisor ?? 1);
          monthData.push(allPops);
        }

        series.push({
          name: `${methodName} - ${seriesOpts.name}`,
          data: monthData,
        });
      }
    }

    if (seriesOpts.byPop) {
      // All methods combined, by pop and month
      const mn = seriesOpts.population ?? 0;
      const mx = seriesOpts.population != null ? seriesOpts.population + 1 : popNames.length;
      for (let p = mn; p < mx; ++p) {
        const monthData = [];

        // Data
        for (let t = t1; t < t2; t++) {
          const allMethods =
            seriesOpts.data.reduce((acc, cur) => {
              return acc + cur[p][t];
            }, 0) * (seriesOpts.multiplier ?? 1);
          monthData.push(allMethods);
        }

        series.push({
          name: seriesOpts.population == null ? `${popNames[p]} - ${seriesOpts.name}` : seriesOpts.name,
          data: monthData,
        });
      }
    }
  }

  series = series.sort((a, b) => a.name.localeCompare(b.name));

  const chartOptions = {
    chart: {
      type: "line",
      height: "900px",
      //zoom   : "xy",
    },

    title: {
      text: props.title,
    },

    plotOptions: {
      column: {
        stacking: "normal",
      },
      series: {
        lineWidth: Theme.lineWidthGraph,
      },
    },

    exporting: {
      enabled: true,
    },

    legend: {
      align: "center", //"right",
      layout: "horizontal", //"vertical",
      verticalAlign: "top",
      alignColumns: true,
      x: 0,
      y: 0, // 50
    },

    xAxis: {
      plotBands,
      categories: pointLabels,
    },

    yAxis: {
      min: 0,
    },

    ...props.chartOptions,

    /* subsets */
    series: series,
  };

  function onDownloadCSVClick(chartOptions) {
    let sheet = [[chartOptions.title.text.replace(",", "(") + ")"], ["", ...chartOptions.xAxis.categories]];
    chartOptions.series.forEach((dat) => {
      let info = [dat.name, ...dat.data];
      sheet.push(info);
    });
    downloadCSV(sheet, chartOptions.title.text, 0);
  }

  return (
    <GBStdChart
      chartType={"line"}
      id={props.id}
      minHeight={500}
      maxHeight={500}
      options={chartOptions}
      showDownloadCSV={true}
      onDownloadCSVClick={onDownloadCSVClick}
      showLegend={true}
      style={{
        marginBottom: 20,
        marginTop: 20,
      }}
    />
  );
};

PIDataByMethodPopMonthGraph.propTypes = {
  /** Mod vars */
  modVarObjList: PropTypes.array.isRequired,
  /** ID */
  id: PropTypes.string,
  /** Data series */
  series: PropTypes.arrayOf(
    PropTypes.shape({
      /** String ID from PIStringConst for the series name */
      name: PropTypes.string.isRequired, // ID from PIStringConst
      /** Data by method, pop and month */
      data: PropTypes.array.isRequired,
      /** If true, sum all pops for each method  */
      byMethod: PropTypes.bool,
      /** If true, sum all methods for each pop */
      byPop: PropTypes.bool,
      /** If unset, all methods will be used otherwise only the specified method */
      method: PropTypes.number,
      /** If unset, all pops will be used otherwise only the specified population */
      population: PropTypes.number,
      /** Optionally multiply each data value by this multiplier */
      multiplier: PropTypes.number,
      /** Optionally divide each data value by this divisor */
      divisor: PropTypes.number,
    })
  ).isRequired,
  /** Title */
  title: PropTypes.string,
  /** Show the PDP; will extend start of DRD to include the PDP */
  showPDP: PropTypes.bool,
  /** HighChart optionss */
  chartOptions: PropTypes.object,
};

export default PIDataByMethodPopMonthGraph;
