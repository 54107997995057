import * as React from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import TSlideDrawer from "../../common/TSlideDrawer";
import THelpButton from "../../common/THelpButton";

import * as gbu from "../../GB/GBUtil";

import * as php from "../NonComponents/PIHelp";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pic from "../NonComponents/PIConst";
import * as pip from "../NonComponents/PIProps";
import * as pisc from "../NonComponents/PIServerConst";
import * as piu from "../NonComponents/PIUtil";

import PIItemsForm from "../Forms/PIItemsForm";

import PIResetDefaultMethodCostsBtn from "../Other/PIResetDefaultMethodCostsBtn";

const applyBtnEnabledC = "applyBtnEnabled";

class PIItemsSlideDrawer extends React.Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,
    [pias.onDialogChange]: PropTypes.func,
    [pias.onDrawerChange]: PropTypes.func,

    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelpAreaChange]: PropTypes.func,
    [pias.onHelp]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pias.tableKeyObj]: PropTypes.object,

    [pip.onNextAreaChange]: PropTypes.func,
    [pip.onPrevAreaChange]: PropTypes.func,

    [pip.itemType]: PropTypes.number,

    [pip.itemDrawerTitleStr]: PropTypes.string,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(`PIItemsSlideDrawer: ${pias.onCalculatingChange}`),
    [pias.onDialogChange]: () => console.log(`PIItemsSlideDrawer: ${pias.onDialogChange}`),
    [pias.onDrawerChange]: () => console.log(`PIItemsSlideDrawer: ${pias.onDrawerChange}`),

    [pias.helpAreaStr]: php.config_PriorPopSD_HP,
    [pias.onHelpAreaChange]: () => console.log(`PIItemsSlideDrawer: ${pias.onHelpAreaChange}`),
    [pias.onHelp]: () => console.log(pias.onHelp),

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarsChange]: () => console.log(`PIItemsSlideDrawer: ${pias.onModVarsChange}`),

    [pias.tableKeyObj]: {},

    [pip.onNextAreaChange]: () => console.log(`PIItemsSlideDrawer: ${pip.onNextAreaChange}`),
    [pip.onPrevAreaChange]: () => console.log(`PIItemsSlideDrawer: ${pip.onPrevAreaChange}`),

    [pip.itemType]: 0,

    [pip.itemDrawerTitleStr]: "",
  };

  /* Since the data is all saved by clicking Apply in the slide drawer component instead of on the form
       component, and the slide drawer component exists in this component, we have to save intermediary state for any
       state the user can change in this drawer component and pass intermediate state and onChange events to the form component.
       Changes in state will then be passed back up to this component via callback functions for each piece of state
       the user can change. */
  constructor(props) {
    super(props);

    this.state = {
      /* Passed down to further components and passed back up later via callbacks. */
      [pias.modVarObjList]: gbu.cloneObj(props[pias.modVarObjList]),
      [pias.tableKeyObj]: gbu.cloneObj(props[pias.tableKeyObj]),

      /* Not passed down to further components. */
      [applyBtnEnabledC]: true,
    };
  }

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onHelpBtnClick = () => {
    this.props.onHelp();
  };

  onCheckHelp = () => {
    let whitelist = [
      php.config_PriorPopSD_HP,
      php.config_ContSD_HP,
      php.config_Cont_DefCurvesTB_HP,
      php.config_MethodsSD_HP,
    ];

    return whitelist.includes(this.props[pias.helpAreaStr]);
  };

  /* Sets the help area back to wherever the user should have been when they
       activated the drawer. */
  onUpdateHelpArea = (successFn) => {
    const props = this.props;
    const onHelpAreaChange = props[pias.onHelpAreaChange];
    const itemType = props[pip.itemType];

    let helpAreaStr = "";
    switch (itemType) {
      case pic.priorPopItems:
        helpAreaStr = php.configFM_HP;
        break;

      case pic.persTypeItems:
        helpAreaStr = php.detCosts_PersTB_HP;
        break;

      case pic.servDelivStratItems:
        helpAreaStr = php.detCosts_PersTB_HP;
        break;

      case pic.labTestItems:
        helpAreaStr = php.detCosts_LabTestsContVisTB_HP;
        break;

      case pic.contVisitItems:
        helpAreaStr = php.detCosts_LabTestsContVisTB_HP;
        break;

      case pic.contVisitSchedItems:
        helpAreaStr = php.detCosts_DefContVisSchedTB_HP;
        break;

      case pic.contCurveItems:
        helpAreaStr = php.config_Cont_DefCurvesTB_HP;
        break;

      case pic.scaleUpTrendItems:
        helpAreaStr = php.targ_DefScaleUpTrendsTB_HP;
        break;

      default:
        helpAreaStr = "";
        break;
    }

    onHelpAreaChange(helpAreaStr, successFn);
  };

  onSlideDrawerSaveBtnClick = (successFn) => {
    /* state */
    const state = this.state;
    const modVarObjList = state[pias.modVarObjList];

    /* props */
    const props = this.props;
    const onModVarsChange = props[pias.onModVarsChange];
    const onDrawerChange = props[pias.onDrawerChange];
    const onDialogChange = props[pias.onDialogChange];
    const itemType = props[pip.itemType];

    let mstID2 = undefined;
    if (piu.itemVariesByMethod(itemType)) {
      mstID2 = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
    }

    const itemObjList = piasu.getItemModVarValue(itemType, modVarObjList);
    let editorValid = piasu.getTotalNumItems(itemType, itemObjList, mstID2) > 0;

    let editorInvalidStr = RS(SC.GB_stNeedOneItem);

    if (!editorValid) {
      let dialogObj = pias.getDefaultDialogObj();
      dialogObj[pias.contentStr] = editorInvalidStr;
      dialogObj[pias.headerStr] = RS(SC.GB_stError);
      dialogObj[pias.maxWidthStr] = "sm";
      dialogObj[pias.showBool] = true;
      dialogObj[pias.styleObj] = { width: 500 };
      onDialogChange(dialogObj);

      return false;
    } else {
      onModVarsChange(
        modVarObjList,
        false,
        () => {
          this.setState(
            {
              [applyBtnEnabledC]: false,
            },
            () => {
              this.onUpdateHelpArea(() => {
                onDrawerChange(() => {
                  gbu.safeCallFn(successFn);
                  return true;
                });
              });
            }
          );
        },
        () => {
          /* In case recalc fails, close the drawer so the user is not stuck. */

          this.onUpdateHelpArea(() => {
            onDrawerChange(() => {
              gbu.safeCallFn(successFn);
              return true;
            });
          });
        }
      );
    }
  };

  onSlideDrawerCloseBtnClick = () => {
    const props = this.props;
    const onDrawerChange = props[pias.onDrawerChange];

    this.onUpdateHelpArea(() => {
      onDrawerChange();
    });
  };

  /* Match onModVarsChange method signature passed into this form so ItemsSlideDrawer
    can stay the same whether it appears inside another drawer or not. calculateBool will not
    be used. */
  onModVarsChange = (modVarObjList, calculateBool, successFn) => {
    let tableKeyObjClone = gbu.cloneObj(this.state[pias.tableKeyObj]);

    piasu.updateTableKeys(tableKeyObjClone);

    this.setState(
      {
        [pias.modVarObjList]: modVarObjList,
        [pias.tableKeyObj]: tableKeyObjClone,
        [applyBtnEnabledC]: true,
      },
      () => gbu.safeCallFn(successFn)
    );
  };

  //==================================================================================================================
  //
  //                                              Render
  //
  //==================================================================================================================

  render() {
    const props = this.props;
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onDialogChange = props[pias.onDialogChange];
    const onDrawerChange = props[pias.onDrawerChange];
    const onModVarsChange = this[pias.onModVarsChange];
    const origModVarObjArr = props[pias.origModVarObjArr];

    const onNextAreaChange = props[pip.onNextAreaChange];
    const onPrevAreaChange = props[pip.onPrevAreaChange];

    const state = this.state;
    const modVarObjList = state[pias.modVarObjList];
    const tableKeyObj = state[pias.tableKeyObj];

    const PIItemsFormComp = (
      <PIItemsForm
        {...{
          [pias.onCalculatingChange]: onCalculatingChange,
          [pias.onDialogChange]: onDialogChange,
          [pias.onDrawerChange]: onDrawerChange,

          [pias.modVarObjList]: modVarObjList,
          [pias.origModVarObjArr]: origModVarObjArr,
          [pias.onModVarsChange]: onModVarsChange,

          [pip.itemType]: props[pip.itemType],

          [pias.tableKeyObj]: tableKeyObj,

          [pip.onSlideDrawerSaveBtnClick]: this[pip.onSlideDrawerSaveBtnClick],

          [pip.onNextAreaChange]: onNextAreaChange,
          [pip.onPrevAreaChange]: onPrevAreaChange,
        }}
      />
    );

    const helpBtn = <THelpButton Theme={Theme} onClick={this.onHelpBtnClick} />;

    const resetDefaultsBtn = (
      <PIResetDefaultMethodCostsBtn
        modVarObjList={modVarObjList}
        onModVarsChange={onModVarsChange}
        onCalculatingChange={onCalculatingChange}
        containerStyle={{
          float: "right",
          marginRight: 10,
          marginTop: 4,
        }}
      />
    );

    const topRightComponents = [];
    if (props[pip.itemType] === pic.methodItems) {
      topRightComponents.push(resetDefaultsBtn);
    }

    const slideDrawer = (
      <TSlideDrawer
        anchor={"right"}
        onClose={this.onSlideDrawerSaveBtnClick}
        content={PIItemsFormComp}
        headerTitleStyle={{
          color: Theme.PI_PrimaryColor,
        }}
        title={props[pip.itemDrawerTitleStr]}
        topRightComponents={topRightComponents}
        width={props[pip.itemType] === pic.contVisitSchedItems ? Theme.slideDrawerLv2Width : Theme.slideDrawerLv1Width}
        helpBtn={this.onCheckHelp() ? helpBtn : null}
      />
    );

    return slideDrawer;
  }
}

export default PIItemsSlideDrawer;
