import React from "react";
import Highcharts from "highcharts";
import addHighchartsMore from "highcharts-more"; // needed for boxplot
import PropTypes from "prop-types";
import deepmerge from "deepmerge";
window.Highcharts = Highcharts;
require("highcharts/modules/exporting")(Highcharts);
addHighchartsMore(Highcharts); // needed for boxplot

class GBStdChart extends React.Component {
  //==================================================================================================================
  //
  //                                             Props and State
  //
  //==================================================================================================================

  static propTypes = {
    id: PropTypes.string,
    packChart: PropTypes.object,
    stretch: PropTypes.bool,
    minHeight: PropTypes.number,
    maxHeight: PropTypes.number,
    chartType: PropTypes.string,
    showLegend: PropTypes.bool,
    alwaysRedraw: PropTypes.bool,
    options: PropTypes.object,
    style: PropTypes.object,
    onDownloadCSVClick: PropTypes.func,
    showDownloadCSV: PropTypes.bool,
  };

  static defaultProps = {
    id: "Chart1",
    packChart: null,
    stretch: false,
    minHeight: null,
    maxHeight: null,
    chartType: "line",
    showLegend: true,
    alwaysRedraw: false,
    options: {},
    style: {},
    showDownloadCSV: true,
    // put into function below and call function up here later on when you have time
    onDownloadCSVClick: function (_options) {
      //let i = 0, r, c;
      let seriesAmount = this.series.length;
      let pointAmount = this.xAxis[0].categories.length;

      let sheet = [...Array(seriesAmount + 2)].map((e) => Array(pointAmount + 2).fill(0)); // Create 2DArray
      sheet[0][0] = '"' + this.title.textStr + '"';
      let ss = 0;
      let pt = 0;

      for (ss = 0; ss < seriesAmount; ss++) {
        sheet[2 + ss][0] = this.series[ss].name;
      }

      for (pt = 0; pt < pointAmount; pt++) {
        sheet[1][1 + pt] = this.xAxis[0].categories[pt];
      }

      for (ss = 0; ss < seriesAmount; ss++) {
        for (pt = 0; pt < pointAmount; pt++) {
          console.log("ss=" + ss + ",pt=" + pt);
          sheet[2 + ss][1 + pt] = this.series[ss].data[pt].value;
        }
      }

      let csvContent = "";
      sheet.forEach(function (rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
      });

      if (window.navigator.msSaveOrOpenBlob) {
        let blob = new Blob([csvContent]);
        window.navigator.msSaveOrOpenBlob(blob, "chart.csv");
      } else {
        let a = document.createElement("a");
        a.href = "data:attachment/csv," + encodeURIComponent(csvContent);
        a.target = "_blank";
        a.download = "chart.csv";
        document.body.appendChild(a);
        a.click();
      }

      // window.open();
      // window.open("data:text/csv;charset=utf-8," + escape(csvContent));
    },
  };

  state = {
    chart: null,
  };

  defaultOptions = {
    chart: {
      renderTo: "chart1",
      type: "bar",
      backgroundColor: null,
      height: 300,
      animation: false,
      width: null,
      reflow: false,
    },
    legend: {
      enabled: true,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [],
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            {
              text: "Download PNG",
              onclick: function () {
                this.exportChart();
              },
            },
            {
              text: "Download PDF",
              onclick: function () {
                this.exportChart({
                  type: "application/pdf",
                });
              },
            },
            {
              text: "Download CSV",
              onclick: () => this.props.onDownloadCSVClick(this.props.options)
            },
          ],
        },
      },
      sourceWidth: 600,
      sourceHeight: 400,
      printMaxWidth: 780,
      scale: 2,
      chartOptions: {
        chart: {
          backgroundColor: "#ffffff",
        },
        legend: {
          maxHeight: 200,
          itemStyle: {
            fontSize: "5px",
          },
        },
        plotOptions: {
          series: {
            lineWidth: "0.5px",
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        if (typeof this.series !== "undefined") {
          return (
            "<b>" +
            this.series.name +
            " at " +
            this.point.category +
            "</b><br/>" +
            "Value: " +
            Math.abs(this.point.y).toFixed(2)
          );
        }
      },
    },
    series: [],
  };

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (JSON.stringify(this.props) !== JSON.stringify(nextProps) || this.props.alwaysRedraw) {
      this.renderChart(
        nextProps.id,
        this.state.chart,
        nextProps.packChart,
        nextProps.chartType,
        nextProps.showLegend,
        nextProps.options
      );
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize);
    this.renderChart(
      this.props.id,
      this.state.chart,
      this.props.packChart,
      this.props.chartType,
      this.props.showLegend,
      this.props.options
    );
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);

    // Destroy chart before unmount.
    if (!(this.state.chart === null)) {
      this.state.chart.destroy();
    }
  }

  getChartHeight = () => {
    let stretch = this.props.stretch;
    let chart = this.state.chart;
    let height = window.innerHeight; // - AppBarHeight; const AppBarHeight = 64;
    let minHeight = this.props.minHeight;
    let maxHeight = this.props.maxHeight;

    // 1) Stretch the chart to the very bottom?
    if (stretch) {
      if (!(chart === null)) {
        let div = chart.renderTo;
        let space = window.innerHeight - div.offsetTop;

        if (minHeight > space) {
          return minHeight;
        } else {
          return space;
        }
      }
    }

    // 2) Or use a custom min/max height
    if (height > minHeight && height < maxHeight) {
      return height;
    } else if (height < minHeight) {
      return minHeight;
    } else if (height > maxHeight) {
      return maxHeight;
    } else {
      return undefined;
    }
  };

  //==================================================================================================================
  //
  //                                                 Events
  //
  //==================================================================================================================

  onResize = () => {
    if (!(this.state.chart === null)) {
      this.state.chart.setSize(undefined, this.getChartHeight(), false);
    }
  };

  getOptions = (o, customOptions) => {
    // o = packChart, shortened for easier readability

    // const getChartType = (chartType) => {
    //
    //     const CH_Line                   = 1;
    //     const CH_Bar                    = 2;
    //     const CH_Bar_3D                 = 3;
    //     const CH_HORIZONTAL_BAR         = 4;
    //     const CH_HORIZONTAL_BAR_3D      = 5;
    //     const CH_STACKED_BAR            = 6;
    //     const CH_POINT                  = 7;
    //     const CH_Table                  = 8;
    //     const CH_Pie                    = 9;
    //     const CH_3D_GRAPH               = 10;
    //     const CH_POINT_LINE             = 11;
    //     const CH_HORIZONTAL_STACKED     = 12;
    //
    //     switch(chartType) {
    //         case CH_Line                   : return 'line';
    //         case CH_Bar                    : return 'column';
    //         case CH_Bar_3D                 : return 'column';
    //         case CH_HORIZONTAL_BAR         : return 'bar';
    //         case CH_HORIZONTAL_BAR_3D      : return 'bar';
    //         case CH_STACKED_BAR            : return 'column';
    //         case CH_POINT                  : return 'point';
    //         case CH_Table                  : return null;
    //         case CH_Pie                    : return 'pie';
    //         case CH_3D_GRAPH               : return null;
    //         case CH_POINT_LINE             : return 'line';
    //         case CH_HORIZONTAL_STACKED     : return 'column';
    //         default                        : return 'line';
    //     }
    // };

    const getSubtitle = (subTitle, multiSubTitles) => {
      if (subTitle !== "") {
        return subTitle;
      } else if (typeof multiSubTitles !== "undefined") {
        let s = "";
        let len = multiSubTitles.length - 1;

        for (let i = 0; i < len; i++) {
          s += multiSubTitles[0];
          if (i < len) {
            s += "<br />";
          }
        }
        return s;
      } else {
        return undefined;
      }
    };

    const getLegendAlign = (legendLocation) => {
      // eLegendLocation
      const gLegendTop = 0;
      const gLegendBottom = 1;
      const gLegendLeft = 2;
      const gLegendRight = 3;

      switch (legendLocation) {
        case gLegendTop:
          return "top";
        case gLegendBottom:
          return "bottom";
        case gLegendLeft:
          return "left";
        case gLegendRight:
          return "right";
        default:
          return "top";
      }
    };

    const getYAxisMin = (manualMinY, manualStackedMinY, stacking) => {
      if (stacking) {
        return manualStackedMinY;
      } else {
        return manualMinY;
      }
    };

    const getYAxisMax = (manualMaxY, manualStackedMax, stacking) => {
      if (stacking) {
        if (manualStackedMax > 0) {
          return manualStackedMax;
        } else {
          return undefined;
        }
      } else {
        if (manualMaxY > 0) {
          return manualMaxY;
        } else {
          return undefined;
        }
      }
    };

    function getTooltipFormat(a, b, c, d) {
      let s = "<b>" + this.x + "</b>";

      this.points.forEach((item) => {
        s += "<br/><b>" + item.series.name + "</b>: " + item.y.toFixed(2);
      });

      return s;
    }

    // const onHotSpotClick = (event) => {
    //
    //     // let s;
    //     // s += '<b>Subset</b><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + event.point.series.name;
    //     // s += '<b>Point</b><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + event.point.category;
    //     // s += '<b>Value</b><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + event.point.y;
    //     // s += '<b>' + event.point.value2Lab</b><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + event.point.category;
    //     // s += '<b>Point</b><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + event.point.category;
    //
    //
    //     // s += '<h2>value2</h2>';
    //     // s += 'Insert something here';
    //     //s += o.value2[event.point.x];
    //
    //     //alert('You just clicked the graph');
    //     alert(event.point.category + ', ' + event.point.series.name + ':  ' + event.point.y);
    // };

    const getSeries = (subsetLabels, subsetColors, subsetsToLegend, chartData) => {
      let series = chartData.map((arr2, subset) => {
        return {
          name: subsetLabels[subset],
          color: this.getDelphiColorToHex(subsetColors[subset]),
          index: subsetsToLegend[subset],
          visible: !(subsetsToLegend[subset] === -1),
          data: chartData[subset],
          //yAxis: (RYAxisLabel === '') ? 0 : (subset === 0) ? 0 : 1
          // arr2.map((value, point) => {
          //     return {
          //         y: value
          //     }
          // })
        };
      });

      return series;
    };

    let options = {};

    if (o !== null) {
      options = {
        chart: {
          type: this.props.chartType, //getChartType(o.chartType),
          height: "900px",
        },

        title: {
          text: o.title,
          subTitle: getSubtitle(o.subTitle, o.multiSubTitles),
        },

        legend: {
          enabled: o.showLegend,
          verticalAlign: getLegendAlign(o.legendLocation),
        },

        plotOptions: {
          column: {
            stacking: o.stacking,
          },
          bar: {
            stacking: o.stacking,
          },
          series: {
            cursor: "pointer",
            marker: {
              enabled: false,
            },
            // events : {
            //     click : onHotSpotClick
            // }
          },
        },

        exporting: {
          enabled: false,
        },

        tooltip: {
          enabled: true, //o.allowPopup,
          shared: true,
          crosshairs: true,
          valueDecimals: 2,
          formatter: getTooltipFormat,
        },

        credits: {
          enabled: false,
          text: "",
          href: "",
          style: {
            cursor: "pointer",
          },
        },

        xAxis: {
          title: o.xAxisLabel,
          categories: o.pointLabels,
        },

        yAxis: [
          {
            title: o.yAxisLabel,
            maxPadding: 0,
            // tickPositioner: function() {
            //     return [this.dataMin, this.dataMax];
            // },
            min: getYAxisMin(o.manualMinY, o.manualStackedMinY, o.stacking),
            max: getYAxisMax(o.manualMaxY, o.manualStackedMaxY, o.stacking),
          },
          {
            title: o.RYAxisLabel,
            maxPadding: 0,
            // tickPositioner: function() {
            //     return [this.dataMin.toFixed(0), this.dataMax.toFixed(0)];
            // },
            min: o.manualMinRY,
            //opposite: true
          },
        ],

        series: getSeries(o.subsetLabels, o.subsetColors, o.subsetsToLegend, o.chartData),
      };
    }

    if (!this.props.showDownloadCSV) {
      delete this.defaultOptions.exporting.buttons.contextButton.menuItems[2];
    }

    let mergedOptions = deepmerge(this.defaultOptions, options);
    mergedOptions = deepmerge(mergedOptions, customOptions);

    return mergedOptions;
  };

  getDelphiColorToHex = (color) => {
    if (color >= 0) {
      let hexString = color.toString(16);

      let R = hexString.slice(-2);
      let G = hexString.slice(-4, -2);
      let B = hexString.slice(-6, -4);

      return "#" + (R + G + B);
    } else {
      return "";
    }
  };

  //==================================================================================================================
  //
  //                                                Render
  //
  //==================================================================================================================

  renderChart = (id, chart, packChart, chartType, showLegend, customOptions) => {
    let options = this.getOptions(packChart, customOptions);
    options.chart.height = this.getChartHeight();
    options.chart.type = chartType;
    options.legend.enabled = showLegend;

    if (!(chart === null)) {
      chart.destroy();
    }

    let newChart = Highcharts.chart(id, options);

    this.setState(
      {
        chart: newChart,
      },
      () => this.onResize()
    );
  };

  render() {
    return (
      <div className="HighChart">
        <div id={this.props.id} style={this.props.style}></div>
      </div>
    );
  }
}

export default GBStdChart;
