import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtu from "../../GB/GBTableUtil";
import * as pip from "../NonComponents/PIProps";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";
import { onCalculate } from "../NonComponents/PICalc";
import * as pitu from "../NonComponents/PITableUtil";

import { generateTypes } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

const priorityPopulationColumn = 0;
const constantConstraintColumn = priorityPopulationColumn + 1;
const nineFiveColumn = constantConstraintColumn + 1;
const adjustmentFactorColumn = nineFiveColumn + 1;
const constantConstraintAdjustedIAColumn = adjustmentFactorColumn + 1;
const constantConstraintAdjustedPYColumn = constantConstraintAdjustedIAColumn + 1;
const nineFiveAdjustedIAColumn = constantConstraintAdjustedPYColumn + 1;
const nineFiveAdjustedPYColumn = nineFiveAdjustedIAColumn + 1;
const numCols = nineFiveAdjustedPYColumn + 1;

const superHeadingRow = 0;
const subHeadingRow = superHeadingRow + 1;

class PIImpactTable extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),
  };

  state = {
    [pip.rDec]: [],
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPackTableChange = (newPackTable) => {
    try {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const onCalculatingChange = props[pias.onCalculatingChange];
      const onModVarsChange = props[pias.onModVarsChange];

      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);

      const priorPopObjList = piasu.getModVarValue(modVarObjListClone, pisc.priorPopsMVTag);
      let adjFactor2DFltArr = piasu.getModVarValue(modVarObjListClone, pisc.adjustmentFactorMVTag);
      const selectedMethodMstID = piasu.getModVarValue(modVarObjListClone, pisc.selectedMethodMVTag);
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjListClone, pisc.priorPopMethodEligMVTag);

      const newPackTableClone = gbu.cloneObj(newPackTable);

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);

      for (let pp = 1; pp <= numPriorPops; pp++) {
        const row = pp + 1;
        const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, selectedMethodMstID, pp);
        if (methodEligMstIDStr === pisc.yesCVOMstID) {
          const adjFactorFlt = gbtu.getValue(newPackTableClone, row, adjustmentFactorColumn);
          piasu.setImpactAdjFactor(selectedMethodMstID, adjFactor2DFltArr, pp, adjFactorFlt);
        }
      }

      onCalculatingChange(true, () => {
        this.setState(
          {
            [pip.rDec]: newPackTable[gbtc.rDec],
          },
          () => {
            onModVarsChange(modVarObjListClone, false, () => {
              onCalculate(
                modVarObjListClone,
                "",
                onDialogChange,
                (response) => {
                  onModVarsChange(response, false, () => {
                    onCalculatingChange(false);
                  });
                },
                () => onCalculatingChange(false)
              );
            });
          }
        );
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      const state = this.state;
      const rDec = state[pip.rDec];

      const priorPopObjList = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      const adjFactor2DFltArr = piasu.getModVarValue(modVarObjList, pisc.adjustmentFactorMVTag);

      let constantCoverageAdjustedIAList = piasu.getModVarValue(
        modVarObjList,
        pisc.adjInfAvtdConstantCoverageMVTag
      );
      let nineFiveAdjustedIAList = piasu.getModVarValue(
        modVarObjList,
        pisc.adjInfAvtdNineFiveMVTag
      );
      let constantConstraintAdjustedPYList = piasu.getModVarValue(
        modVarObjList,
        pisc.persYrsPrEPAvtOneInfectConstantCoverageMVTag
      );
      let nineFiveAdjustedPYList = piasu.getModVarValue(
        modVarObjList,
        pisc.persYrsPrEPAvtOneInfectNineFiveMVTag
      );
      const methodObjList = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const selectedMethodMstID = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopMethodEligMVTag);

      const selectedMethodCurrID = piasu.getMethodCurrID(methodObjList, selectedMethodMstID);

      const showAGYW = piasu.showAGYWTool(modVarObjList);

      let packTable = gbtu.getNewPackTable();

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);
      const numRows = numPriorPops + 2; // includes two header rows

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Set column headings, merging as we go */
      gbtu.setValue(packTable, subHeadingRow, priorityPopulationColumn, RS(SC.GB_stPriorityPop));
      gbtu.mergeCells(packTable, superHeadingRow, constantConstraintColumn, 1, 2);
      gbtu.setValue(packTable, superHeadingRow, constantConstraintColumn, RS(SC.GB_stImpactFactor));
      gbtu.setValue(packTable, subHeadingRow, constantConstraintColumn, RS(SC.GB_stConstCov));
      gbtu.setValue(packTable, subHeadingRow, nineFiveColumn, RS(SC.GB_stNinetyFiveX3));
      gbtu.setValue(packTable, subHeadingRow, adjustmentFactorColumn, RS(SC.GB_stAdjustFactor));
      gbtu.mergeCells(packTable, superHeadingRow, constantConstraintAdjustedIAColumn, 1, 2);
      gbtu.setValue(packTable, superHeadingRow, constantConstraintAdjustedIAColumn, RS(SC.GB_stConstCov));
      gbtu.setValue(packTable, subHeadingRow, constantConstraintAdjustedIAColumn, RS(SC.GB_stAdjInfAvtdPerPYPrEP));
      gbtu.setValue(packTable, subHeadingRow, constantConstraintAdjustedPYColumn, RS(SC.GB_stAdjPYOnPrEPPerInfAvtd));
      gbtu.mergeCells(packTable, superHeadingRow, nineFiveAdjustedIAColumn, 1, 2);
      gbtu.setValue(packTable, superHeadingRow, nineFiveAdjustedIAColumn, RS(SC.GB_stNinetyFiveX3));
      gbtu.setValue(packTable, subHeadingRow, nineFiveAdjustedIAColumn, RS(SC.GB_stAdjInfAvtdPerPYPrEP));
      gbtu.setValue(packTable, subHeadingRow, nineFiveAdjustedPYColumn, RS(SC.GB_stAdjPYOnPrEPPerInfAvtd));

      for (let pp = 1; pp <= numPriorPops; pp++) {
        const row = pp + 1;
        /* Set row headings. */
        const priorPopName = piasu.getPriorPopName(priorPopObjList, pp);
        gbtu.setValue(packTable, row, priorityPopulationColumn, priorPopName);

        const priorPopMstID = piasu.getPriorPopMstID(priorPopObjList, pp);

        const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, selectedMethodMstID, pp);
        if (methodEligMstIDStr === pisc.yesCVOMstID) {
          gbtu.setValue(
            packTable,
            row,
            constantConstraintColumn,
            piasu.getConstantPP(priorPopObjList, selectedMethodCurrID, pp)
          );
          gbtu.setValue(packTable, row, nineFiveColumn, piasu.getNinetyX3PP(priorPopObjList, selectedMethodCurrID, pp));

          const adjFactorFlt = piasu.getImpactAdjFactor(selectedMethodMstID, adjFactor2DFltArr, pp);
          gbtu.setValue(packTable, row, adjustmentFactorColumn, adjFactorFlt);

          if (priorPopMstID === pisc.AGYW_PP_MstID && showAGYW) {
          }

          const constantConstraintAdjustedIA = piasu.getAdjInfAvtd(
            constantCoverageAdjustedIAList,
            selectedMethodCurrID,
            pp
          );
          gbtu.setValue(packTable, row, constantConstraintAdjustedIAColumn, constantConstraintAdjustedIA);

          const constantConstraintAdjustedPY = piasu.getPersYrsPrEPAvtOneInfect(
            constantConstraintAdjustedPYList,
            selectedMethodCurrID,
            pp
          );
          gbtu.setValue(packTable, row, constantConstraintAdjustedPYColumn, constantConstraintAdjustedPY);

          const nineFiveAdjustedIA = piasu.getAdjInfAvtd(nineFiveAdjustedIAList, selectedMethodCurrID, pp);
          gbtu.setValue(packTable, row, nineFiveAdjustedIAColumn, nineFiveAdjustedIA);

          const nineFiveAdjustedPY = piasu.getPersYrsPrEPAvtOneInfect(nineFiveAdjustedPYList, selectedMethodCurrID, pp);
          gbtu.setValue(packTable, row, nineFiveAdjustedPYColumn, nineFiveAdjustedPY);
        } else {
          gbtu.lockCells(packTable, row, true, true);
          const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
          gbtu.setRowBGColor(packTable, row, gainsboroBase10);
        }
      }

      gbtu.setFixedRows(packTable, 2);
      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, superHeadingRow, gbtc.hAlign.center);
      gbtu.setRowAlignment(packTable, subHeadingRow, gbtc.hAlign.center);
      gbtu.lockCol(packTable, constantConstraintColumn, true);
      gbtu.lockCol(packTable, nineFiveColumn, true);
      gbtu.lockCol(packTable, constantConstraintAdjustedIAColumn, true);
      gbtu.lockCol(packTable, constantConstraintAdjustedPYColumn, true);
      gbtu.lockCol(packTable, nineFiveAdjustedIAColumn, true);
      gbtu.lockCol(packTable, nineFiveAdjustedPYColumn, true);
      gbtu.setColWidths(packTable, Theme.dataColWidthMed);
      gbtu.setColWidth(packTable, priorityPopulationColumn, Theme.itemNameColWidthWide);
      gbtu.setWordWrappedCol(packTable, priorityPopulationColumn, true);

      if (rDec.length === 0) {
        gbtu.setRDecByCol(packTable, constantConstraintColumn, 4);
        gbtu.setRDecByCol(packTable, nineFiveColumn, 4);
        gbtu.setRDecByCol(packTable, adjustmentFactorColumn, 1);
        gbtu.setRDecByCol(packTable, constantConstraintAdjustedIAColumn, 4);
        gbtu.setRDecByCol(packTable, constantConstraintAdjustedPYColumn, 0);
        gbtu.setRDecByCol(packTable, nineFiveAdjustedIAColumn, 4);
        gbtu.setRDecByCol(packTable, nineFiveAdjustedPYColumn, 0);
      } else {
        for (let r = 0; r < rDec.length; r++) {
          for (let c = 0; c < rDec[r].length; c++) {
            gbtu.setRDec(packTable, r, c, rDec[r][c]);
          }
        }
      }

      if (window.DebugMode) {
        // console.log("Component: PIImpactTable");
        // console.log("ModVar(s):");
        // console.log(pisc.adjustmentFactorMVTag + ": ");
        // console.log(adjFactor2DFltArr);
        // console.log(pisc.priorPopMethodEligMVTag + ": ");
        // console.log(priorPopMethodEligObjArr);
        // console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"table"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable)}
          onPackTableChanged={this.onPackTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            tableFont: Theme.tableFont,
            marginTop: 0,
            padding: 0,
          }}
          undoDisabled={false}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIImpactTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIImpactTable;
