import * as React from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import TSlideDrawer from "../../common/TSlideDrawer";
import THelpButton from "../../common/THelpButton";
import TButton from "../../common/TButton";

import * as gbu from "../../GB/GBUtil";

import * as php from "../NonComponents/PIHelp";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pip from "../NonComponents/PIProps";

import PIContinuationForm from "../Forms/PIContinuationForm";

import PIResetDefaultContCurvesBtn from "../Other/PIResetDefaultContCurvesBtn";

const applyBtnEnabledC = "applyBtnEnabled";

class PIContinuationSlideDrawer extends React.Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,
    [pias.onDialogChange]: PropTypes.func,
    [pias.onDrawerChange]: PropTypes.func,

    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelpAreaChange]: PropTypes.func,
    [pias.onHelp]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pias.tableKeyObj]: PropTypes.object,

    [pip.onNextAreaChange]: PropTypes.func,
    [pip.onPrevAreaChange]: PropTypes.func,

    [pias.calcContCurvePercOnPrEPBool]: PropTypes.bool,
    [pias.onCalcContCurvePercOnPrEPChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),
    [pias.onDialogChange]: () => console.log(pias.onDialogChange),
    [pias.onDrawerChange]: () => console.log(pias.onDrawerChange),

    [pias.helpAreaStr]: php.config_ContSD_HP,
    [pias.onHelpAreaChange]: () => console.log(pias.onHelpAreaChange),
    [pias.onHelp]: () => console.log(pias.onHelp),

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pias.tableKeyObj]: {},

    [pip.onNextAreaChange]: () => console.log(pip.onNextAreaChange),
    [pip.onPrevAreaChange]: () => console.log(pip.onPrevAreaChange),

    [pias.calcContCurvePercOnPrEPBool]: false,
    [pias.onCalcContCurvePercOnPrEPChange]: () => console.log(pias.onCalcContCurvePercOnPrEPChange),
  };

  /* Since the data is all saved by clicking Apply in the slide drawer component instead of on the form
       component, and the slide drawer component exists in this component, we have to save intermediary state for any
       state the user can change in this drawer component and pass intermediate state and onChange events to the form component.
       Changes in state will then be passed back up to this component via callback functions for each piece of state
       the user can change. */
  constructor(props) {
    super(props);

    this.state = {
      [pias.calcContCurvePercOnPrEPBool]: props[pias.calcContCurvePercOnPrEPBool],

      /* Passed down to further components and passed back up later via callbacks. */
      [pias.modVarObjList]: gbu.cloneObj(props[pias.modVarObjList]),
      [pias.tableKeyObj]: gbu.cloneObj(props[pias.tableKeyObj]),

      /* Not passed down to further components. */
      [applyBtnEnabledC]: true,
      [pip.showRefSlideDrawer]: false,
      [pip.showCalcSlideDrawer]: false,
    };
  }

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onHelpBtnClick = () => {
    this.props.onHelp();
  };

  onUpdateHelpArea = (successFn) => {
    const props = this.props;
    const onHelpAreaChange = props[pias.onHelpAreaChange];

    onHelpAreaChange(php.configFM_HP, successFn);
  };

  onSlideDrawerSaveBtnClick = (successFn) => {
    /* state */
    const state = this.state;
    const modVarObjList = state[pias.modVarObjList];
    const calcContCurvePercOnPrEPBool = state[pias.calcContCurvePercOnPrEPBool];

    /* props */
    const props = this.props;
    const onModVarsChange = props[pias.onModVarsChange];
    const onDrawerChange = props[pias.onDrawerChange];
    const onCalcContCurvePercOnPrEPChange = props[pias.onCalcContCurvePercOnPrEPChange];

    const editorValid = true;

    if (!editorValid) {
      return false;
    } else {
      onModVarsChange(
        modVarObjList,
        false,
        () => {
          onCalcContCurvePercOnPrEPChange(calcContCurvePercOnPrEPBool, () => {
            this.setState(
              {
                [applyBtnEnabledC]: false,
              },
              () => {
                this.onUpdateHelpArea(() => {
                  onDrawerChange(() => {
                    gbu.safeCallFn(successFn);
                    return true;
                  });
                });
              }
            );
          });
        },
        () => {
          /* In case recalc fails, close the drawer so the user is not stuck. */

          this.onUpdateHelpArea(() => {
            onDrawerChange(() => {
              gbu.safeCallFn(successFn);
              return true;
            });
          });
        }
      );
    }
  };

  onToggleRefSlideDrawer = (successFn) => {
    try {
      this.setState(
        {
          [pip.showRefSlideDrawer]: !this.state[pip.showRefSlideDrawer],
        },
        () => gbu.safeCallFn(successFn)
      );
    } catch (exception) {
      this.setState(
        {
          [pip.showRefSlideDrawer]: false,
        },
        () => alert(exception.name + ": " + exception.message)
      );
    }
  };

  onToggleCalcSlideDrawer = (successFn) => {
    try {
      this.setState(
        {
          [pip.showCalcSlideDrawer]: !this.state[pip.showCalcSlideDrawer],
        },
        () => gbu.safeCallFn(successFn)
      );
    } catch (exception) {
      this.setState(
        {
          [pip.showCalcSlideDrawer]: false,
        },
        () => alert(exception.name + ": " + exception.message)
      );
    }
  };

  onSlideDrawerCloseBtnClick = () => {
    const props = this.props;
    const onDrawerChange = props[pias.onDrawerChange];

    this.onUpdateHelpArea(() => {
      onDrawerChange();
    });
  };

  /* Match onModVarsChange method signature passed into this form so ItemsSlideDrawer
    can stay the same whether it appears inside another drawer or not. calculateBool will not
    be used. */
  onModVarsChange = (modVarObjList, calculateBool, successFn) => {
    let tableKeyObjClone = gbu.cloneObj(this.state[pias.tableKeyObj]);

    piasu.updateTableKeys(tableKeyObjClone);

    this.setState(
      {
        [pias.modVarObjList]: modVarObjList,
        [pias.tableKeyObj]: tableKeyObjClone,
        [applyBtnEnabledC]: true,
      },
      () => gbu.safeCallFn(successFn)
    );
  };

  onCalcContCurvePercOnPrEPChange = (calcBool, successFn) => {
    const state = this.state;
    let tableKeyObjClone = gbu.cloneObj(state[pias.tableKeyObj]);

    piasu.updateTableKeys(tableKeyObjClone);

    this.setState(
      {
        [pias.calcContCurvePercOnPrEPBool]: calcBool,
        [pias.tableKeyObj]: tableKeyObjClone,
        [applyBtnEnabledC]: true,
      },
      () => gbu.safeCallFn(successFn)
    );
  };

  //==================================================================================================================
  //
  //                                              Render
  //
  //==================================================================================================================

  render() {
    const props = this.props;
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onDialogChange = props[pias.onDialogChange];
    const onDrawerChange = props[pias.onDrawerChange];

    const helpAreaStr = props[pias.helpAreaStr];
    const onHelpAreaChange = props[pias.onHelpAreaChange];
    const onHelp = props[pias.onHelp];

    const onMethodSlideDrawerChange = props[pip.onMethodSlideDrawerChange];

    const onModVarsChange = this[pias.onModVarsChange];
    const origModVarObjArr = props[pias.origModVarObjArr];

    const onNextAreaChange = props[pip.onNextAreaChange];
    const onPrevAreaChange = props[pip.onPrevAreaChange];

    const state = this.state;
    const modVarObjList = state[pias.modVarObjList];
    const tableKeyObj = state[pias.tableKeyObj];

    const calcContCurvePercOnPrEPBool = state[pias.calcContCurvePercOnPrEPBool];
    const showRefSlideDrawer = state[pip.showRefSlideDrawer];
    const showCalcSlideDrawer = state[pip.showCalcSlideDrawer];

    const onCalcContCurvePercOnPrEPChange = this[pias.onCalcContCurvePercOnPrEPChange];
    const onToggleRefSlideDrawer = this[pip.onToggleRefSlideDrawer];
    const onToggleCalcSlideDrawer = this[pip.onToggleCalcSlideDrawer];

    const PIContinuationFormComp = (
      <PIContinuationForm
        {...{
          [pias.onCalculatingChange]: onCalculatingChange,
          [pias.onDialogChange]: onDialogChange,
          [pias.onDrawerChange]: onDrawerChange,

          [pias.calcContCurvePercOnPrEPBool]: calcContCurvePercOnPrEPBool,
          [pias.onCalcContCurvePercOnPrEPChange]: onCalcContCurvePercOnPrEPChange,

          [pias.helpAreaStr]: helpAreaStr,
          [pias.onHelpAreaChange]: onHelpAreaChange,
          [pias.onHelp]: onHelp,

          [pip.onMethodSlideDrawerChange]: onMethodSlideDrawerChange,

          [pias.modVarObjList]: modVarObjList,
          [pias.origModVarObjArr]: origModVarObjArr,
          [pias.onModVarsChange]: onModVarsChange,

          [pias.tableKeyObj]: tableKeyObj,

          [pip.onSlideDrawerSaveBtnClick]: this[pip.onSlideDrawerSaveBtnClick],

          [pip.onNextAreaChange]: onNextAreaChange,
          [pip.onPrevAreaChange]: onPrevAreaChange,

          [pip.showRefSlideDrawer]: showRefSlideDrawer,
          [pip.onToggleRefSlideDrawer]: onToggleRefSlideDrawer,

          [pip.showCalcSlideDrawer]: showCalcSlideDrawer,
          [pip.onToggleCalcSlideDrawer]: onToggleCalcSlideDrawer,
        }}
      />
    );

    const helpBtn = <THelpButton Theme={Theme} onClick={this.onHelpBtnClick} />;

    const resetDefaultsBtn = (
      <PIResetDefaultContCurvesBtn
        modVarObjList={modVarObjList}
        onModVarsChange={onModVarsChange}
        onCalculatingChange={onCalculatingChange}
        containerStyle={{
          float: "right",
          marginRight: 10,
          marginTop: 4,
        }}
      />
    );

    const defContCurvesBtn = (
      <TButton
        caption={RS(SC.GB_stDefContCurves)}
        containerStyle={{
          float: "right",
          marginRight: 10,
          marginTop: 4,
        }}
        key={"defContCurvesBtn"}
        onClick={this.onToggleRefSlideDrawer}
        style={{
          backgroundColor: Theme.PI_PrimaryColor,
          padding: 0,
        }}
      />
    );

    const contCalculatorBtn = (
      <TButton
        caption={RS(SC.GB_stContCalculator)}
        containerStyle={{
          float: "right",
          marginRight: 10,
          marginTop: 4,
        }}
        key={"contCalculatorBtn"}
        onClick={this.onToggleCalcSlideDrawer}
        style={{
          backgroundColor: Theme.PI_PrimaryColor,
          padding: 0,
        }}
      />
    );

    const topRightComponents = [defContCurvesBtn, contCalculatorBtn, resetDefaultsBtn];

    const slideDrawer = (
      <TSlideDrawer
        anchor={"right"}
        onClose={this.onSlideDrawerSaveBtnClick}
        content={PIContinuationFormComp}
        headerTitleStyle={{
          color: Theme.PI_PrimaryColor,
        }}
        title={RS(SC.GB_stContinuation)}
        topRightComponents={topRightComponents}
        helpBtn={helpBtn}
      />
    );

    return slideDrawer;
  }
}

export default PIContinuationSlideDrawer;
