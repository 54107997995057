import * as React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Checkbox, FormControlLabel, FormGroup, Tooltip } from "@material-ui/core";
import { Info as InfoIcon } from "@material-ui/icons";
import * as Theme from "../../app/Theme";

const Information = ({ message }) => (
  <Tooltip title={message}>
    <div
      style={{
        display: "inline-flex",
        verticalAlign: "middle",
      }}
    >
      <InfoIcon
        style={{
          color: Theme.PI_PrimaryColor,
          fontSize: "1.2rem",
        }}
      />
    </div>
  </Tooltip>
);

class TCheckBox extends React.Component {
  //==================================================================================================================
  //
  //                                             Props and State
  //
  //==================================================================================================================

  static propTypes = {
    caption: PropTypes.string,
    value: PropTypes.bool,
    name: PropTypes.string,
    enabled: PropTypes.bool,
    color: PropTypes.string,
    labelStyle: PropTypes.object,
    onClick: PropTypes.func,
    style: PropTypes.object,
    rootStyle: PropTypes.object,
    classes: PropTypes.object,
    custom: PropTypes.any,
    informationMessage: PropTypes.string,
  };

  static defaultProps = {
    caption: "Caption",
    value: null,
    name: "",
    enabled: true,
    color: "primary",
    labelStyle: {},
    onClick: (checked) => console.log("TCheckBox onClick [" + checked + "]"),
    style: {},
    rootStyle: {},
    classes: {},
    custom: null,
    informationMessage: "",
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
      return true;
    } else {
      return false;
    }
  }

  //==================================================================================================================
  //
  //                                                 Events
  //
  //==================================================================================================================

  onChange = (event, checked) => {
    this.props.onClick(checked, event, this.props.name, this.props.caption, this.props.custom);
  };

  //==================================================================================================================
  //
  //                                                Render
  //
  //==================================================================================================================

  render() {
    const styles = {
      // height: '30px',
      // ...this.props.style
    };

    const unstyledComponent = (props) => (
      <FormGroup style={{ height: 30, ...this.props.style }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.props.value}
              color={this.props.color}
              disabled={!this.props.enabled}
              onChange={this.onChange}
              style={{ ...styles }}
            />
          }
          label={this.props.caption}
          classes={{
            label: props.classes.label,
            root: props.classes.root,
          }}
        />
        {this.props.informationMessage && <Information message={this.props.informationMessage} />}
      </FormGroup>
    );

    const StyledComponent = withStyles({
      label: this.props.labelStyle || {},
      root: this.props.rootStyle || {},
    })(unstyledComponent);

    return <StyledComponent />;
  }
}

export default TCheckBox;
