import * as PropTypes from "prop-types";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as piasu from "../../NonComponents/PIAppStateUtil";
import * as pisc from "../../NonComponents/PIServerConst";
import * as gbtc from "../../../GB/GBTableConst";
import * as gbu from "../../../GB/GBUtil";
import * as pic from "../../NonComponents/PIConst";
import * as pitu from "../../NonComponents/PITableUtil";

import { generateTypes } from "../../../../utilities";
import SuperTableShim from "../../../common/SuperTableShim";

const PINewCommoditiesForecastTargResTable = (props) => {
  const modVarObjList = props.modVarObjList;

  const dateRangeDisplayObj = piasu.getDateRangeDisplayObj(modVarObjList);
  const dateRangeDisplayStartYearInt = piasu.getDateRangeDisplayStartYear(dateRangeDisplayObj);
  const dateRangeDisplayStartMonthInt = piasu.getDateRangeDisplayStartMonth(dateRangeDisplayObj);
  const dateRangeDisplayEndYearInt = piasu.getDateRangeDisplayEndYear(dateRangeDisplayObj);
  const dateRangeDisplayEndMonthInt = piasu.getDateRangeDisplayEndMonth(dateRangeDisplayObj);

  const getMonthCurrID = () => {
    /* drugForecastTableMVTag array needs to be offset by the program start period.
           Target period should always be greater than the program period. */

    const targSetTimePeriodObj = piasu.getTargSettingPeriodObj(modVarObjList);
    const targStartYearInt = piasu.getTargStartYear(targSetTimePeriodObj);
    const targStartMonthInt = piasu.getTargStartMonth(targSetTimePeriodObj);

    const progDataSetTimePeriodObj = piasu.getProgDataPeriodObj(modVarObjList);
    const progStartYearInt = piasu.getProgDataStartYear(progDataSetTimePeriodObj);
    const progStartMonthInt = piasu.getProgDataStartMonth(progDataSetTimePeriodObj);

    let monthCurrID;

    if (progStartYearInt === targStartYearInt) {
      monthCurrID = targStartMonthInt - progStartMonthInt;
    } else {
      /* Otherwise, assume the target start year is greater. */
      /* Take care of months in the target start year and program data start year. */
      monthCurrID = 12 - progStartMonthInt + targStartMonthInt;

      /* Take care of months between the target start year and program data start year. */
      monthCurrID += (targStartYearInt - progStartYearInt - 1) * 12;
    }

    monthCurrID +=
      12 -
      targStartMonthInt +
      (dateRangeDisplayStartYearInt - targStartYearInt - 1) * 12 +
      dateRangeDisplayStartMonthInt;

    return monthCurrID;
  };

  let packTable = gbtu.getNewPackTable();

  const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
  //const numMethods = piasu.getTotalNumMethods(methodObjArr);
  const selectedMethodMstIDStr = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
  const methodCurrID = piasu.getMethodCurrID(methodObjArr, selectedMethodMstIDStr);
  const unitCommName = piasu.methodUnitComm(methodObjArr, methodCurrID);

  const drugForecastData = piasu.getModVarValue(modVarObjList, "PI_DrugForecasting");
  const methodForecastData = drugForecastData[methodCurrID - 1];
  const haveRestarts = "restarts" in methodForecastData?.[0];

  const monthCol = 0;
  const initCol = 1;
  const restartsCol = haveRestarts ? initCol + 1 : initCol;
  const contCol = restartsCol + 1;
  const numMethodReqCol = contCol + 1;
  const methodCostCol = numMethodReqCol + 1;
  const numCols = methodCostCol + 1;

  const firstRow = 0;

  let numRows = firstRow + 2;

  for (let yr = dateRangeDisplayStartYearInt; yr <= dateRangeDisplayEndYearInt; yr++) {
    const m1 = yr === dateRangeDisplayStartYearInt ? dateRangeDisplayStartMonthInt : 1;
    const m2 = yr === dateRangeDisplayEndYearInt ? dateRangeDisplayEndMonthInt : 12;

    for (let m = m1; m <= m2; m++) {
      numRows++;
    }
  }

  packTable = gbtu.resizePackTable(packTable, numRows, numCols);

  gbtu.setValue(packTable, firstRow, monthCol, RS(SC.GB_stMonth));
  gbtu.setValue(packTable, firstRow, initCol, RS(SC.GB_stNumInitVisits));
  if (haveRestarts) gbtu.setValue(packTable, firstRow, restartsCol, RS(SC.GB_stNumRestartVisits));
  gbtu.setValue(packTable, firstRow, contCol, RS(SC.GB_stNumContVisits));
  gbtu.setValue(
    packTable,
    firstRow,
    numMethodReqCol,
    RS(SC.GB_stNumUNITCOMMRequired).replace(pic.unitCommStr, unitCommName)
  );
  gbtu.setValue(
    packTable,
    firstRow,
    methodCostCol,
    gbu.capitalizeFirstLetter(RS(SC.GB_stUNITCOMMCosts).replace(pic.unitCommStr, unitCommName)) +
      " (" +
      RS(SC.GB_stUSD) +
      ")"
  );

  const months = [
    RS(SC.GB_stJanuary),
    RS(SC.GB_stFebruary),
    RS(SC.GB_stMarch),
    RS(SC.GB_stApril),
    RS(SC.GB_stMay),
    RS(SC.GB_stJune),
    RS(SC.GB_stJuly),
    RS(SC.GB_stAugust),
    RS(SC.GB_stSeptember),
    RS(SC.GB_stOctober),
    RS(SC.GB_stNovember),
    RS(SC.GB_stDecember),
  ];

  const totals = {
    initiations: 0,
    restarts: 0,
    continuations: 0,
    productRequired: 0,
    costs: 0,
  };

  /* Offset the starting month by the starting program data. Assume the target start is farther into
               the future. */
  let monthCurrID = getMonthCurrID();
  let row = firstRow + 1;
  for (let yr = dateRangeDisplayStartYearInt; yr <= dateRangeDisplayEndYearInt; yr++) {
    const m1 = yr === dateRangeDisplayStartYearInt ? dateRangeDisplayStartMonthInt : 1;
    const m2 = yr === dateRangeDisplayEndYearInt ? dateRangeDisplayEndMonthInt : 12;

    for (let m = m1; m <= m2; m++) {
      const monthData = methodForecastData[monthCurrID];

      const setForecastData = (fieldName, row, column) => {
        const value = Math.round(monthData[fieldName]);
        totals[fieldName] += value;
        gbtu.setValue(packTable, row, column, value);
      };

      gbtu.setValue(packTable, row, monthCol, months[m - 1] + " " + yr); // row header
      gbtu.setIndent(packTable, row, true, Theme.leftIndent);

      setForecastData("initiations", row, initCol);
      if (haveRestarts) setForecastData("restarts", row, restartsCol);
      setForecastData("continuations", row, contCol);
      setForecastData("productRequired", row, numMethodReqCol);
      setForecastData("costs", row, methodCostCol);

      row++;
      monthCurrID++;
    }
  }

  gbtu.setValue(packTable, row, monthCol, RS(SC.GB_stTotal)); // row header
  gbtu.setFontStyle(packTable, row, monthCol, [gbtc.fontStyle.bold]);
  gbtu.setValue(packTable, row, initCol, totals.initiations);
  if (haveRestarts) gbtu.setValue(packTable, row, restartsCol, totals.restarts);
  gbtu.setValue(packTable, row, contCol, totals.continuations);
  gbtu.setValue(packTable, row, numMethodReqCol, totals.productRequired);
  gbtu.setValue(packTable, row, methodCostCol, totals.costs);

  gbtu.alignNumericCellsRight(packTable);
  gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
  gbtu.setRDecs(packTable, 0);
  // gbtu.setColWidth(packTable, catCol, 325);
  // gbtu.setColWidth(packTable, valCol, 100);
  for (let col = 1; col < numCols; col++) {
    gbtu.setColWidth(packTable, col, Theme.dataColWidthMed);
  }
  gbtu.setRowHeight(packTable, firstRow, 75);
  gbtu.setWordWrappedCol(packTable, initCol, true);
  gbtu.lockPackTable(packTable, true, false);
  gbtu.setMinAllowedValByCol(packTable, initCol, 0);
  gbtu.setMaxAllowedValByCol(packTable, initCol, gbtc.maxInt);

  if (window.DebugMode) {
    console.log("Component: PICommoditiesForecastTargResTable");
    console.log("ModVar(s):");
    console.log(pisc.drugForecastTableMVTag);
    console.log(drugForecastData);
    console.log("");
  }

  const stdTable = (
    <SuperTableShim
      font={Theme.tableFont}
      headerBackgroundColor={Theme.PI_PrimaryColor}
      key={"DrugForecastTargResTable"}
      oddRowBackgroundColor={Theme.PI_BandColor}
      packTable={packTable}
      types={generateTypes(packTable)}
      onPackTableChanged={() => {}}
      removedMenuNames={pitu.tableHideMenuItems}
      style={{
        tableFont: Theme.tableFont,
        marginTop: Theme.ctrlSpacing,
        padding: 0,
      }}
    />
  );

  return stdTable;
};

PINewCommoditiesForecastTargResTable.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
};

export default PINewCommoditiesForecastTargResTable;
