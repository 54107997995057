import * as PropTypes from "prop-types";

import { useState } from "react";

import PIDataByMonthGraph from "./PIDataByMonthGraph";
import TComboBox from "../../common/TComboBox";
import TCheckBox from "../../common/TCheckBox";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as piasu from "../NonComponents/PIAppStateUtil";

const PITargetsOnPrEPGraphByPop = (props) => {
  const modVarObjList = props.modVarObjList;

  /** @type {string[]} */
  const methodNames = piasu.getModVarValue(modVarObjList, "PI_Methods").map((v) => v.name);
  const selectedMethods = piasu.getModVarValue(modVarObjList, "PI_TargetSelectedMethods");
  /** @type {string[]} */
  const popNames = piasu.getModVarValue(modVarObjList, "PI_PriorityPop").map((v) => v.name);

  /** @type {number[][][]} */
  const inits = piasu.getModVarValue(modVarObjList, "PI_Initiations");
  /** @type {number[][][]} */
  const restarts = piasu.getModVarValue(modVarObjList, "PI_Restarts");
  /** @type {number[][][]} */
  const currOnPrEP = piasu.getModVarValue(modVarObjList, "PI_CurrOnPrEP");
  /** @type {number[][][]} */
  const coverage = piasu.getModVarValue(modVarObjList, "PI_Coverage");

  const [selectedPop, setSelectedPop] = useState(0);
  const [showProgData, setShowProgData] = useState(false);

  /**
   * Population change callback
   * @param {number} value Selected item index
   * @param {string} text Item text
   * @param {any} info User data
   */
  const onPopChange = (value, text, info) => {
    setSelectedPop(value);
  };

  /**
   * Show program data flag callback
   * @param {boolean} value Show program data flag
   */
  const onShowProgDataChange = (value) => {
    setShowProgData(value);
  };

  /**
   * Format pop data for the graph
   *
   * @param {string} title Title of series
   * @param {number[][]} data Series data
   * @param {number} multiplier Optional multiplier for the series
   * @returns {{ title: string, data: number[] }[]} Data series for graph
   */
  const makeSeries = (title, data, multiplier) => {
    let names = popNames;
    if (selectedPop !== 0) {
      // Limit data to just the selected pop
      data = [data[selectedPop - 1]];
      names = [popNames[selectedPop - 1]];
    }

    return data.map((value, idx) => {
      return {
        title: `${names[idx]} - ${title}`,
        data: multiplier ? value.map((v) => v * multiplier) : value,
      };
    });
  };

  return (
    <div>
      <div style={{ marginBottom: Theme.contentMarginTop }}>
        <div style={{ ...Theme.labelStyle, fontSize: 24 }}>{RS(SC.GB_stTargetsByPopulation)}</div>
        <TComboBox
          key={"selectPop"}
          onChange={onPopChange}
          items={[RS(SC.GB_stAllPopulations), ...popNames]}
          itemIndex={selectedPop}
          outerStyle={{
            marginTop: Theme.ctrlSpacing,
            fontFamily: Theme.fontFamily,
            width: 150,
          }}
        />

        <TCheckBox
          caption={RS(SC.GB_stShowProgDataPeriod)}
          onClick={onShowProgDataChange}
          enabled={true}
          key={"showProgDataPeriod"}
          style={{
            color: Theme.PI_SecondaryColor,
            height: "auto",
            marginBottom: 10,
            marginLeft: 0,
            marginTop: Theme.ctrlSpacing,
          }}
          value={showProgData}
        />
      </div>

      {methodNames.map((methodName, idx) => {
        const initsByPop = makeSeries(RS(SC.GB_stPrEPInitiations), inits[idx]);
        const restartsByPop = makeSeries(RS(SC.GB_stRestarts), restarts[idx]);
        const currOnPrEPByPop = currOnPrEP ? makeSeries(RS(SC.GB_stCurrentlyOnPrEP), currOnPrEP[idx]) : [];
        const coverageByPop = coverage ? makeSeries(RS(SC.GB_stCoverage), coverage[idx], 100) : [];
        return (
          selectedMethods[idx] && (
            <div key={idx}>
              <div style={{ ...Theme.labelStyle }}>{methodName}</div>
              <div
              // style={{
              //   display: "grid",
              //   gridTemplateRows: "1fr 1fr",
              // }}
              >
                <PIDataByMonthGraph
                  key={`bymonth-${methodName}-left`}
                  modVarObjList={modVarObjList}
                  id={`${methodName}-left`}
                  series={[...initsByPop, ...restartsByPop, ...currOnPrEPByPop]}
                  title={RS(SC.GB_stInitOnPrEPByPop)}
                  showPDP={showProgData}
                />
                <PIDataByMonthGraph
                  key={`bymonth-${methodName}-right`}
                  modVarObjList={modVarObjList}
                  id={`${methodName}-right`}
                  series={coverageByPop}
                  title={RS(SC.GB_stCoverageByPop)}
                  showPDP={showProgData}
                />
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};

PITargetsOnPrEPGraphByPop.propTypes = {
  /** Mod vars */
  modVarObjList: PropTypes.array.isRequired,
};

export default PITargetsOnPrEPGraphByPop;
