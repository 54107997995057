import React, { Component } from "react";
import * as PropTypes from "prop-types";

import Divider from "@material-ui/core/Divider";

import { calculateTargetImpact } from "../../../api/server_calls";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as pic from "../NonComponents/PIConst";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";

import TButton from "../../common/TButton";
import TTabs2 from "../../common/TTabs2";
import TDialog from "../../common/TDialog";
import * as pip from "../NonComponents/PIProps";

import PINavBtnDiv from "../Other/PINavBtnDiv";
import { PINavBtnDivProps } from "../Other/PINavBtnDiv";
import PICostCatLiteTable from "../Tables/PICostCatLiteTable";
import PICostCatAboveSiteTable from "../Tables/PICostCatAboveSiteTable";
import PIAvgUnitCostPrEPUseRefSlideDrawer from "../Drawers/Reference/PIAvgUnitCostPrEPUseRefSlideDrawer";
import PIDefCostsPerVisitRefLiteSlideDrawer from "../Drawers/Reference/PIDefCostsPerVisitRefLiteSlideDrawer";
import PICostsLiteResultContent from "../ResultContent/PICostsLiteResultContent";
import PIMethodComboBox, { PIMethodComboBoxProps } from "../Other/PIMethodComboBox";
import PIResetDefaultCostsBtn from "../Other/PIResetDefaultCostsBtn";

import { cloneObj, safeCallFn } from "../../GB/GBUtil";
import * as piv from "../NonComponents/PIValidate";
import { onCalculate, onCalculateCostsLiteTable } from "../NonComponents/PICalc";
import * as php from "../NonComponents/PIHelp";

//const assignContVisitsTab     = 0;
//const firstTab                = assignContVisitsTab;
//const assignContVisitSchedTab = 1;
const costsTab = 0; //2;
const firstTab = costsTab;
const resultsTab = 1; //3;
// This will be set dynaimcally based on the response to "Getting started question #2"
let finalTab = costsTab;

const selectedTabIdxC = "selectedTabIdx";
const showServDelivStratSlideDrawerBoolC = "showServDelivStratSlideDrawerBool";
//const showContVisitSchedSlideDrawerBoolC      = "showContVisitSchedSlideDrawerBool";
const showServDelivStratDialogBoolC = "showServDelivStratDialogBool";
const showAvgUnitCostRefSlideDrawerBoolC = "showAvgUnitCostRefSlideDrawerBool";
const showDefCostsPerVisitRefSlideDrawerBoolC = "showDefCostsPerVisitRefSlideDrawerBool";

class PICostsLiteForm extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.onDialogChange]: PropTypes.func,

    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelpAreaChange]: PropTypes.func,
    [pias.onHelp]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarChange]: PropTypes.func,

    [pias.onPageChange]: PropTypes.func,

    [pias.tableKeyObj]: PropTypes.object,

    [pias.validationItem]: PropTypes.string,
    [pias.onValidationItemChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    [pias.helpAreaStr]: php.costsLiteFM_HP,
    [pias.onHelpAreaChange]: () => console.log(pias.onHelpAreaChange),
    [pias.onHelp]: () => console.log(pias.onHelp),

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarChange]: () => console.log(pias.onModVarChange),

    [pias.onPageChange]: () => console.log(pias.onPageChange),

    [pias.tableKeyObj]: {},

    [pias.validationItem]: "",
    [pias.onValidationItemChange]: () => console.log(pias.onValidationItemChange),
  };

  state = {
    [selectedTabIdxC]: costsTab, //assignContVisitsTab,
    [showServDelivStratSlideDrawerBoolC]: false,
    //[showContVisitSchedSlideDrawerBoolC]      : false,
    [showServDelivStratDialogBoolC]: false,
    [showAvgUnitCostRefSlideDrawerBoolC]: false,
    [showDefCostsPerVisitRefSlideDrawerBoolC]: false,
  };

  componentDidMount() {
    this.props[pias.onHelpAreaChange](php.costsLiteFM_HP);
  }

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  getHelpAreaStr = (tabIdx) => {
    let helpAreaStr = "";

    switch (tabIdx) {
      // case assignContVisitsTab :
      //     helpAreaStr = php.costsLite_AssContVisTB_HP;
      //     break;
      //
      // case assignContVisitSchedTab :
      //     helpAreaStr = php.costsLite_AssContVisSchedTB_HP;
      //     break;

      case costsTab:
        helpAreaStr = php.costsLite_CostsTB_HP;
        break;

      case resultsTab:
        helpAreaStr = php.costsLite_ResTB_HP;
        break;

      default:
        break;
    }

    return helpAreaStr;
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onTabIdxChange = (tabIdx) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onHelpAreaChange = props[pias.onHelpAreaChange];
    const modVarObjListClone = cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];

    if (tabIdx === resultsTab) {
      this.setState(
        {
          [selectedTabIdxC]: tabIdx,
        },
        () => {
          onCalculatingChange(true, () => {
            onHelpAreaChange(php.costsLite_ResTB_HP, () => {
              onModVarsChange(modVarObjListClone, false, () => {
                /* Put this here because after the editor values change, the user needs to see
                               the graph under it update. */
                onCalculate(
                  modVarObjListClone,
                  "",
                  onDialogChange,
                  (response2) => {
                    onModVarsChange(response2, false, () => {
                      onCalculatingChange(false);
                    });
                  },
                  () => onCalculatingChange(false)
                );
              });
            });
          });
        }
      );
    } else {
      let helpAreaStr = this.getHelpAreaStr(tabIdx);

      onHelpAreaChange(helpAreaStr, () => {
        this.setState({
          [selectedTabIdxC]: tabIdx,
        });
      });
    }
  };

  onNavBtnClick = (direction) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const modVarObjList = props[pias.modVarObjList];
    const onPageChange = props[pias.onPageChange];
    const validationItem = props[pias.validationItem];
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onModVarsChange = props[pias.onModVarsChange];

    const state = this.state;
    let selectedTabIdx = state[selectedTabIdxC];

    let onPageChangeEvent = undefined;

    onCalculatingChange(true, () => {
      onCalculate(
        modVarObjList,
        "",
        onDialogChange,
        (response) => {
          calculateTargetImpact(
            response,
            (response2) => {
              piasu.addNewModVars(response, response2[pisc.modVars], true);
              onModVarsChange(response, false, () => onCalculatingChange(false));
            },
            () => {
              let dialogObj = pias.getDefaultDialogObj();
              dialogObj[pias.contentStr] = "An error occurred while calculating targets.";
              dialogObj[pias.headerStr] = RS(SC.GB_stError);
              dialogObj[pias.maxWidthStr] = "sm";
              dialogObj[pias.showBool] = true;
              dialogObj[pias.styleObj] = { width: 500 };

              onDialogChange(dialogObj);

              onCalculatingChange(false);
            }
          );
        },
        () => onCalculatingChange(false)
      );
    });

    if (direction === pic.back) {
      if (selectedTabIdx === firstTab) {
        const backPageID = piasu.getPageID(modVarObjList, pic.costsLiteFormOrder, pic.back);
        onPageChangeEvent = () => onPageChange(backPageID); //pic.configForm);
      } else {
        selectedTabIdx--;
      }
    } else if (direction === pic.next) {
      if (selectedTabIdx === finalTab) {
        const nextPageID = piasu.getPageID(modVarObjList, pic.costsLiteFormOrder, pic.next);
        onPageChangeEvent = () => onPageChange(nextPageID);
      } else {
        selectedTabIdx++;
      }
    }

    /* From Targets; must be checked before call to calculate. */
    piv.getPotUsersValid(modVarObjList, validationItem, onDialogChange, true, (response2) => {
      if (response2) {
        if (typeof onPageChangeEvent !== "undefined") {
          onPageChangeEvent();
        } else {
          this.onTabIdxChange(selectedTabIdx);
        }
      }
    });
  };

  onAvgUnitCostRefSlideDrawerClick = () => {
    try {
      const props = this.props;
      const onHelpAreaChange = props[pias.onHelpAreaChange];

      const state = this.state;
      const showAvgUnitCostRefSlideDrawerBool = state[showAvgUnitCostRefSlideDrawerBoolC];
      const selectedTabIdx = state[selectedTabIdxC];

      let helpAreaStr = "";
      if (!showAvgUnitCostRefSlideDrawerBool) {
        helpAreaStr = php.costsLite_AvgUnitCostRD_HP;
      } else {
        helpAreaStr = this.getHelpAreaStr(selectedTabIdx);
      }

      onHelpAreaChange(helpAreaStr, () => {
        this.setState({
          [showAvgUnitCostRefSlideDrawerBoolC]: !showAvgUnitCostRefSlideDrawerBool,
        });
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onDefCostsPerVisitRefSlideDrawerClick = () => {
    try {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const onCalculatingChange = props[pias.onCalculatingChange];
      const onHelpAreaChange = props[pias.onHelpAreaChange];
      const modVarObjListClone = cloneObj(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      const state = this.state;
      const showDefCostsPerVisitRefSlideDrawerBool = state[showDefCostsPerVisitRefSlideDrawerBoolC];
      const selectedTabIdx = state[selectedTabIdxC];

      let helpAreaStr = "";
      if (!showDefCostsPerVisitRefSlideDrawerBool) {
        helpAreaStr = php.costsLite_DefCostPatRD_HP;
      } else {
        helpAreaStr = this.getHelpAreaStr(selectedTabIdx);
      }

      onCalculatingChange(true, () => {
        onModVarsChange(modVarObjListClone, false, () => {
          onCalculateCostsLiteTable(
            modVarObjListClone,
            "",
            onDialogChange,
            (response) => {
              onModVarsChange(response, false, () => {
                onHelpAreaChange(helpAreaStr, () => {
                  this.setState(
                    {
                      [showDefCostsPerVisitRefSlideDrawerBoolC]: !showDefCostsPerVisitRefSlideDrawerBool,
                    },
                    () =>
                      safeCallFn(() => {
                        onCalculatingChange(false);
                      })
                  );
                });
              });
            },
            () => onCalculatingChange(false)
          );
        });
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onDefCostsPerVisitRefLiteSlideDrawerClose = () => {
    try {
      const props = this.props;
      const onHelpAreaChange = props[pias.onHelpAreaChange];

      const state = this.state;
      const showDefCostsPerVisitRefSlideDrawerBool = state[showDefCostsPerVisitRefSlideDrawerBoolC];
      const selectedTabIdx = state[selectedTabIdxC];

      let helpAreaStr = php.costsLite_DefCostPatRD_HP;
      if (!showDefCostsPerVisitRefSlideDrawerBool) {
        helpAreaStr = php.costsLite_DefCostPatRD_HP;
      } else {
        helpAreaStr = this.getHelpAreaStr(selectedTabIdx);
      }

      onHelpAreaChange(helpAreaStr, () => {
        this.setState({
          [showDefCostsPerVisitRefSlideDrawerBoolC]: !showDefCostsPerVisitRefSlideDrawerBool,
        });
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onServDelivStratDialogClose = () => {
    this.setState({
      [showServDelivStratDialogBoolC]: false,
    });
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderCostsTabContents = () => {
    const props = this.props;
    const helpAreaStr = props[pias.helpAreaStr];
    //const onHelpAreaChange = props[pias.onHelpAreaChange];
    const onHelp = props[pias.onHelp];
    const modVarObjList = props[pias.modVarObjList];
    const onModVarsChange = props[pias.onModVarsChange];
    const tableKeyObj = props[pias.tableKeyObj];

    const assignContVisitsToSchedLiteTableKey = tableKeyObj[pias.assignContVisitsToSchedLiteTableKey];

    const state = this.state;
    const showDefCostsPerVisitRefSlideDrawerBool = state[showDefCostsPerVisitRefSlideDrawerBoolC];

    const methodMstID = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);

    const enterCostCatsLab = (
      <p
        style={{
          marginTop: 30,
          ...Theme.labelStyle,
        }}
      >
        {RS(SC.GB_stSpecifyUnitCostsEachCat) + " (" + RS(SC.GB_stUSD) + ")"}
      </p>
    );

    const costCatTable = (
      <PICostCatLiteTable
        {...{
          [pias.modVarObjList]: modVarObjList,
          [pias.onModVarsChange]: onModVarsChange,

          [pip.tableKey]: assignContVisitsToSchedLiteTableKey,
        }}
      />
    );

    // GB_stSpecifyAboveSiteCostCat
    let easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);
    const doNotSetTargets = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);

    let aboveSiteCosts;

    if (!doNotSetTargets) {
      aboveSiteCosts = (
        <React.Fragment>
          <p
            style={{
              marginTop: 30,
              ...Theme.labelStyle,
            }}
          >
            {RS(SC.GB_stSpecifyAboveSiteCostCat)}
          </p>
          <PICostCatAboveSiteTable
            key={methodMstID}
            {...{
              [pias.modVarObjList]: modVarObjList,
              [pias.onModVarsChange]: onModVarsChange,

              [pip.tableKey]: tableKeyObj[pias.assignAboveAnnualCostsTableKey],
            }}
          />
        </React.Fragment>
      );
    }

    let defCostsPerVisitRefSlideDrawer = null;

    if (showDefCostsPerVisitRefSlideDrawerBool) {
      defCostsPerVisitRefSlideDrawer = (
        <PIDefCostsPerVisitRefLiteSlideDrawer
          {...{
            [pias.helpAreaStr]: helpAreaStr,
            [pias.onHelp]: onHelp,

            [pias.modVarObjList]: modVarObjList,
            [pias.onModVarsChange]: onModVarsChange,

            [pip.onSlideDrawerClose]: this.onDefCostsPerVisitRefLiteSlideDrawerClose,
            //
            // [pip.onToggleRefSlideDrawer] : this.onDefCostsPerVisitRefSlideDrawerClick,
          }}
        />
      );
    }

    return (
      <React.Fragment>
        {enterCostCatsLab}
        {costCatTable}
        {aboveSiteCosts}
        {defCostsPerVisitRefSlideDrawer}
      </React.Fragment>
    );
  };

  renderResultsTabContents = () => {
    const props = this.props;
    const helpAreaStr = props[pias.helpAreaStr];
    //const onHelpAreaChange = props[pias.onHelpAreaChange];
    const onHelp = props[pias.onHelp];
    const modVarObjList = props[pias.modVarObjList];
    const onModVarsChange = props[pias.onModVarsChange];

    const state = this.state;
    const showAvgUnitCostRefSlideDrawerBool = state[showAvgUnitCostRefSlideDrawerBoolC];

    const resultContent = (
      <PICostsLiteResultContent
        {...{
          [pias.modVarObjList]: modVarObjList,
          [pias.onModVarsChange]: onModVarsChange,
          [pip.showMethodComboBox]: false,
        }}
      />
    );

    let avgUnitCostPrEPUseRefSlideDrawer = null;

    if (showAvgUnitCostRefSlideDrawerBool) {
      avgUnitCostPrEPUseRefSlideDrawer = (
        <PIAvgUnitCostPrEPUseRefSlideDrawer
          {...{
            [pias.helpAreaStr]: helpAreaStr,
            [pias.onHelp]: onHelp,

            [pias.modVarObjList]: modVarObjList,

            [pip.onToggleRefSlideDrawer]: this.onAvgUnitCostRefSlideDrawerClick,
          }}
        />
      );
    }

    return (
      <React.Fragment>
        {resultContent}
        {avgUnitCostPrEPUseRefSlideDrawer}
      </React.Fragment>
    );
  };

  renderSelectedTabContent = () => {
    const state = this.state;
    const selectedTabIdx = state[selectedTabIdxC];

    // let assignContVisitsTabContents = null;
    // let assignContVisitSchedTabContents = null;
    let costsTabContents = null;
    let resultsTabContents = null;

    // if (selectedTabIdx === assignContVisitsTab) {
    //
    //     assignContVisitsTabContents = this.renderAssignContVisitsTabContents();
    //
    // }
    //
    // else if (selectedTabIdx === assignContVisitSchedTab) {
    //
    //     assignContVisitSchedTabContents = this.renderAssignContVisitSchedTabContents();
    //
    // }

    if (selectedTabIdx === costsTab) {
      costsTabContents = this.renderCostsTabContents();
    } else if (selectedTabIdx === resultsTab) {
      resultsTabContents = this.renderResultsTabContents();
    }

    return (
      <React.Fragment>
        {/*{assignContVisitsTabContents}*/}
        {/*{assignContVisitSchedTabContents}*/}
        {costsTabContents}
        {resultsTabContents}
      </React.Fragment>
    );
  };

  renderServDelivStratDialog = () => {
    let servDelivStratDialog = null;

    /* State */

    const state = this.state;
    const showServDelivStratDialogBool = state[showServDelivStratDialogBoolC];

    if (showServDelivStratDialogBool) {
      const servDelivStratHeader = (
        <p
          key={"servDelivStratHeader"}
          style={{
            ...Theme.textFontStyle,
            color: Theme.PI_PrimaryColor,
            padding: 0,
            margin: 0,
          }}
        >
          {RS(SC.GB_stServDelivStrats)}
        </p>
      );

      const servDelivStratText = (
        <p
          key={"servDelivStratText"}
          style={{
            ...Theme.textFontStyle,
            padding: 0,
            margin: 0,
          }}
        >
          {RS(SC.GB_stServDelivStratExplan)}
        </p>
      );

      servDelivStratDialog = (
        <TDialog
          actions={["mrClose"]}
          content={[servDelivStratText]}
          header={[servDelivStratHeader]}
          key={"contCurveDialog"}
          onClose={this.onServDelivStratDialogClose}
        />
      );
    }

    return servDelivStratDialog;
  };

  render() {
    try {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const onModVarsChange = props[pias.onModVarsChange];

      const state = this.state;
      const selectedTabIdx = state[selectedTabIdxC];

      const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const numMethods = piasu.getTotalNumMethods(methodObjArr);

      let formHeightStyle = {};

      const areaLab = (
        <p
          style={{
            display: "inline-block",
            ...Theme.pageHeadingFontStyle,
            marginTop: 10,
          }}
        >
          {RS(SC.GB_stCostsLite)}
        </p>
      );

      let methodComboBox = null;

      /* Continuation visit schedules are not method dependent, and when assigning continuation visit
               schedules to priority populations, it's done in one table rather than a separate one for each method. */
      //if ((numMethods > 1) && (selectedTabIdx !== assignContVisitsTab) && (selectedTabIdx !== assignContVisitSchedTab)) {
      if (numMethods > 1) {
        methodComboBox = (
          <PIMethodComboBox
            {...{
              // [PIMethodComboBoxProps.outerStyle] : {
              //     marginTop : 20,
              // },

              [pias.modVarObjList]: modVarObjList,
              [pias.onModVarsChange]: onModVarsChange,

              [PIMethodComboBoxProps.row]: false,
            }}
          />
        );
      }

      let easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);
      const doNotSetTargsBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);

      let tabTitles = [
        // RS(SC.GB_stAssignContVisits),
        // RS(SC.GB_stAssignContVisitSchedules),
        RS(SC.GB_stSetUp),
      ];
      if (doNotSetTargsBool) {
        tabTitles.push(RS(SC.GB_stUnitCosts));
        finalTab = resultsTab;
      } else finalTab = costsTab;

      const tabs = (
        <TTabs2
          onChange={this.onTabIdxChange}
          selectedTabIdx={selectedTabIdx}
          style={{
            marginTop: 10,
          }}
          tabBackgroundColor={"inherit"}
          tabBarOutline={"none"}
          tabContents={["", ""]} //, "", ""]}
          tabTitles={tabTitles}
        />
      );

      //let emptyRefBtnDiv = null;
      let defCostsRefBtn = null;
      let avgUnitCostsRefBtn = null;

      const selectedTabContent = this.renderSelectedTabContent();

      // if (selectedTabIdx === assignContVisitSchedTab) {
      //
      //     formHeightStyle = {
      //         height : 500,
      //     };
      //
      // }

      if (selectedTabIdx === costsTab) {
        defCostsRefBtn = (
          <React.Fragment>
            <PIResetDefaultCostsBtn
              modVarObjList={modVarObjList}
              onModVarsChange={onModVarsChange}
              onCalculatingChange={props.onCalculatingChange}
            />
            <TButton
              caption={RS(SC.GB_stDefaultCostPatterns)}
              containerStyle={{
                //float        : "right", // same as saveButton
                display: "inline-block",
                marginRight: 10,
                marginTop: 0, //4, // same as saveButton
              }}
              key={"defCostsRefBtn"}
              onClick={this.onDefCostsPerVisitRefSlideDrawerClick}
              style={{
                backgroundColor: Theme.PI_PrimaryColor,
                padding: 0, // same as saveButton
              }}
            />
          </React.Fragment>
        );
      } else if (selectedTabIdx === resultsTab) {
        // avgUnitCostsRefBtn =
        //     <TButton
        //         caption        = {RS(SC.GB_stAvgUnitCost)}//RS(SC.GB_stReference)}
        //         containerStyle = {{
        //             float        : "right", // same as saveButton
        //             marginRight  : 10,
        //             marginTop    : 0,//4, // same as saveButton
        //         }}
        //         key            = {"resultsRefBtn"}
        //         onClick        = {this.onAvgUnitCostRefSlideDrawerClick}
        //         style          = {{
        //             backgroundColor : Theme.PI_PrimaryColor,
        //             padding         : 0, // same as saveButton
        //         }}
        //     />;
      }

      /* Add empty space above tabs if no reference button is showing so the
               tabs show up at the same place vertically no matter which tab you click. */
      if (selectedTabIdx !== costsTab && selectedTabIdx !== resultsTab) {
        /*emptyRefBtnDiv =
                    <div
                        key = {"emptyRefBtnDiv"}
                        style = {{
                            height : 40,
                            width  : 1,
                        }}
                    />;*/
      }

      const backPageID = piasu.getPageID(modVarObjList, pic.costsLiteFormOrder, pic.back);
      const nextPageID = piasu.getPageID(modVarObjList, pic.costsLiteFormOrder, pic.next);

      const navBtnDiv = (
        <PINavBtnDiv
          {...{
            [PINavBtnDivProps.showBackBtn]: backPageID !== pic.noPageID,
            [PINavBtnDivProps.showNextBtn]: nextPageID !== pic.noPageID,
            [PINavBtnDivProps.onBackBtnClick]: () => this.onNavBtnClick(pic.back),
            [PINavBtnDivProps.onNextBtnClick]: () => this.onNavBtnClick(pic.next),
          }}
        />
      );

      const servDelivStratDialog = this.renderServDelivStratDialog();

      /* formHeightStyle: Some tables unmount and remount (such as those implementing comboboxes and
               those that change font color). This changes the height of the table, causing the
               AppPage scrollbar to change position. To prevent this, if the table is shown on the screen,
               set the height of the form large enough to cover the entire vertical span of all
               controls on the form. */
      return (
        <div
          style={{
            ...formHeightStyle,
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <div
            style={{
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <div>{areaLab}</div>
            <div>
              {defCostsRefBtn}
              {avgUnitCostsRefBtn}
            </div>
          </div>

          <Divider
            style={{
              ...Theme.dividerStyle,
            }}
          />

          {tabs}
          {methodComboBox}
          {selectedTabContent}
          {navBtnDiv}
          {servDelivStratDialog}
        </div>
      );
    } catch (exception) {
      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <h3>{exception.name + ": " + exception.message}</h3>
        </div>
      );
    }
  }
}

export default PICostsLiteForm;
