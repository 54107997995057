import { create } from "zustand";
import { devtools } from "zustand/middleware";

const useStore = create(
  devtools(
    (set) => ({
      activeArea: null,
      noteEditorOpen: false,
      notes: {},
      updateActiveArea: (activeArea) =>
        set(() => ({
          activeArea,
        })),
      updateNoteEditorOpen: (noteEditorOpen) =>
        set(() => ({
          noteEditorOpen,
        })),
      updateNotes: (notes) =>
        set(() => ({
          notes,
        })),
      updateActiveNote: (note) =>
        set((state) => ({
          notes: {
            ...state.notes,
            [state.activeArea]: note,
          },
        })),
    }),
    {
      enabled: process.env.NODE_ENV === "development",
    }
  )
);

export default useStore;
