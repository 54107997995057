import React, { Component } from "react";
import * as PropTypes from "prop-types";

import * as gbu from "../../../GB/GBUtil";
import * as pias from "../../NonComponents/PIAppState";
import * as pip from "../../NonComponents/PIProps";
import * as pisc from "../../NonComponents/PIServerConst";
import * as piasu from "../../NonComponents/PIAppStateUtil";

import PIPercClientsOnPrEPGraph from "../../Graphs/PIPercClientsOnPrEPGraph";

import { PIContCurvePercOnPrEPTable } from "../../Tables/PIContCurvePercOnPrEPTable";
import { PICustomRowTableProps } from "../../Other/PICustomRowsUtil";

class PITargContRefForm extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),
  };

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  render() {
    const props = this.props;
    const modVarsObjList = props[pias.modVarObjList];
    const onModVarsChange = props[pias.onModVarsChange];
    const origModVarObjArr = props[pias.origModVarObjArr];

    const defineCurvesTable = (
      <PIContCurvePercOnPrEPTable
        {...{
          [PICustomRowTableProps.allowEditsBoolC]: false,

          [pias.origModVarObjArr]: origModVarObjArr,
          [pias.modVarObjList]: modVarsObjList,
          [pias.onModVarsChange]: onModVarsChange,
        }}
      />
    );

    const contCurveObjList = piasu.getModVarValue(modVarsObjList, pisc.continuationCurvesMVTag);

    const percClientsPrEPChart = (
      <PIPercClientsOnPrEPGraph
        {...{
          [pip.contCurveObjList]: contCurveObjList,
        }}
      />
    );

    return (
      <React.Fragment>
        {defineCurvesTable}
        {percClientsPrEPChart}
      </React.Fragment>
    );
  }
}

export default PITargContRefForm;
