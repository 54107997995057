import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import Divider from "@material-ui/core/Divider";

import * as Theme from "../../../app/Theme";

import * as gbu from "../../GB/GBUtil";

import * as pic from "../NonComponents/PIConst";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as pip from "../NonComponents/PIProps";
import * as php from "../NonComponents/PIHelp";
import PINavBtnDiv, { PINavBtnDivProps } from "../Other/PINavBtnDiv";
import PIMethodComboBox, { PIMethodComboBoxProps } from "../Other/PIMethodComboBox";
import PICommodityForecastingResultContent from "../ResultContent/PICommodityForecastingResultContent";
import PITimePeriodDiv, { PITimePeriodDivProps } from "../Other/PITimePeriodDiv";
import { cloneObj } from "../../GB/GBUtil";
import * as pieh from "../NonComponents/PIEventHandlers";
import PITimePeriodLab, { PITimePeriodLabProps } from "../Other/PITimePeriodLab";

class PICommoditiesForecastingForm extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.onDialogChange]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelpAreaChange]: PropTypes.func,

    [pias.onPageChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pias.helpAreaStr]: php.commForecFM_HP,
    [pias.onHelpAreaChange]: () => console.log(pias.onHelpAreaChange),

    [pias.onPageChange]: () => console.log(pias.onPageChange),
  };

  constructor(props) {
    super(props);

    const modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
    const dateRangeDisplayObj = piasu.getDateRangeDisplayObj(modVarObjListClone);

    this.state = {
      [pip.dateRangeDisplayObj]: dateRangeDisplayObj,
    };
  }

  componentDidMount() {
    this.props[pias.onHelpAreaChange](php.commForecFM_HP);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onDateRangeDisplayChange = (monthOrYearInt, startOrEndInt, successFn) => {
    try {
      const state = this.state;
      let dateRangeDisplayObjClone = gbu.cloneObj(state[pip.dateRangeDisplayObj]);

      piasu.setTimeframeData(dateRangeDisplayObjClone, monthOrYearInt, startOrEndInt);

      this.setState({
        [pip.dateRangeDisplayObj]: dateRangeDisplayObjClone,
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  /* We only want to validate the time period, set it to the ModVars, and run calculations if the user clicks
       a 'Set period' button now. */
  onSetPeriod = () => {
    const props = this.props;
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onDialogChange = props[pias.onDialogChange];
    const modVarObjListClone = cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];
    const origModVarObjArr = props[pias.origModVarObjArr];

    const state = this.state;
    let dateRangeDisplayObjClone = gbu.cloneObj(state[pip.dateRangeDisplayObj]);
    let timePeriodObjs = {
      [pip.dateRangeDisplayObj]: dateRangeDisplayObjClone,
    };

    pieh.onSetTimePeriod(
      timePeriodObjs,
      undefined,
      false,
      modVarObjListClone,
      origModVarObjArr,
      onModVarsChange,
      onCalculatingChange,
      onDialogChange
    );
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderResultsContents = () => {
    const props = this.props;
    //const helpAreaStr = props[pias.helpAreaStr];
    //const onHelpAreaChange = props[pias.onHelpAreaChange];
    //const onHelp = props[pias.onHelp];
    const modVarObjList = props[pias.modVarObjList];
    const onModVarsChange = props[pias.onModVarsChange];

    const resultContent = (
      <PICommodityForecastingResultContent
        {...{
          [pias.modVarObjList]: modVarObjList,
          [pias.onModVarsChange]: onModVarsChange,
          [pip.showMethodComboBox]: false,
        }}
      />
    );

    return <React.Fragment>{resultContent}</React.Fragment>;
  };

  render() {
    try {
      const props = this.props;
      const onCalculatingChange = props[pias.onCalculatingChange];
      const modVarObjList = props[pias.modVarObjList];
      const onModVarsChange = props[pias.onModVarsChange];
      const onPageChange = props[pias.onPageChange];

      const state = this.state;
      const dateRangeDisplayObj = state[pip.dateRangeDisplayObj];

      //const state = this.state;

      const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const dateRangeDisplayObjAppState = piasu.getDateRangeDisplayObj(modVarObjList);
      const targSettingPeriodObjAppState = piasu.getTargSettingPeriodObj(modVarObjList);

      const numMethods = piasu.getTotalNumMethods(methodObjArr);

      const areaLab = (
        <p
          style={{
            display: "inline-block",
            ...Theme.pageHeadingFontStyle,
            marginTop: 10,
          }}
        >
          {RS(SC.GB_stCommoditiesForecasting)}
        </p>
      );

      const districtPopsTargSettingPeriodLab = (
        <PITimePeriodLab
          {...{
            [pip.timePeriodObj]: targSettingPeriodObjAppState,

            [pip.timePeriodType]: pic.targSetPeriod,

            [PITimePeriodLabProps.style]: {
              display: "block",
              marginTop: Theme.topIndent,
            },
          }}
        />
      );

      const dateRangeDisplayDiv = (
        <PITimePeriodDiv
          {...{
            [pip.boundingTimePeriodObj]: piasu.getTargSettingPeriodObj(modVarObjList),

            [pip.onSetPeriod]: this.onSetPeriod,

            [PITimePeriodDivProps.style]: {
              marginTop: Theme.topIndent,
            },

            [pip.onTimePeriodChange]: this.onDateRangeDisplayChange,

            [pip.timePeriodCaption]: RS(SC.GB_stDateRangeForDisplay),

            [pip.timePeriodObj]: dateRangeDisplayObj,
            [pip.timePeriodObjAppState]: dateRangeDisplayObjAppState,

            [pip.timePeriodType]: pic.dateRangeDisplayPeriod,
          }}
        />
      );

      let methodComboBox = null;

      if (numMethods > 1) {
        methodComboBox = (
          <PIMethodComboBox
            {...{
              [pias.onCalculatingChange]: onCalculatingChange,

              [PIMethodComboBoxProps.outerStyle]: {
                marginTop: 20,
              },

              [pias.modVarObjList]: modVarObjList,
              [pias.onModVarsChange]: onModVarsChange,

              [pip.recalcBool]: true,

              [PIMethodComboBoxProps.row]: false,
            }}
          />
        );
      }

      // const progDataLab =
      //     <p
      //         style = {{
      //             marginTop   : 30,
      //             marginRight : 20,
      //             ...Theme.labelStyle,
      //         }}
      //     >
      //         {RS(SC.GB_stProgramData)}
      //     </p>;

      // const progDatTable =
      //     <PICommoditiesForecastProgramDataResTable
      //         {...{
      //             [pias.modVarObjList]                    : modVarObjList,
      //             [pias.onModVarsChange]                  : onModVarsChange
      //         }}
      //     />;

      const resultsContent = this.renderResultsContents();

      const backPageID = piasu.getPageID(modVarObjList, pic.commoditiesForecastFormOrder, pic.back);
      const nextPageID = piasu.getPageID(modVarObjList, pic.commoditiesForecastFormOrder, pic.next);

      const navBtnDiv = (
        <PINavBtnDiv
          {...{
            [PINavBtnDivProps.showBackBtn]: backPageID !== pic.noPageID,
            [PINavBtnDivProps.showNextBtn]: nextPageID !== pic.noPageID,
            [PINavBtnDivProps.onBackBtnClick]: () => onPageChange(backPageID), //pic.disagTargetsForm),//markCapacityHide pic.capacityForm),
            [PINavBtnDivProps.onNextBtnClick]: () => onPageChange(nextPageID), //pic.resultsDashboardForm),
          }}
        />
      );

      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          {areaLab}

          <Divider
            style={{
              ...Theme.dividerStyle,
            }}
          />

          {districtPopsTargSettingPeriodLab}
          {dateRangeDisplayDiv}
          {methodComboBox}
          {/*{progDataLab}*/}
          {/*{progDatTable}*/}
          {resultsContent}
          {navBtnDiv}
        </div>
      );
    } catch (exception) {
      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <h3>{exception.name + ": " + exception.message}</h3>
        </div>
      );
    }
  }
}

export default PICommoditiesForecastingForm;
