export const appBarHeight = "appBarHeight";
export const appBarTitleStyle = "appBarTitleStyle";
/* PIAppContent and all components on it use appSideBarWidth
   while PIAppSideBar and all components on it use appSideBarWidthClosed, appSideBarWidthOpen.
   Most components should only care about the single appSideBarWidth, but TSideDrawer needs to know
   how wide to be when it's "opened" and "closed".
 */
export const appSideBarWidth = "appSideBarWidth";
export const appSideBarWidthClosed = "appSideBarWidthClosed";
export const appSideBarWidthOpen = "appSideBarWidthOpen";
export const children = "children";
export const aggCostIndicator = "aggCostIndicator";
export const dialogHeaderText = "dialogHeaderText";
export const onContCurveChange = "onContCurveChange";
export const onDataElementsChange = "onDataElementsChange";
export const onIndProgDataPeriodChange = "onIndProgDataPeriodChange";
export const onPercOrNumChange = "onPercOrNumChange";
export const onPrEPScreenOptionChange = "onPrEPScreenOptionChange";
export const onPriorityPopChange = "onPriorityPopChange";
export const onProgDataSettingPeriodChange = "onProgDataSettingPeriodChange";
export const onProgDataSettingPeriodChange2 = "onProgDataSettingPeriodChange2";
export const onSetPeriod = "onSetPeriod";
export const onShowCustomItemsSlideDrawerChange = "onShowCustomItemsSlideDrawerChange";
export const showInstructionsBool = "showInstructionsBool";
export const onSlideDrawerClose = "onSlideDrawerClose";
export const onTargSettingPeriodChange = "onTargSettingPeriodChange";
export const onTimePeriodChange = "onTimePeriodChange";
export const onToggleInfoDialog = "onToggleInfoDialog";
export const onToggleRefSlideDrawer = "onToggleRefSlideDrawer";
export const onToggleCalcSlideDrawer = "onToggleCalcSlideDrawer";
export const onAddBtnClick = "onAddBtnClick";
export const onDeleteBtnClick = "onDeleteBtnClick";
export const onMoveUpBtnClick = "onMoveUpBtnClick";
export const onMoveDownBtnClick = "onMoveDownBtnClick";
export const onInfoBtnClick = "onInfoBtnClick";
export const recalcBool = "recalcBool";
export const rDec = "rDec";
export const selectedTabIdx = "selectedTabIdx";
export const methodSelectedTabIdx = "methodSelectedTabIdx";
export const showMethodComboBox = "showMethodComboBox";
export const showRefSlideDrawer = "showRefSlideDrawer";
export const showCalcSlideDrawer = "showCalcSlideDrawer";
export const showTargetSettingPeriodLab = "showTargetSettingPeriodLab";
export const showReportingPeriodDiv = "showReportingPeriodDiv";
export const tableKey = "tableKey";
export const targEst = "targEst";

/* These are used to manipulate parts of app state (such as things in the ModVar object list,
   but are not explicitly part of app state themselves, so they are put here. */
export const areaTypeInt = "areaTypeInt"; // temporary until we have modVar
export const areaInfo2DArr = "areaInfo2DArr";
export const boundingTimePeriodObj = "boundingTimePeriodObj";
export const calculatingBool = "calculatingBool";
export const comboBoxOuterStyle = "comboBoxOuterStyle";
export const comboBoxAndLabStyle = "comboBoxAndLabStyle";
export const comboBoxAndLabRowBool = "rowBool";
export const contCurveObjList = "contCurveObjList";
export const countryCodeInt = "countryCodeInt";
export const countryISO3Str = "countryISO3Str";
export const dataElements1DBoolArray = "dataElements1DBoolArray";
export const dateRangeDisplayObj = "dateRangeDisplayObj";
export const HIVPrev1DFltArray = "HIVPrev1DFltArray";
export const indProgDataPeriodObj = "indProgDataPeriodObj";
export const percIndicPrEP1DFltArray = "percIndicPrEP1DFltArray";
export const percOrNumByte = "percOrNumByte";
export const percPrEPElig1DFltArray = "percPrEPElig1DFltArray";
export const popSize1DFltArray = "popSize1DFltArray";
export const potUsersToTakePrEP1DFltArray = "potUsersToTakePrEP1DFltArray";
export const priorPopObjList = "priorityPopObjList";
export const progDataPeriodObj = "progDataPeriodObj";
export const PrEPScreenOptionMstIDStr = "PrEPScreenOptionMstIDStr";
export const selectedMethodMstIDStr = "selectedMethodMstIDStr";
export const onSelectedMethodChange = "onSelectedMethodChange";
export const selectedMethodMVTagStr = "selectedMethodMVTagStr";
export const scaleUpTrendsObjList = "scaleUpTrendsObjList";
export const showInDashboardAreaBool = "showInDashboardAreaBool";
export const showInTargetsAreaBool = "showInTargetsAreaBool";
export const spaceAfterColonStr = "spaceAfterColonStr";
export const targClientsInit1DFltArray = "targClientsInit1DFltArray";
export const targSetOptionMstIDStr = "targSetOptionMstIDStr";
export const targSettingPeriodObj = "targSettingPeriodObj";
export const timePeriodObj = "timePeriodObj";
export const timePeriodObjAppState = "timePeriodObjState";
export const totalCosts1DIntArray = "totalCosts1DIntArray";
export const totalInfAvtd1DIntArray = "totalInfAvtd1DIntArray";
export const totalInits1DIntArray = "totalInits1DIntArray";
export const varyCostByAreaDetCostsBool = "varyCostByAreaDetCostsBool"; // temporary until we have modvar

/* progDataSettingPeriodObj and targSettingPeriodObj fields */

export const endMonthInt = "endMonthInt";
export const endYearInt = "endYearInt";
export const startMonthInt = "startMonthInt";
export const startYearInt = "startYearInt";

/* Other app-specific props. */

export const timePeriodType = "timePeriodType";
export const timePeriodCaption = "timePeriodCaption";
export const timePeriodLabStyle = "timePeriodLabStyle";

/* For items slide drawer. */

export const itemType = "itemType";

export const allowCustomItemsBool = "allowCustomItemsBool";
export const itemDrawerIDStr = "itemDrawerIDStr";
export const itemDrawerTitleStr = "itemDrawerTitleStr";
export const mngCustomItemsStr = "mngCustomItemsStr";
export const selectItemsStr = "selectItemsStr";
export const yourItemsStr = "yourItemsStr";
export const onSlideDrawerSaveBtnClick = "onSlideDrawerSaveBtnClick";
export const onMethodSlideDrawerChange = "onMethodSlideDrawerChange";
export const onPriorPopsSlideDrawerChange = "onPriorPopsSlideDrawerChange";
export const onContSlideDrawerChange = "onContSlideDrawerChange";
export const onVisitSchedSlideDrawerChange = "onVisitSchedSlideDrawerChange";
export const onProgDataSlideDrawerChange = "onProgDataSlideDrawerChange";
export const onNextAreaChange = "onNextAreaChange";
export const onPrevAreaChange = "onPrevAreaChange";

/* For custom item form */

export const addItemNoteStr = "addItemNoteStr";
export const customItemDrawerTitleStr = "customItemDrawerTitleStr";
export const editItemInstructStr = "editItemInstructStr";
export const itemTypeStr = "itemTypeStr";
export const removeItemNoteStr = "removeItemNoteStr";
export const moveItemNoteStr = "moveItemNoteStr";
export const moveItemDownNoteStr = "moveItemDownNoteStr";

/* For local state needed to maintain sheet selections. */

export const selectedRegions = "selectedRegions";
export const rowStart = "rowStart";
export const rowEnd = "rowEnd";
export const columnStart = "columnStart";
export const columnEnd = "columnEnd";

export const focusedCell = "focusedCell";
export const rowFocus = "row";
export const colFocus = "column";
