import en from "../../lang/en.json";
import fr from "../../lang/fr.json";
import * as SC from "./PIStringConst";

// The order of these must match the order expected in the strings.
export const ValidLanguages = {
  en: SC.GB_stEnglish,
  fr: SC.GB_stFrancais,
};

export const getUserLanguage = () => sessionStorage.getItem("userLanguage") ?? localStorage.getItem("userLanguage");

export const getUserLanguageCode = () => {
  let code = "en";

  const language = getUserLanguage();

  if (!language) {
    return code;
  }

  const languageNumber = parseInt(language, 10);

  if (!languageNumber) {
    return code;
  }

  const languageIndex = languageNumber - 1;

  return Object.keys(ValidLanguages)[languageIndex] ?? code;
};

export const setUserLanguage = (languageCode) => {
  const languageIndex = Math.max(Object.keys(ValidLanguages).indexOf(languageCode), 0);

  const value = (languageIndex + 1).toString();

  localStorage.setItem("userLanguage", value);
  sessionStorage.setItem("userLanguage", value);
};

export function RS(id) {
  // Tolgee treats "" as untranslated, so it's set to "NULL" in Tolgee. Shouldn't really be translated at all.
  if (id === "GB_stNull") {
    return "";
  }

  const translations = {
    en,
    fr,
  };

  const languageCode = getUserLanguageCode();

  const translation = translations[languageCode][id] ?? translations["en"][id];

  if (translation == null) {
    // Production hotfix - hardcoded fallback to allow Cop25 translations
    // without needing to import potentially incompatible tolgee strings.
    switch (id) {
      case "PI_stAccessFullCop25":
        return languageCode === "fr"
          ? "Accédez à la version complète COP25 de PrEP-it."
          : "Access the full, Cop25 version of PrEP-it.";

      case "GB_stSpecialVersionOfPrEP":
        return languageCode === "fr"
          ? "Version spéciale de PrEP-it avec mises à jour pour améliorer la définition des objectifs pour plusieurs méthodes PrEP."
          : "Special version of PrEP-it with updates to improve target setting for multiple PrEP methods.";

      case "PI_stAccessStdVersion":
        return languageCode === "fr"
          ? "Accédez à la version standard complète de PrEP-it. Utilisez cette version pour les sessions créées précédemment."
          : "Access the full, standard version of PrEP-it. Use this version for previously-created sessions.";

      case "GB_stPrEPitForCop25":
        return languageCode === "fr" ? "PrEP-it pour COP25" : "PrEP-it for COP25";

      case "GB_stQuestionsAboutVersionsEmail":
        return languageCode === "fr"
          ? "Si vous avez des questions sur les versions de PrEP-it ou si vous rencontrez des problèmes, veuillez demander l'aide en ecrivant à PrEP-it à prepithelp@avenirhealth.org"
          : "If you have questions about PrEP-it versions or encounter any issues, please contact PrEP-it Help at prepithelp@avenirhealth.org";

      default:
        console.error(` ${languageCode} translation for ${id} not found`);
    }
  }

  return translation ?? translations["en"][id] ?? "";
}
