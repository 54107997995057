import { PropTypes } from "prop-types";

import TSlideDrawer from "../../common/TSlideDrawer";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

const PIUpdateInfoContents = (props) => {
  return (
    <div>
      <p>31 January 2024 – Version 1.78.56</p>
      <ul>
        <li>
          <p>Addition of default costs per visit and cost per injection for CAB-LA</p>
        </li>
        <li>
          <p>
            Users who have uploaded program data (historical numbers of initiations or reinitiations) can now modify
            their program data period without losing their uploaded data. This means users can create scenarios that
            start at some point in the past (e.g. last year) and project targets forward from that point. The original
            program data period can later be restored without reuploading program data.
          </p>
        </li>
        <li>
          <p>Updated PrEP-it Data Templates in English and French</p>
        </li>
        <li>
          <p>Updated French translation</p>
        </li>
        <li>
          <p>
            Fixed a bug that caused the data shown in result figures to display incorrectly when downloaded as a .csv
            file.
          </p>
        </li>
        <li>
          <p>Server performance improvements in increase speed</p>
        </li>
      </ul>

      <p>27 July 2023 – Version 1.78.0.35</p>
      <p>
        The previous version of PrEP-it (1.78.0.3) will be available through 30 Sep 2023 at{" "}
        <a href="https://cop23.prepitweb.org/">https://cop23.prepitweb.org/</a>.
      </p>
      <ul>
        <li>
          <p>
            New “Notes” function added, allowing users to document data sources, assumptions, questions, etc. Notes are
            available on each tab in PrEP-it and are saved with downloaded session files.
          </p>
        </li>
        <li>
          <p>Addition of two new default populations: “Pregnant and Breastfeeding People” and “Transgender People”</p>
        </li>
        <li>
          <p>New “Reset to defaults” button on many tabs to reload default values</p>
        </li>
        <li>
          <p>
            Updated impact factors derived from 2021 national HIV estimates. Values in previously created sessions will
            not be updated unless the user clicks “Reset to defaults” to load the new values.
          </p>
        </li>
        <li>
          <p>
            Target setting results for “95-95-95” and “Constant coverage” scenarios are now displayed as ranges, rather
            than separately for each scenario.
          </p>
        </li>
        <li>
          <p>New ability to constrain targets based on the quantity of PrEP commodity available by method</p>
        </li>
        <li>
          <p>Revised figures for Targets Results</p>
        </li>
        <li>
          <p>Program data is now displayed by month when uploaded.</p>
        </li>
        <li>
          <p>
            Addition of checkboxes in Costs module to turn particular cost line items on and off when calculating unit
            costs per person-year on PrEP and total costs for budget purposes. Note that all costs are always included
            when calculating costs per infection averted.
          </p>
        </li>
        <li>
          <p>
            Changes to commodities forecasting module to align the number of units of product dispensed with visit
            schedules: Previously, the number of units of product dispensed at each initiation and continuation visit
            was set by the user for each method. After this revision, the number of units of product dispensed at each
            visit is determined by the number of months of protection provided (Configuration &gt; Methods) and the
            visit schedules assigned by population (Configuration &gt; Visit schedules) such that product dispensed is
            sufficient to reach the next continuation visit.
          </p>
        </li>
      </ul>
    </div>
  );
};

const PIUpdateInfoSlideDrawer = (props) => {
  return (
    props.show && (
      <TSlideDrawer
        anchor={"right"}
        onClose={props.onToggleSlideDrawer}
        content={<PIUpdateInfoContents />}
        headerTitleStyle={{
          color: Theme.PI_PrimaryColor,
        }}
        saveButton={false}
        title={RS(SC.GB_stUpdatesButtonText)}
        width={Theme.slideDrawerLv2Width}
        level={1}
      />
    )
  );
};

PIUpdateInfoSlideDrawer.propTypes = {
  show: PropTypes.bool,
  onToggleSlideDrawer: PropTypes.func,
};

PIUpdateInfoSlideDrawer.defaultProps = {
  show: true,
  onToggleSlideDrawer: () => {},
};

export default PIUpdateInfoSlideDrawer;
