import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as pic from "../NonComponents/PIConst";
import * as pisc from "../NonComponents/PIServerConst";
import * as pias from "./PIAppState";
//import * as piasu from "./PIAppStateUtil";

/*****************************************************************************/
/*    Utility methods that are ModVar-independent should be placed here.     */
/*****************************************************************************/

/* These functions return info about default items for user-entered lists. They are
   here because they do not depend on the ModVars. */

export function getPageIDFromOrder(pageOrder) {
  let pageID;

  switch (pageOrder) {
    case pic.welcomeFormOrder:
      pageID = pic.welcomeForm;
      break;

    case pic.gettingStartedFormOrder:
      pageID = pic.gettingStartedForm;
      break;

    case pic.aggregateFormOrder:
      pageID = pic.aggregateForm;
      break;

    case pic.configFormOrder:
      pageID = pic.configForm;
      break;

    case pic.detailedCostsFormOrder:
      pageID = pic.detailedCostsForm;
      break;

    case pic.costsLiteFormOrder:
      pageID = pic.costsLiteForm;
      break;

    case pic.AGYW_FormOrder:
      pageID = pic.AGYW_Form;
      break;

    case pic.impactFormOrder:
      pageID = pic.impactForm;
      break;

    case pic.targetsFormOrder:
      pageID = pic.targetsForm;
      break;

    case pic.resultsDashboardFormOrder:
      pageID = pic.resultsDashboardForm;
      break;

    case pic.disagTargetsFormOrder:
      pageID = pic.disagTargetsForm;
      break;

    case pic.commoditiesForecastFormOrder:
      pageID = pic.commoditiesForecastForm;
      break;

    default:
      pageID = pic.noPageID;
      break;
  }

  return pageID;
}

export function getDefNumItems(itemTypeByte) {
  let numItems;

  switch (itemTypeByte) {
    case pic.priorPopItems:
      numItems = pic.numDefPriorPops;
      break;

    case pic.persTypeItems:
      numItems = pic.numDefPersTypes;
      break;

    case pic.servDelivStratItems:
      numItems = pic.numDefServDelivStrats;
      break;

    case pic.labTestItems:
      numItems = pic.numDefLabTests;
      break;

    case pic.contVisitItems:
      numItems = pic.numDefContVisits;
      break;

    case pic.contVisitSchedItems:
      numItems = pic.numDefContVisitSchedules;
      break;

    case pic.contCurveItems:
      numItems = pic.numDefContCurves;
      break;

    case pic.scaleUpTrendItems:
      numItems = pic.numDefScaleUpTrends;
      break;

    case pic.minutePatternItems:
      numItems = pic.numMinPatterns;
      break;

    case pic.methodItems:
      numItems = pic.numMethods;
      break;

    default:
      numItems = 0;
      break;
  }

  return numItems;
}

export function getMaxNumItems(itemTypeByte) {
  let numItems;

  switch (itemTypeByte) {
    case pic.priorPopItems:
      numItems = 15;
      break;

    case pic.persTypeItems:
      numItems = 15; //unofficial
      break;

    case pic.servDelivStratItems:
      numItems = 15; //unofficial
      break;

    case pic.labTestItems:
      numItems = 15; //unofficial
      break;

    case pic.contVisitItems:
      numItems = 15; //unofficial
      break;

    case pic.contVisitSchedItems:
      numItems = 15;
      break;

    case pic.contCurveItems:
      numItems = 15;
      break;

    case pic.scaleUpTrendItems:
      numItems = 10;
      break;

    case pic.minutePatternItems:
      numItems = 15; //unofficial
      break;

    case pic.methodItems:
      numItems = 10;
      break;

    default:
      numItems = 0;
      break;
  }

  return numItems;
}

export function getDefItemName(itemTypeByte, mstID) {
  let itemNameStr;

  switch (itemTypeByte) {
    case pic.priorPopItems:
      itemNameStr = getDefPriorPopNameFromMstID(mstID);
      break;

    case pic.persTypeItems:
      itemNameStr = getDefPersTypeNameFromMstID(mstID);
      break;

    case pic.servDelivStratItems:
      itemNameStr = getDefServDelivStratNameFromMstID(mstID);
      break;

    case pic.labTestItems:
      itemNameStr = getDefLabTestNameFromMstID(mstID);
      break;

    case pic.contVisitItems:
      itemNameStr = getDefContVisitNameFromMstID(mstID);
      break;

    case pic.contVisitSchedItems:
      itemNameStr = getDefContVisitSchedNameFromMstID(mstID);
      break;

    case pic.contCurveItems:
      itemNameStr = getDefContCurveNameFromMstID(mstID);
      break;

    case pic.scaleUpTrendItems:
      itemNameStr = getDefScaleUpTrendNameFromMstID(mstID);
      break;

    case pic.minutePatternItems:
      itemNameStr = getDefMinPatNameFromMstID(mstID);
      break;

    case pic.methodItems:
      itemNameStr = getDefMethodNameFromMstID(mstID);
      break;

    default:
      itemNameStr = "";
      break;
  }

  return itemNameStr;
}

export function getDefItemDisplay(itemTypeByte, mstID) {
  let itemEnabledBool;

  switch (itemTypeByte) {
    case pic.contVisitItems:
      itemEnabledBool = getDefContVisitDisplay(mstID);
      break;

    default:
      itemEnabledBool = true;
      break;
  }

  return itemEnabledBool;
}

export function getDefItemEnabled(itemTypeByte, mstID) {
  let itemEnabledBool;

  switch (itemTypeByte) {
    case pic.contVisitItems:
      itemEnabledBool = getDefContVisitEnabled(mstID);
      break;

    case pic.methodItems:
      itemEnabledBool = getDefMethodEnabled(mstID);
      break;

    default:
      itemEnabledBool = true;
      break;
  }

  return itemEnabledBool;
}

export function getDefItemMstID(itemTypeByte, currID) {
  let itemMstID;

  switch (itemTypeByte) {
    case pic.priorPopItems:
      itemMstID = getDefPriorPopMstID(currID);
      break;

    case pic.persTypeItems:
      itemMstID = getDefPersTypeMstID(currID);
      break;

    case pic.servDelivStratItems:
      itemMstID = getDefServDelivStratMstID(currID);
      break;

    case pic.labTestItems:
      itemMstID = getDefLabTestMstID(currID);
      break;

    case pic.contVisitItems:
      itemMstID = getDefContVisitMstID(currID);
      break;

    case pic.contVisitSchedItems:
      itemMstID = getDefContVisitSchedMstID(currID);
      break;

    case pic.contCurveItems:
      itemMstID = getDefContCurveMstID(currID);
      break;

    case pic.scaleUpTrendItems:
      itemMstID = getDefScaleUpTrendMstID(currID);
      break;

    case pic.minutePatternItems:
      itemMstID = getDefMinPatMstID(currID);
      break;

    case pic.methodItems:
      itemMstID = getDefMethodMstID(currID);
      break;

    default:
      itemMstID = "";
      break;
  }

  return itemMstID;
}

export function getItemModVarTag(itemTypeByte) {
  let mvTagStr = "";
  switch (itemTypeByte) {
    case pic.priorPopItems:
      mvTagStr = pisc.priorPopsMVTag;
      break;

    case pic.persTypeItems:
      mvTagStr = pisc.persTypesMVTag;
      break;

    case pic.servDelivStratItems:
      mvTagStr = pisc.servDelivStratsMVTag;
      break;

    case pic.labTestItems:
      mvTagStr = pisc.labTestsMVTag;
      break;

    case pic.contVisitItems:
      mvTagStr = pisc.contVisitsMVTag;
      break;

    case pic.contVisitSchedItems:
      mvTagStr = pisc.contVisitSchedMVTag;
      break;

    case pic.contCurveItems:
      mvTagStr = pisc.continuationCurvesMVTag;
      break;

    case pic.scaleUpTrendItems:
      mvTagStr = pisc.scaleUpTrendsMVTag;
      break;

    case pic.minutePatternItems:
      mvTagStr = pisc.minPatsMVTag;
      break;

    case pic.methodItems:
      mvTagStr = pisc.methodsMVTag;
      break;

    default:
      break;
  }

  return mvTagStr;
}

export function getItemMstIDField(itemTypeByte) {
  let fieldStr = "";

  switch (itemTypeByte) {
    case pic.priorPopItems:
      fieldStr = pisc.priorPopMstID;
      break;

    case pic.persTypeItems:
      fieldStr = pisc.persTypeMstID;
      break;

    case pic.servDelivStratItems:
      fieldStr = pisc.servDelivStratMstID;
      break;

    case pic.labTestItems:
      fieldStr = pisc.labTestMstID;
      break;

    case pic.contVisitItems:
      fieldStr = pisc.contVisitMstID;
      break;

    case pic.contVisitSchedItems:
      fieldStr = pisc.contVisitSchedMstID;
      break;

    case pic.contCurveItems:
      fieldStr = pisc.contCurveMstID;
      break;

    case pic.scaleUpTrendItems:
      fieldStr = pisc.scaleUpTrendMstID;
      break;

    case pic.minutePatternItems:
      fieldStr = pisc.minPatMstID;
      break;

    case pic.methodItems:
      fieldStr = pisc.methodMstID;
      break;

    default:
      break;
  }

  return fieldStr;
}

export function getItemNameField(itemTypeByte) {
  let fieldStr = "";

  switch (itemTypeByte) {
    case pic.priorPopItems:
      fieldStr = pisc.priorPopName;
      break;

    case pic.persTypeItems:
      fieldStr = pisc.persTypeName;
      break;

    case pic.servDelivStratItems:
      fieldStr = pisc.servDelivStratName;
      break;

    case pic.labTestItems:
      fieldStr = pisc.labTestName;
      break;

    case pic.contVisitItems:
      fieldStr = pisc.contVisitName;
      break;

    case pic.contVisitSchedItems:
      fieldStr = pisc.contVisitSchedName;
      break;

    case pic.contCurveItems:
      fieldStr = pisc.contCurveName;
      break;

    case pic.scaleUpTrendItems:
      fieldStr = pisc.scaleUpTrendName;
      break;

    case pic.minutePatternItems:
      fieldStr = pisc.minPatName;
      break;

    case pic.methodItems:
      fieldStr = pisc.methodName;
      break;

    default:
      break;
  }

  return fieldStr;
}

export function getItemMethodMstIDField(itemTypeByte) {
  let fieldStr = "";

  switch (itemTypeByte) {
    case pic.persTypeItems:
      fieldStr = pisc.persTypeMethodMstID;
      break;

    case pic.servDelivStratItems:
      fieldStr = pisc.servDelivStratMethodMstID;
      break;

    case pic.labTestItems:
      fieldStr = pisc.labTestMethodMstID;
      break;

    case pic.contVisitItems:
      fieldStr = pisc.contVisitMethodMstID;
      break;

    case pic.contVisitSchedItems:
      fieldStr = pisc.contVisitSchedMethodMstID;
      break;

    case pic.minutePatternItems:
      fieldStr = pisc.minPatMethodMstID;
      break;

    default:
      break;
  }

  return fieldStr;
}

export function getItemObjArrField(itemTypeByte) {
  let fieldStr = "";

  switch (itemTypeByte) {
    // case pic.priorPopItems :
    //     mstIDStr = pisc.priorPopObjArr;
    //     break;

    case pic.persTypeItems:
      fieldStr = pisc.persTypeObjArr;
      break;

    case pic.minutePatternItems:
      fieldStr = pisc.minPatObjArr;
      break;

    case pic.servDelivStratItems:
      fieldStr = pisc.servDelivStratObjArr;
      break;

    case pic.labTestItems:
      fieldStr = pisc.labTestObjArr;
      break;

    case pic.contVisitItems:
      fieldStr = pisc.contVisitObjArr;
      break;

    case pic.contVisitSchedItems:
      fieldStr = pisc.contVisitSchedObjArr;
      break;

    default:
      break;
  }

  return fieldStr;
}

export function getCustomItemDefMstID(itemTypeByte) {
  let mstIDStr = "";

  switch (itemTypeByte) {
    case pic.priorPopItems:
      mstIDStr = pisc.SDC_PP_MstID;
      break;

    case pic.persTypeItems:
      mstIDStr = pisc.counselorMstID;
      break;

    case pic.servDelivStratItems:
      mstIDStr = pisc.strat1SDSMstID;
      break;

    case pic.labTestItems:
      mstIDStr = pisc.HIVRapidTestMstID;
      break;

    case pic.contVisitItems:
      mstIDStr = pisc.initVisitCVMstID;
      break;

    case pic.contVisitSchedItems:
      mstIDStr = pisc.contVisitSched1MstID;
      break;

    case pic.contCurveItems:
      mstIDStr = pisc.SDCsCCMstID;
      break;

    case pic.scaleUpTrendItems:
      mstIDStr = pisc.scaleUpTrendAMstID;
      break;

    case pic.methodItems:
      mstIDStr = pisc.pillMethodMstID;
      break;

    default:
      break;
  }

  return mstIDStr;
}

export function getMonthName(monthByte) {
  let name = "";

  switch (monthByte) {
    case 1:
      name = RS(SC.GB_stJanuary);
      break;

    case 2:
      name = RS(SC.GB_stFebruary);
      break;

    case 3:
      name = RS(SC.GB_stMarch);
      break;

    case 4:
      name = RS(SC.GB_stApril);
      break;

    case 5:
      name = RS(SC.GB_stMay);
      break;

    case 6:
      name = RS(SC.GB_stJune);
      break;

    case 7:
      name = RS(SC.GB_stJuly);
      break;

    case 8:
      name = RS(SC.GB_stAugust);
      break;

    case 9:
      name = RS(SC.GB_stSeptember);
      break;

    case 10:
      name = RS(SC.GB_stOctober);
      break;

    case 11:
      name = RS(SC.GB_stNovember);
      break;

    case 12:
      name = RS(SC.GB_stDecember);
      break;

    default:
      name = "";
  }

  return name;
}

export function getMonthNum(monthName) {
  let num = "";

  switch (monthName) {
    case RS(SC.GB_stJanuary).toUpperCase():
      num = 1;
      break;

    case RS(SC.GB_stFebruary).toUpperCase():
      num = 2;
      break;

    case RS(SC.GB_stMarch).toUpperCase():
      num = 3;
      break;

    case RS(SC.GB_stApril).toUpperCase():
      num = 4;
      break;

    case RS(SC.GB_stMay).toUpperCase():
      num = 5;
      break;

    case RS(SC.GB_stJune).toUpperCase():
      num = 6;
      break;

    case RS(SC.GB_stJuly).toUpperCase():
      num = 7;
      break;

    case RS(SC.GB_stAugust).toUpperCase():
      num = 8;
      break;

    case RS(SC.GB_stSeptember).toUpperCase():
      num = 9;
      break;

    case RS(SC.GB_stOctober).toUpperCase():
      num = 10;
      break;

    case RS(SC.GB_stNovember).toUpperCase():
      num = 11;
      break;

    case RS(SC.GB_stDecember).toUpperCase():
      num = 12;
      break;

    default:
      num = "";
  }

  return num;
}

export function getFullMonthsArray() {
  let months1DStrArray = [];

  for (let m = 1; m <= 12; m++) {
    months1DStrArray.push(getMonthName(m));
  }

  return months1DStrArray;
}

export function getMonthsArray(monthMstIDIntArr) {
  let months1DStrArray = [];

  for (let i = 0; i < monthMstIDIntArr.length; i++) {
    months1DStrArray.push(getMonthName(monthMstIDIntArr[i]));
  }

  return months1DStrArray;
}

/* Gets month index for TComboBox (itemIndex is 0-based). */
export function getMonthIdx(monthInt) {
  return monthInt - 1;
}

/* Gets year index for TComboBox (itemIndex is 0-based). */
export function getProgDataYearIdx(yearInt) {
  return yearInt - pic.firstPossibleProgramStartYear;
}

/* Gets year index for TComboBox (itemIndex is 0-based). */
export function getTargSetYearIdx(yearInt) {
  return yearInt - pic.firstPossibleTargetStartYear;
}

export function getDefProgramStartYearsArray() {
  let possibleYears1DIntArray = [];

  for (let yr = pic.firstPossibleProgramStartYear; yr <= pic.finalPossibleProgramStartYear; yr++) {
    possibleYears1DIntArray.push(yr);
  }

  return possibleYears1DIntArray;
}

export function getDefProgramEndYearsArray() {
  let possibleYears1DIntArray = [];

  for (let yr = pic.firstPossibleProgramEndYear; yr <= pic.finalPossibleProgramEndYear; yr++) {
    possibleYears1DIntArray.push(yr);
  }

  return possibleYears1DIntArray;
}

export function getAppModeName(appModeMstIDStr) {
  let name = "";

  switch (appModeMstIDStr) {
    case pisc.noToolSelectedMstID:
      name = RS(SC.GB_stChooseMode);
      break;

    case pisc.PrEPitStdToolMstID:
      name = RS(SC.GB_stPrEPit);
      break;

    case pisc.PrEPitEasyStartToolMstID:
      name = RS(SC.GB_stPrEPit); // Easy Start is now Standard mode RS(SC.GB_stEasyStart);
      break;

    case pisc.aggregateToolMstID:
      name = RS(SC.GB_stAggregate);
      break;

    default:
      break;
  }

  return name;
}

export function getToolModeMstID(currIDInt) {
  let mstIDStr = "";

  switch (currIDInt) {
    case pic.PrEPitStdTool:
      mstIDStr = pisc.PrEPitStdToolMstID;
      break;

    case pic.PrEPitEasyStartTool:
      mstIDStr = pisc.PrEPitEasyStartToolMstID;
      break;

    case pic.aggregateTool:
      mstIDStr = pisc.aggregateToolMstID;
      break;

    default:
      break;
  }

  return mstIDStr;
}

export function getToolModeCurrID(mstIDStr) {
  let currIDInt = pic.noToolSelected;

  switch (mstIDStr) {
    case pisc.PrEPitStdToolMstID:
      currIDInt = pic.PrEPitStdTool;
      break;

    case pisc.PrEPitEasyStartToolMstID:
      currIDInt = pic.PrEPitEasyStartTool;
      break;

    case pic.aggregateTool:
      mstIDStr = pisc.aggregateToolMstID;
      break;

    default:
  }

  return currIDInt;
}

/* Priority populations */

export function getDefPriorPopNameFromMstID(mstIDStr) {
  let name = "";

  switch (mstIDStr) {
    case pisc.SDC_PP_MstID:
      name = RS(SC.GB_stSDC);
      break;

    case pisc.AGYW_PP_MstID:
      name = RS(SC.GB_stAGYW15t24);
      break;

    case pisc.MSM_PP_MstID:
      name = RS(SC.GB_stMSM);
      break;

    case pisc.FSW_PP_MstID:
      name = RS(SC.GB_stFSW);
      break;

    case pisc.PWID_PP_MstID:
      name = RS(SC.GB_stIDU);
      break;

    case pisc.PBW_PP_MstID:
      name = RS(SC.GB_stPBW);
      break;

    case pisc.TP_PP_MstID:
      name = RS(SC.GB_stTP);
      break;

    case pisc.customItemMstID:
      name = RS(SC.GB_stCustomPriorPop) + " " + (1).toString();

      break;

    default:
      break;
  }

  return name;
}

export function getDefPriorPopAbbrFromMstID(mstIDStr) {
  let name = "";

  switch (mstIDStr) {
    case pisc.SDC_PP_MstID:
      name = RS(SC.GB_stSDCAbbr);
      break;

    case pisc.AGYW_PP_MstID:
      name = RS(SC.GB_stAGYWAbbr);
      break;

    case pisc.MSM_PP_MstID:
      name = RS(SC.GB_stMSMAbbr);
      break;

    case pisc.FSW_PP_MstID:
      name = RS(SC.GB_stFSWAbbr);
      break;

    case pisc.PWID_PP_MstID:
      name = RS(SC.GB_stIDUAbbr);
      break;

    case pisc.PBW_PP_MstID:
      name = RS(SC.GB_stPBWAbbr);
      break;

    case pisc.TP_PP_MstID:
      name = RS(SC.GB_stTPAbbr);
      break;

    default:
      break;
  }

  return name;
}

export function getDefPriorPopMstID(currIDInt) {
  let mstID = 0;

  switch (currIDInt) {
    case pic.SDC_PPCurrID:
      mstID = pisc.SDC_PP_MstID;
      break;

    case pic.AGYW_PPCurrID:
      mstID = pisc.AGYW_PP_MstID;
      break;

    case pic.MSM_PPCurrID:
      mstID = pisc.MSM_PP_MstID;
      break;

    case pic.FSW_PPCurrID:
      mstID = pisc.FSW_PP_MstID;
      break;

    case pic.PWIDU_PPCurrID:
      mstID = pisc.PWID_PP_MstID;
      break;

    default:
      break;
  }

  return mstID;
}

export function getDefPriorPopCurrID(mstIDInt) {
  let currID = 0;

  switch (mstIDInt) {
    case pisc.SDC_PP_MstID:
      currID = pic.SDC_PPCurrID;
      break;

    case pisc.AGYW_PP_MstID:
      currID = pic.AGYW_PPCurrID;
      break;

    case pisc.MSM_PP_MstID:
      currID = pic.MSM_PPCurrID;
      break;

    case pisc.FSW_PP_MstID:
      currID = pic.FSW_PPCurrID;
      break;

    case pisc.PWID_PP_MstID:
      currID = pic.PWIDU_PPCurrID;
      break;

    default:
      break;
  }

  return currID;
}

export function getDefPriorPopImpPriorPopMstID(mstIDStr) {
  let impPriorPopMstID;

  switch (mstIDStr) {
    case pisc.SDC_PP_MstID:
      impPriorPopMstID = pisc.SDC_DIPPMstID;
      break;

    case pisc.AGYW_PP_MstID:
      impPriorPopMstID = pisc.AGYW_15_24_DIPPMstID;
      break;

    case pisc.MSM_PP_MstID:
      impPriorPopMstID = pisc.MSM_DIPPMstID;
      break;

    case pisc.FSW_PP_MstID:
      impPriorPopMstID = pisc.FSW_DIPPMstID;
      break;

    case pisc.PWID_PP_MstID:
      impPriorPopMstID = pisc.PWID_DIPPMstID;
      break;

    default:
      impPriorPopMstID = pisc.SDC_DIPPMstID;
      break;
  }

  return impPriorPopMstID;
}

export function getDefImpPriorPopNameFromMstID(mstIDStr) {
  let name = "";

  switch (mstIDStr) {
    case pisc.SDC_DIPPMstID:
      name = RS(SC.GB_stSDCAbbr);
      break;

    case pisc.FSW_DIPPMstID:
      name = RS(SC.GB_stFSWAbbr);
      break;

    case pisc.MSM_DIPPMstID:
      name = RS(SC.GB_stMSMAbbr);
      break;

    case pisc.AGYW_15_19_DIPPMstID:
      name = RS(SC.GB_stAGYW15t19);
      break;

    case pisc.AGYW_18_19_DIPPMstID:
      name = RS(SC.GB_stAGYW18t19);
      break;

    case pisc.AGYW_20_24_DIPPMstID:
      name = RS(SC.GB_stAGYW20t24);
      break;

    case pisc.AGYW_15_24_DIPPMstID:
      name = RS(SC.GB_stAGYW15t24);
      break;

    case pisc.TW_DIPPMstID:
      name = RS(SC.GB_stTW);
      break;

    case pisc.PWID_DIPPMstID:
      name = RS(SC.GB_stIDUAbbr);
      break;

    case pisc.PW_DIPPMstID:
      name = RS(SC.GB_stPW);
      break;

    case pisc.BFW_DIPPMstID:
      name = RS(SC.GB_stBFW);
      break;

    case pisc.GPW_DIPPMstID:
      name = RS(SC.GB_stGenPopWomen);
      break;

    case pisc.MRW_DIPPMstID:
      name = RS(SC.GB_stMedRiskWomen);
      break;

    case pisc.GPM_DIPPMstID:
      name = RS(SC.GB_stGenPopMen);
      break;

    case pisc.MRM_DIPPMstID:
      name = RS(SC.GB_stMedRiskMen);
      break;

    case pisc.HRM_DIPPMstID:
      name = RS(SC.GB_stHighRiskHeteroMen);
      break;

    default:
      break;
  }

  return name;
}

/* Default continuation curves */

export function getDefContCurveCurrID(mstID) {
  let currID;

  switch (mstID) {
    case pisc.SDCsCCMstID:
      currID = pic.SDCsCCCurrID;
      break;

    case pisc.AGYW15t24CCMstID:
      currID = pic.AGYW15t24CCCurrID;
      break;

    case pisc.MSMCCMstID:
      currID = pic.MSMCCCurrID;
      break;

    case pisc.FSWCCMstID:
      currID = pic.FSWCCCurrID;
      break;

    case pisc.PBWCCMstID:
      currID = pic.PBWCCCurrID;
      break;

    default:
      currID = 0;
      break;
  }

  return currID;
}

export function getDefContCurveMstID(currID) {
  let mstID;

  switch (currID) {
    case pic.SDCsCCCurrID:
      mstID = pisc.SDCsCCMstID;
      break;

    case pic.AGYW15t24CCCurrID:
      mstID = pisc.AGYW15t24CCMstID;
      break;

    case pic.MSMCCCurrID:
      mstID = pisc.MSMCCMstID;
      break;

    case pic.FSWCCCurrID:
      mstID = pisc.FSWCCMstID;
      break;

    case pic.PBWCCCurrID:
      mstID = pisc.PBWCCMstID;
      break;

    default:
      mstID = 0;
      break;
  }

  return mstID;
}

export function getDefContCurveName(currIDInt) {
  let name = "";

  switch (currIDInt) {
    case pic.SDCsCCCurrID:
      name = RS(SC.GB_stSDCsCC);
      break;

    case pic.AGYW15t24CCCurrID:
      name = RS(SC.GB_stAGYW15t24CC);
      break;

    case pic.MSMCCCurrID:
      name = RS(SC.GB_stMSMAbbrCC);
      break;

    case pic.FSWCCCurrID:
      name = RS(SC.GB_stFSWAbbrCC);
      break;

    case pic.PBWCCCurrID:
      name = RS(SC.GB_sPBWAbbr);
      break;

    default:
      break;
  }

  return name;
}

export function getDefContCurveNameFromMstID(mstIDStr) {
  let name = "";

  switch (mstIDStr) {
    case pisc.SDCsCCMstID:
      name = RS(SC.GB_stSDCsCC);
      break;

    case pisc.AGYW15t24CCMstID:
      name = RS(SC.GB_stAGYW15t24CC);
      break;

    case pisc.MSMCCMstID:
      name = RS(SC.GB_stMSMAbbrCC);
      break;

    case pisc.FSWCCMstID:
      name = RS(SC.GB_stFSWAbbrCC);
      break;

    case pisc.PBWCCMstID:
      name = RS(SC.GB_sPBWAbbr);
      break;

    default:
      break;
  }

  return name;
}

/* Methods */

export function getDefMethodNameFromMstID(mstIDStr) {
  let name = "";

  switch (mstIDStr) {
    case pisc.pillMethodMstID:
      name = RS(SC.GB_stPill);
      break;

    case pisc.ringsMethodMstID:
      name = RS(SC.GB_stRing);
      break;

    case pisc.CAB_LAMethodMstID:
      name = RS(SC.GB_stCABLA);

      break;

    default:
      break;
  }

  return name;
}

export function getDefMethodUnitOfCommodityFromMstID(mstIDStr) {
  let name = "";

  switch (mstIDStr) {
    case pisc.pillMethodMstID:
      name = RS(SC.GB_stBottleLower);
      break;

    case pisc.ringsMethodMstID:
      name = RS(SC.GB_stRingLower);
      break;

    case pisc.CAB_LAMethodMstID:
      name = RS(SC.GB_stInjectionLower);

      break;

    default:
      break;
  }

  return name;
}

export function getDefMethodMstID(currIDInt) {
  let mstID = "";

  switch (currIDInt) {
    case pic.pillMethodCurrID:
      mstID = pisc.pillMethodMstID;
      break;

    case pic.ringMethodCurrID:
      mstID = pisc.ringsMethodMstID;
      break;

    case pic.CAB_LAMethodCurrID:
      mstID = pisc.CAB_LAMethodMstID;
      break;

    default:
      break;
  }

  return mstID;
}

export function getDefMethodCurrID(mstIDInt) {
  let currID = 0;

  switch (mstIDInt) {
    case pisc.pillMethodMstID:
      currID = pic.pillMethodCurrID;
      break;

    case pisc.ringsMethodMstID:
      currID = pic.ringMethodCurrID;
      break;

    case pisc.CAB_LAMethodMstID:
      currID = pic.CAB_LAMethodCurrID;
      break;

    default:
      break;
  }

  return currID;
}

export function getDefMethodEnabled(mstIDStr) {
  return true;
}

/* Default scale-up trends */

export function getDefScaleUpTrendCurrID(mstID) {
  let currID;

  switch (mstID) {
    case pisc.scaleUpTrendAMstID:
      currID = pic.scaleUpTrendACurrID;
      break;

    case pisc.scaleUpTrendBMstID:
      currID = pic.scaleUpTrendBCurrID;
      break;

    case pisc.scaleUpTrendCMstID:
      currID = pic.scaleUpTrendCCurrID;
      break;

    default:
      currID = 0;
      break;
  }

  return currID;
}

export function getDefScaleUpTrendMstID(currID) {
  let mstID;

  switch (currID) {
    case pic.scaleUpTrendACurrID:
      mstID = pisc.scaleUpTrendAMstID;
      break;

    case pic.scaleUpTrendBCurrID:
      mstID = pisc.scaleUpTrendBMstID;
      break;

    case pic.scaleUpTrendCCurrID:
      mstID = pisc.scaleUpTrendCMstID;
      break;

    default:
      mstID = 0;
      break;
  }

  return mstID;
}

export function getDefScaleUpTrendName(currIDInt) {
  let name = "";

  switch (currIDInt) {
    case pic.scaleUpTrendACurrID:
      name = RS(SC.GB_stTrendA);
      break;

    case pic.scaleUpTrendBCurrID:
      name = RS(SC.GB_stTrendB);
      break;

    case pic.scaleUpTrendCCurrID:
      name = RS(SC.GB_stTrendC);
      break;

    default:
      break;
  }

  return name;
}

export function getDefScaleUpTrendNameFromMstID(mstIDStr) {
  let name = "";

  switch (mstIDStr) {
    case pisc.scaleUpTrendAMstID:
      name = RS(SC.GB_stTrendA);
      break;

    case pisc.scaleUpTrendBMstID:
      name = RS(SC.GB_stTrendB);
      break;

    case pisc.scaleUpTrendCMstID:
      name = RS(SC.GB_stTrendC);
      break;

    default:
      break;
  }

  return name;
}

/* Scale-up types */

export function getScaleUpTypeCurrID(mstID) {
  let currID;

  switch (mstID) {
    case pisc.sShapedScaleUpMstID:
      currID = pic.sShapedScaleUpCurrID;
      break;

    case pisc.linearScaleUpMstID:
      currID = pic.linearScaleUpCurrID;
      break;

    case pisc.constantScaleUpMstID:
      currID = pic.constantScaleUpCurrID;
      break;

    default:
      currID = 0;
      break;
  }

  return currID;
}

export function getScaleUpTypeMstID(currID) {
  let mstID;

  switch (currID) {
    case pic.sShapedScaleUpCurrID:
      mstID = pisc.sShapedScaleUpMstID;
      break;

    case pic.linearScaleUpCurrID:
      mstID = pisc.linearScaleUpMstID;
      break;

    case pic.constantScaleUpCurrID:
      mstID = pisc.constantScaleUpMstID;
      break;

    default:
      mstID = 0;
      break;
  }

  return mstID;
}

export function getScaleUpTypeName(currIDInt) {
  let name = "";

  switch (currIDInt) {
    case pic.sShapedScaleUpCurrID:
      name = RS(SC.GB_stSShaped);
      break;

    case pic.linearScaleUpCurrID:
      name = RS(SC.GB_stLinear);
      break;

    case pic.constantScaleUpCurrID:
      name = RS(SC.GB_stConstant);
      break;

    default:
      break;
  }

  return name;
}

export function getScaleUpTypeNames() {
  let scaleUpTypeNames1DStrArray = [];

  for (let sut = 1; sut <= pic.numScaleUpTypes; sut++) {
    scaleUpTypeNames1DStrArray.push(getScaleUpTypeName(sut));
  }

  return scaleUpTypeNames1DStrArray;
}

/* Data elements */

export function getDefDataElement(dataElementCurrID) {
  let defDataElementNameStr = "";

  switch (dataElementCurrID) {
    case pic.numTestedHIVCurrID:
      defDataElementNameStr = RS(SC.GB_stNumTestedHIV);
      break;

    case pic.numTestedHIVNegCurrID:
      defDataElementNameStr = RS(SC.GB_stNumTestedHIVNeg);
      break;

    case pic.numScrPrEPOneStepCurrID:
      defDataElementNameStr = RS(SC.GB_stNumScrPrEPOneStep);
      break;

    case pic.numScrRiskEligCurrID:
      defDataElementNameStr = RS(SC.GB_stNumScrPotRisk);
      break;

    case pic.numScrClinEligCurrID:
      defDataElementNameStr = RS(SC.GB_stNumScrClinElig);
      break;

    case pic.numEligPrEPCurrID:
      defDataElementNameStr = RS(SC.GB_stNumEligPrEP);
      break;

    case pic.numOfferedPrEPCurrID:
      defDataElementNameStr = RS(SC.GB_stNumOfferedPrEP);
      break;

    case pic.numInitPrEPCurrID:
      defDataElementNameStr = RS(SC.GB_stNumInitPrEP);
      break;

    case pic.numReinitPrEPCurrID:
      defDataElementNameStr = RS(SC.GB_stNumReinitPrEP);
      break;

    case pic.numSeroconHIVPosCurrID:
      defDataElementNameStr = RS(SC.GB_stNumSeroconvHIVPos);
      break;

    case pic.numExpSerSideEffCurrID:
      defDataElementNameStr = RS(SC.GB_stNumSerSideEffects);
      break;

    case pic.numCustInd1:
      defDataElementNameStr = RS(SC.GB_stDefineCustInd1); //GB_stNumCustInd1);
      break;

    case pic.numCustInd2:
      defDataElementNameStr = RS(SC.GB_stDefineCustInd2); //GB_stNumCustInd2);
      break;

    case pic.numCustInd3:
      defDataElementNameStr = RS(SC.GB_stDefineCustInd3); //GB_stNumCustInd3);
      break;

    default:
      break;
  }

  return defDataElementNameStr;
}

export function getDataElementCurrID(mstID) {
  let currIDBool = false;

  switch (mstID) {
    case pisc.includeDEMstID:
      currIDBool = true;
      break;

    default:
      break;
  }

  return currIDBool;
}

export function getDataElementMstID(currIDBool) {
  let mstID = pisc.excludeDEMstID;

  switch (currIDBool) {
    case true:
      mstID = pisc.includeDEMstID;
      break;

    default:
      break;
  }

  return mstID;
}

export function getDataElementFieldStr(currID) {
  let mstIDStr = "";

  switch (currID) {
    case pic.numTestedHIVCurrID:
      mstIDStr = pisc.initTestedHIVPos;
      break;

    case pic.numTestedHIVNegCurrID:
      mstIDStr = pisc.initTestedHIVNeg;
      break;

    case pic.numScrPrEPOneStepCurrID:
      mstIDStr = pisc.initScreenedPrEP;
      break;

    case pic.numScrRiskEligCurrID:
      mstIDStr = pisc.initScreenedRisk;
      break;

    case pic.numScrClinEligCurrID:
      mstIDStr = pisc.initScreenedClinical;
      break;

    case pic.numEligPrEPCurrID:
      mstIDStr = pisc.initEligiblePrEP;
      break;

    case pic.numOfferedPrEPCurrID:
      mstIDStr = pisc.initOfferedPrEP;
      break;

    case pic.numInitPrEPCurrID:
      mstIDStr = pisc.initInitiatedPrEP;
      break;

    case pic.numReinitPrEPCurrID:
      mstIDStr = pisc.initReinitiatedPrEP;
      break;

    case pic.numSeroconHIVPosCurrID:
      mstIDStr = pisc.initSeroHIVPosOnPrEP;
      break;

    case pic.numExpSerSideEffCurrID:
      mstIDStr = pisc.initSeriousSideEffects;
      break;

    case pic.numCustInd1:
      mstIDStr = pisc.initCustomInd1;
      break;

    case pic.numCustInd2:
      mstIDStr = pisc.initCustomInd2;
      break;

    case pic.numCustInd3:
      mstIDStr = pisc.initCustomInd3;
      break;

    default:
      break;
  }

  return mstIDStr;
}

export function getDataElementCustomIndMstID(currID) {
  let mstIDStr = "";

  switch (currID) {
    case pic.numCustInd1:
      mstIDStr = pisc.customInd1ProgDataMstID;
      break;

    case pic.numCustInd2:
      mstIDStr = pisc.customInd2ProgDataMstID;
      break;

    case pic.numCustInd3:
      mstIDStr = pisc.customInd3ProgDataMstID;
      break;

    default:
      break;
  }

  return mstIDStr;
}

/* PrEP screened option */

export function getNumScreenedPrEPOption(numScreenedPrEPCurrID) {
  let numScreenedPrEPOptionStr = "";

  switch (numScreenedPrEPCurrID) {
    case pic.noDataScrCurrID:
      numScreenedPrEPOptionStr = RS(SC.GB_stNoData);
      break;

    case pic.oneStepScrCurrID:
      numScreenedPrEPOptionStr = RS(SC.GB_stOneStep);
      break;

    case pic.twoStepScrCurrID:
      numScreenedPrEPOptionStr = RS(SC.GB_stTwoStep);
      break;

    default:
      break;
  }

  return numScreenedPrEPOptionStr;
}

export function getPrEPScreenOptionCurrID(mstIDStr) {
  let currIDInt;

  switch (mstIDStr) {
    case pisc.noDataSOMstID:
      currIDInt = pic.noDataScrCurrID;
      break;

    case pisc.oneStepSOMstID:
      currIDInt = pic.oneStepScrCurrID;
      break;

    case pisc.twoStepSOMstID:
      currIDInt = pic.twoStepScrCurrID;
      break;

    default:
      currIDInt = pic.noDataScrCurrID;
      break;
  }

  return currIDInt;
}

export function getPrEPScreenOptionMstID(currIDInt) {
  let mstIDStr;

  switch (currIDInt) {
    case pic.noDataScrCurrID:
      mstIDStr = pisc.noDataSOMstID;
      break;

    case pic.oneStepScrCurrID:
      mstIDStr = pisc.oneStepSOMstID;
      break;

    case pic.twoStepScrCurrID:
      mstIDStr = pisc.twoStepSOMstID;
      break;

    default:
      mstIDStr = pisc.noDataSOMstID;
      break;
  }

  return mstIDStr;
}

/* Months */

export function getMonthFromMstID(monthMstID) {
  let monthCurrID;

  switch (monthMstID) {
    case pisc.JanMstID:
      monthCurrID = 1;
      break;

    case pisc.FebMstID:
      monthCurrID = 2;
      break;

    case pisc.MarMstID:
      monthCurrID = 3;
      break;

    case pisc.AprMstID:
      monthCurrID = 4;
      break;

    case pisc.MayMstID:
      monthCurrID = 5;
      break;

    case pisc.JunMstID:
      monthCurrID = 6;
      break;

    case pisc.JulMstID:
      monthCurrID = 7;
      break;

    case pisc.AugMstID:
      monthCurrID = 8;
      break;

    case pisc.SeptMstID:
      monthCurrID = 9;
      break;

    case pisc.OctMstID:
      monthCurrID = 10;
      break;

    case pisc.NovMstID:
      monthCurrID = 11;
      break;

    case pisc.DecMstID:
      monthCurrID = 12;
      break;

    default:
      monthCurrID = 0;
      break;
  }

  return monthCurrID;
}

export function getMonthMstID(currID) {
  let monthMstID;

  switch (currID) {
    case 1:
      monthMstID = pisc.JanMstID;
      break;

    case 2:
      monthMstID = pisc.FebMstID;
      break;

    case 3:
      monthMstID = pisc.MarMstID;
      break;

    case 4:
      monthMstID = pisc.AprMstID;
      break;

    case 5:
      monthMstID = pisc.MayMstID;
      break;

    case 6:
      monthMstID = pisc.JunMstID;
      break;

    case 7:
      monthMstID = pisc.JulMstID;
      break;

    case 8:
      monthMstID = pisc.AugMstID;
      break;

    case 9:
      monthMstID = pisc.SeptMstID;
      break;

    case 10:
      monthMstID = pisc.OctMstID;
      break;

    case 11:
      monthMstID = pisc.NovMstID;
      break;

    case 12:
      monthMstID = pisc.DecMstID;
      break;

    default:
      monthMstID = 0;
      break;
  }

  return monthMstID;
}

export function getMonthAbbrName(currID) {
  let name;

  switch (currID) {
    case 1:
      name = RS(SC.GB_stJanAbbr);
      break;

    case 2:
      name = RS(SC.GB_stFebAbbr);
      break;

    case 3:
      name = RS(SC.GB_stMarAbbr);
      break;

    case 4:
      name = RS(SC.GB_stAprAbbr);
      break;

    case 5:
      name = RS(SC.GB_stMayAbbr);
      break;

    case 6:
      name = RS(SC.GB_stJuneAbbr);
      break;

    case 7:
      name = RS(SC.GB_stJulyAbbr);
      break;

    case 8:
      name = RS(SC.GB_stAugAbbr);
      break;

    case 9:
      name = RS(SC.GB_stSeptAbbr);
      break;

    case 10:
      name = RS(SC.GB_stOctAbbr);
      break;

    case 11:
      name = RS(SC.GB_stNovAbbr);
      break;

    case 12:
      name = RS(SC.GB_stDecAbbr);
      break;

    default:
      name = "";
      break;
  }

  return name;
}

export function getMonthsBetween(startMonth, startYear, endMonth, endYear) {
  let monthsBetween = 0;

  if (startYear === endYear) {
    monthsBetween = endMonth - startMonth + 1;
  } else {
    if (endYear - startYear > 1) {
      monthsBetween = (endYear - startYear - 1) * 12;
    }

    monthsBetween = monthsBetween + (12 - startMonth + 1) + endMonth;
  }

  return monthsBetween;
}

/**
 * Get number of months between two JavaScript Dates
 * @param {Date} start Start date
 * @param {Date} end End date
 * @returns {number} Number of months between start and end
 */
export function getMonthsBetweenDates(start, end) {
  let months = (end.getFullYear() - start.getFullYear()) * 12;
  months -= start.getMonth();
  months += end.getMonth();
  return months;
}

/**
 * Convert Avenir date object to JavaScript dates
 * @param {{ startYearInt: number, endYearInt: number, startMonthInt: number, endMonthInt: number }} dateObj
 * @returns {{ start: Date, end: Date }} start and end date
 */
export function getDateObjectAsJSDates(dateObj) {
  return {
    start: new Date(dateObj.startYearInt, dateObj.startMonthInt - 1),
    end: new Date(dateObj.endYearInt, dateObj.endMonthInt - 1),
  };
}

/**
 * Convert JS dates to avenir date object
 * @param {{ start: Date, end: Date }} jsDates - start and end Date
 * @returns {{ startYearInt: number, endYearInt: number, startMonthInt: number, endMonthInt: number }} dateObj
 */
export function getJSDatesAsDateObject(jsDates) {
  return {
    startYearInt: jsDates.start.getFullYear(),
    startMonthInt: jsDates.start.getMonth() + 1,
    endYearInt: jsDates.end.getFullYear(),
    endMonthInt: jsDates.end.getMonth() + 1,
  };
}

/* Target-setting options */

export function getTargSetOption(currID) {
  let optionStr = "";

  switch (currID) {
    case pic.coverageTargSetOptCurrID:
      optionStr = RS(SC.GB_stCoverage);
      break;

    case pic.constraintsTargSetOptCurrID:
      optionStr = RS(SC.GB_stCovWithConstraints);
      break;

    case pic.manualTargSetOptCurrID:
      optionStr = RS(SC.GB_stManual);
      break;

    default:
      break;
  }

  return optionStr;
}

export function getTargSetCurrID(mstIDStr) {
  let currID = 0;

  switch (mstIDStr) {
    case pisc.coverageTargSetMstID:
      currID = pic.coverageTargSetOptCurrID;
      break;

    case pisc.constraintsTargSetMstID:
      currID = pic.constraintsTargSetOptCurrID;
      break;

    case pisc.manualTargSetMstID:
      currID = pic.manualTargSetOptCurrID;
      break;

    default:
      break;
  }

  return currID;
}

export function getTargSetMstID(currID) {
  let mstIDStr = "";

  switch (currID) {
    case pic.coverageTargSetOptCurrID:
      mstIDStr = pisc.coverageTargSetMstID;
      break;

    case pic.constraintsTargSetOptCurrID:
      mstIDStr = pisc.constraintsTargSetMstID;
      break;

    case pic.manualTargSetOptCurrID:
      mstIDStr = pisc.manualTargSetMstID;
      break;

    default:
      break;
  }

  return mstIDStr;
}

/* Costs */

export function getEduCodeStr(currID) {
  let eduCodeStr = "";

  switch (currID) {
    case pic.eduCodeICurrID:
      eduCodeStr = "I";
      break;

    case pic.eduCodeIICurrID:
      eduCodeStr = "II";
      break;

    case pic.eduCodeIIICurrID:
      eduCodeStr = "III";
      break;

    case pic.eduCodeIVCurrID:
      eduCodeStr = "IV";
      break;

    case pic.eduCodeVCurrID:
      eduCodeStr = "V";
      break;

    default:
      break;
  }

  return eduCodeStr;
}

export function getEduCodeMstID(currIDInt) {
  let mstIDStr = "";

  switch (currIDInt) {
    case pic.eduCodeICurrID:
      mstIDStr = pisc.eduCode1MstID;
      break;

    case pic.eduCodeIICurrID:
      mstIDStr = pisc.eduCode2MstID;
      break;

    case pic.eduCodeIIICurrID:
      mstIDStr = pisc.eduCode3MstID;
      break;

    case pic.eduCodeIVCurrID:
      mstIDStr = pisc.eduCode4MstID;
      break;

    case pic.eduCodeVCurrID:
      mstIDStr = pisc.eduCode5MstID;
      break;

    default:
      break;
  }

  return mstIDStr;
}

export function getEduCodeCurrID(mstIDInt) {
  let currID = 0;

  switch (mstIDInt) {
    case pisc.eduCode1MstID:
      currID = pic.eduCodeICurrID;
      break;

    case pisc.eduCode2MstID:
      currID = pic.eduCodeIICurrID;
      break;

    case pisc.eduCode3MstID:
      currID = pic.eduCodeIIICurrID;
      break;

    case pisc.eduCode4MstID:
      currID = pic.eduCodeIVCurrID;
      break;

    case pisc.eduCode5MstID:
      currID = pic.eduCodeVCurrID;
      break;

    default:
      break;
  }

  return currID;
}

export function getEduCodeArray() {
  let eduCode1DStrArray = [];

  for (let ec = 1; ec <= pic.numEduCodes; ec++) {
    eduCode1DStrArray.push(getEduCodeStr(ec));
  }

  return eduCode1DStrArray;
}

/* Impact scenario */

export function getImpScenName(currID) {
  let optionStr = "";

  switch (currID) {
    case pic.constCovImpOptCurrID:
      optionStr = RS(SC.GB_stConstCov);
      break;

    case pic.ninetyX3ImpOptCurrID:
      optionStr = RS(SC.GB_stNinetyFiveX3);
      break;

    default:
      break;
  }

  return optionStr;
}

export function getImpScenMstID(currID) {
  let mstIDStr = "";

  switch (currID) {
    case pic.constCovImpOptCurrID:
      mstIDStr = pisc.constantCovMstID;
      break;

    case pic.ninetyX3ImpOptCurrID:
      mstIDStr = pisc.ninetyX3CovMstID;
      break;

    default:
      break;
  }

  return mstIDStr;
}

export function getImpScenCurrID(mstIDStr) {
  let currID = "";

  switch (mstIDStr) {
    case pisc.constantCovMstID:
      currID = pic.constCovImpOptCurrID;
      break;

    case pisc.ninetyX3CovMstID:
      currID = pic.ninetyX3ImpOptCurrID;
      break;

    default:
      break;
  }

  return currID;
}

/* Personnel types */

export function getDefPersTypeNameFromMstID(mstIDStr) {
  let name = "";

  switch (mstIDStr) {
    case pisc.counselorMstID:
      name = RS(SC.GB_stCounselor);
      break;

    case pisc.nurseMstID:
      name = RS(SC.GB_stNurse);
      break;

    case pisc.clinOfficerMstID:
      name = RS(SC.GB_stClinicalOfficer);
      break;

    case pisc.pharmTechMstID:
      name = RS(SC.GB_stPharmTech);
      break;

    case pisc.outrWorkerMstID:
      name = RS(SC.GB_stOutreachWorker);
      break;

    case pisc.peerEducatorMstID:
      name = RS(SC.GB_stPeerEducator);
      break;

    default:
      break;
  }

  return name;
}

export function getDefPersTypeMstID(currIDInt) {
  let mstID = "";

  switch (currIDInt) {
    case pic.counselorCurrID:
      mstID = pisc.counselorMstID;
      break;

    case pic.nurseCurrID:
      mstID = pisc.nurseMstID;
      break;

    case pic.clinOfficerCurrID:
      mstID = pisc.clinOfficerMstID;
      break;

    case pic.pharmTechCurrID:
      mstID = pisc.pharmTechMstID;
      break;

    case pic.outrWorkerCurrID:
      mstID = pisc.outrWorkerMstID;
      break;

    case pic.peerEducatorCurrID:
      mstID = pisc.peerEducatorMstID;
      break;

    default:
      break;
  }

  return mstID;
}

export function getDefPersTypeCurrID(mstIDInt) {
  let currID = 0;

  switch (mstIDInt) {
    case pisc.counselorMstID:
      currID = pic.counselorCurrID;
      break;

    case pisc.nurseMstID:
      currID = pic.nurseCurrID;
      break;

    case pisc.clinOfficerMstID:
      currID = pic.clinOfficerCurrID;
      break;

    case pisc.pharmTechMstID:
      currID = pic.pharmTechCurrID;
      break;

    case pisc.outrWorkerMstID:
      currID = pic.outrWorkerCurrID;
      break;

    case pisc.peerEducatorMstID:
      currID = pic.peerEducatorCurrID;
      break;

    default:
      break;
  }

  return currID;
}

/* Service delivery strategies */

export function getDefServDelivStratNameFromMstID(mstIDStr) {
  let name = "";

  switch (mstIDStr) {
    case pisc.strat1SDSMstID:
      name = RS(SC.GB_stStrategy) + " #1";
      break;

    case pisc.strat2SDSMstID:
      name = RS(SC.GB_stStrategy) + " #2";
      break;

    case pisc.strat3SDSMstID:
      name = RS(SC.GB_stStrategy) + " #3";
      break;

    default:
      break;
  }

  return name;
}

export function getDefServDelivStratMstID(currIDInt) {
  let mstID = "";

  switch (currIDInt) {
    case pic.strat1SDSCurrID:
      mstID = pisc.strat1SDSMstID;
      break;

    case pic.strat2SDSCurrID:
      mstID = pisc.strat2SDSMstID;
      break;

    case pic.strat3SDSCurrID:
      mstID = pisc.strat3SDSMstID;
      break;

    default:
      break;
  }

  return mstID;
}

export function getDefServDelivStratCurrID(mstIDInt) {
  let currID = 0;

  switch (mstIDInt) {
    case pisc.strat1SDSMstID:
      currID = pic.strat1SDSCurrID;
      break;

    case pisc.strat2SDSMstID:
      currID = pic.strat2SDSCurrID;
      break;

    case pisc.strat3SDSMstID:
      currID = pic.strat3SDSCurrID;
      break;

    default:
      break;
  }

  return currID;
}

/* Lab tests */

export function getDefLabTestNameFromMstID(mstIDStr) {
  let name = "";

  switch (mstIDStr) {
    case pisc.HIVRapidTestMstID:
      name = RS(SC.GB_stHIVRapidTest);
      break;

    case pisc.ELISATestMstID:
      name = RS(SC.GB_stELISA);
      break;

    case pisc.HBsAgTestMstID:
      name = RS(SC.GB_stHBsAg);
      break;

    case pisc.ALTTestMstID:
      name = RS(SC.GB_stALT);
      break;

    case pisc.pregTestUrineMstID:
      name = RS(SC.GB_stPregTestUrine);
      break;

    case pisc.rapidSyphTestMstID:
      name = RS(SC.GB_stRapidSyphTest);
      break;

    case pisc.RPRTitreTestMstID:
      name = RS(SC.GB_stRPRTitre);
      break;

    case pisc.creatinineTestMstID:
      name = RS(SC.GB_stCreatinine);
      break;

    case pisc.PrEPDrugLevelTestMstID:
      name = RS(SC.GB_stPrEPDrugLevelTest);
      break;

    default:
      break;
  }

  return name;
}

export function getDefLabTestMstID(currIDInt) {
  let mstID = "";

  switch (currIDInt) {
    case pic.HIVRapidTestCurrID:
      mstID = pisc.HIVRapidTestMstID;
      break;

    case pic.ELISATestCurrID:
      mstID = pisc.ELISATestMstID;
      break;

    case pic.HBsAgTestCurrID:
      mstID = pisc.HBsAgTestMstID;
      break;

    case pic.ALTTestCurrID:
      mstID = pisc.ALTTestMstID;
      break;

    case pic.pregTestUrineCurrID:
      mstID = pisc.pregTestUrineMstID;
      break;

    case pic.rapidSyphTestCurrID:
      mstID = pisc.rapidSyphTestMstID;
      break;

    case pic.RPRTitreTestCurrID:
      mstID = pisc.RPRTitreTestMstID;
      break;

    case pic.creatinineTestCurrID:
      mstID = pisc.creatinineTestMstID;
      break;

    case pic.PrEPDrugLevelTestCurrID:
      mstID = pisc.PrEPDrugLevelTestMstID;
      break;

    default:
      break;
  }

  return mstID;
}

export function getDefLabTestCurrID(mstIDInt) {
  let currID = 0;

  switch (mstIDInt) {
    case pisc.HIVRapidTestMstID:
      currID = pic.HIVRapidTestCurrID;
      break;

    case pisc.ELISATestMstID:
      currID = pic.ELISATestCurrID;
      break;

    case pisc.HBsAgTestMstID:
      currID = pic.HBsAgTestCurrID;
      break;

    case pisc.ALTTestMstID:
      currID = pic.ALTTestCurrID;
      break;

    case pisc.pregTestUrineMstID:
      currID = pic.pregTestUrineCurrID;
      break;

    case pisc.rapidSyphTestMstID:
      currID = pic.rapidSyphTestCurrID;
      break;

    case pisc.RPRTitreTestMstID:
      currID = pic.RPRTitreTestCurrID;
      break;

    case pisc.creatinineTestMstID:
      currID = pic.creatinineTestCurrID;
      break;

    case pisc.PrEPDrugLevelTestMstID:
      currID = pic.PrEPDrugLevelTestCurrID;
      break;

    default:
      break;
  }

  return currID;
}

/* Continuation visits */

export function getDefContVisitNameFromMstID(mstIDStr) {
  let name = "";

  switch (mstIDStr) {
    case pisc.initVisitCVMstID:
      name = RS(SC.GB_stInitiationVisit);
      break;

    default:
      break;
  }

  return name;
}

export function getDefContVisitMstID(currIDInt) {
  let mstID = "";

  switch (currIDInt) {
    case pic.initVisitCurrID:
      mstID = pisc.initVisitCVMstID;
      break;

    default:
      break;
  }

  return mstID;
}

export function getDefContVisitCurrID(mstIDStr) {
  let currID = 0;

  switch (mstIDStr) {
    case pisc.initVisitCVMstID:
      currID = pic.initVisitCurrID;
      break;

    default:
      break;
  }

  return currID;
}

export function getDefContVisitDisplay(mstIDStr) {
  let displayBool = true;

  switch (mstIDStr) {
    case pisc.initVisitCVMstID:
      displayBool = false;
      break;

    default:
      break;
  }

  return displayBool;
}

export function getDefContVisitEnabled(mstIDStr) {
  let enabledBool = true;

  switch (mstIDStr) {
    case pisc.initVisitCVMstID:
      enabledBool = false;
      break;

    default:
      break;
  }

  return enabledBool;
}

/* Continuation visit schedules */

export function getDefContVisitSchedNameFromMstID(mstIDStr) {
  let name = "";

  switch (mstIDStr) {
    case pisc.contVisitSched1MstID:
      name = RS(SC.GB_stSchedule) + " 1";
      break;

    case pisc.contVisitSched2MstID:
      name = RS(SC.GB_stSchedule) + " 2";
      break;

    case pisc.contVisitSched3MstID:
      name = RS(SC.GB_stSchedule) + " 3";
      break;

    case pisc.contVisitSched4MstID:
      name = RS(SC.GB_stSchedule) + " 4";
      break;

    default:
      break;
  }

  return name;
}

export function getDefContVisitSchedMstID(currIDInt) {
  let mstID = "";

  switch (currIDInt) {
    case pic.contVisitSched1CurrID:
      mstID = pisc.contVisitSched1MstID;
      break;

    case pic.contVisitSched2CurrID:
      mstID = pisc.contVisitSched2MstID;
      break;

    case pic.contVisitSched3CurrID:
      mstID = pisc.contVisitSched3MstID;
      break;

    case pic.contVisitSched4CurrID:
      mstID = pisc.contVisitSched4MstID;
      break;

    default:
      break;
  }

  return mstID;
}

export function getDefContVisitSchedCurrID(mstIDInt) {
  let currID = 0;

  switch (mstIDInt) {
    case pisc.contVisitSched1MstID:
      currID = pic.contVisitSched1CurrID;
      break;

    case pisc.contVisitSched2MstID:
      currID = pic.contVisitSched2CurrID;
      break;

    case pisc.contVisitSched3MstID:
      currID = pic.contVisitSched3CurrID;
      break;

    case pisc.contVisitSched4MstID:
      currID = pic.contVisitSched4CurrID;
      break;

    default:
      break;
  }

  return currID;
}

/* Continuation visit options */

export function getContVisitOptMstID(currIDInt) {
  let mstID = "";

  switch (currIDInt) {
    case pic.yesCVOCurrID:
      mstID = pisc.yesCVOMstID;
      break;

    case pic.noCVOCurrID:
      mstID = pisc.noCVOMstID;
      break;

    case pic.dashCVOCurrID:
      mstID = pisc.dashCVOMstID;
      break;

    default:
      break;
  }

  return mstID;
}

export function getContVisitOptCurrID(mstIDStr) {
  let currIDInt = "";

  switch (mstIDStr) {
    case pisc.yesCVOMstID:
      currIDInt = pic.yesCVOCurrID;
      break;

    case pisc.noCVOMstID:
      currIDInt = pic.noCVOCurrID;
      break;

    case pisc.dashCVOMstID:
      currIDInt = pic.dashCVOCurrID;
      break;

    default:
      break;
  }

  return currIDInt;
}

export function getContVisitOption(mstIDStr) {
  let name = "";

  switch (mstIDStr) {
    case pisc.yesCVOMstID:
      name = RS(SC.GB_stYes);
      break;

    case pisc.noCVOMstID:
      name = RS(SC.GB_stNo);
      break;

    case pisc.dashCVOMstID:
      name = "-";
      break;

    default:
      break;
  }

  return name;
}

export function getContVisitOptionArray() {
  let contVisitOpt1DStrArray = [];

  for (let cvo = 1; cvo <= pic.numContVisitOptions; cvo++) {
    contVisitOpt1DStrArray.push(getContVisitOptMstID(cvo));
  }

  return contVisitOpt1DStrArray;
}

/* Age groups for Priority populations included by age/sex. */

export function getAgeGroupPPIASMstID(currIDInt) {
  let mstID = "";

  switch (currIDInt) {
    case pic.age15t19PPIASCurrID:
      mstID = pisc.age15t19PPIASMstID;
      break;

    case pic.age20t24PPIASCurrID:
      mstID = pisc.age20t24PPIASMstID;
      break;

    case pic.age25t29PPIASCurrID:
      mstID = pisc.age25t29PPIASMstID;
      break;

    case pic.age30t34PPIASCurrID:
      mstID = pisc.age30t34PPIASMstID;
      break;

    case pic.age35t39PPIASCurrID:
      mstID = pisc.age35t39PPIASMstID;
      break;

    case pic.age40t44PPIASCurrID:
      mstID = pisc.age40t44PPIASMstID;
      break;

    case pic.age45t49PPIASCurrID:
      mstID = pisc.age45t49PPIASMstID;
      break;

    case pic.age50t54PPIASCurrID:
      mstID = pisc.age50t54PPIASMstID;
      break;

    case pic.age55t59PPIASCurrID:
      mstID = pisc.age55t59PPIASMstID;
      break;

    case pic.age60t64PPIASCurrID:
      mstID = pisc.age60t64PPIASMstID;
      break;

    case pic.age65PlusPPIASCurrID:
      mstID = pisc.age65PlusPPIASMstID;
      break;

    default:
      break;
  }

  return mstID;
}

export function getAgeGroupPPIASCurrID(mstIDStr) {
  let currIDInt = "";

  switch (mstIDStr) {
    case pisc.age15t19PPIASMstID:
      currIDInt = pic.age15t19PPIASCurrID;
      break;

    case pisc.age20t24PPIASMstID:
      currIDInt = pic.age20t24PPIASCurrID;
      break;

    case pisc.age25t29PPIASMstID:
      currIDInt = pic.age25t29PPIASCurrID;
      break;

    case pisc.age30t34PPIASMstID:
      currIDInt = pic.age30t34PPIASCurrID;
      break;

    case pisc.age35t39PPIASMstID:
      currIDInt = pic.age35t39PPIASCurrID;
      break;

    case pisc.age40t44PPIASMstID:
      currIDInt = pic.age40t44PPIASCurrID;
      break;

    case pisc.age45t49PPIASMstID:
      currIDInt = pic.age45t49PPIASCurrID;
      break;

    case pisc.age50t54PPIASMstID:
      currIDInt = pic.age50t54PPIASCurrID;
      break;

    case pisc.age55t59PPIASMstID:
      currIDInt = pic.age55t59PPIASCurrID;
      break;

    case pisc.age60t64PPIASMstID:
      currIDInt = pic.age60t64PPIASCurrID;
      break;

    case pisc.age65PlusPPIASMstID:
      currIDInt = pic.age65PlusPPIASCurrID;
      break;

    default:
      break;
  }

  return currIDInt;
}

export function getAgeGroupStartPPIAS(mstIDStr) {
  let name = "";

  switch (mstIDStr) {
    case pisc.age15t19PPIASMstID:
      name = "15";
      break;

    case pisc.age20t24PPIASMstID:
      name = "20";
      break;

    case pisc.age25t29PPIASMstID:
      name = "25";
      break;

    case pisc.age30t34PPIASMstID:
      name = "30";
      break;

    case pisc.age35t39PPIASMstID:
      name = "35";
      break;

    case pisc.age40t44PPIASMstID:
      name = "40";
      break;

    case pisc.age45t49PPIASMstID:
      name = "45";
      break;

    case pisc.age50t54PPIASMstID:
      name = "50";
      break;

    case pisc.age55t59PPIASMstID:
      name = "55";
      break;

    case pisc.age60t64PPIASMstID:
      name = "60";
      break;

    case pisc.age65PlusPPIASMstID:
      name = "65+";
      break;

    default:
      break;
  }

  return name;
}

export function getAgeGroupEndPPIAS(mstIDStr) {
  let name = "";

  switch (mstIDStr) {
    case pisc.age15t19PPIASMstID:
      name = "19";
      break;

    case pisc.age20t24PPIASMstID:
      name = "24";
      break;

    case pisc.age25t29PPIASMstID:
      name = "29";
      break;

    case pisc.age30t34PPIASMstID:
      name = "34";
      break;

    case pisc.age35t39PPIASMstID:
      name = "39";
      break;

    case pisc.age40t44PPIASMstID:
      name = "44";
      break;

    case pisc.age45t49PPIASMstID:
      name = "49";
      break;

    case pisc.age50t54PPIASMstID:
      name = "54";
      break;

    case pisc.age55t59PPIASMstID:
      name = "59";
      break;

    case pisc.age60t64PPIASMstID:
      name = "64";
      break;

    case pisc.age65PlusPPIASMstID:
      name = "65+";
      break;

    default:
      break;
  }

  return name;
}

export function getAgeGroupPPIASArray() {
  let ageGroup1DStrArray = [];

  for (let a = 1; a <= pic.numAgeGroupsPPIAS; a++) {
    ageGroup1DStrArray.push(getAgeGroupPPIASMstID(a));
  }

  return ageGroup1DStrArray;
}

/* Default minute patterns */

export function getDefMinPatNameFromMstID(mstIDStr) {
  let name = "";

  switch (mstIDStr) {
    case pisc.SDCsMPMstID:
      name = RS(SC.GB_stSDCs);
      break;

    case pisc.keyPopsMPMstID:
      name = RS(SC.GB_stKeyPopCC);
      break;

    case pisc.AGYWMPMstID:
      name = RS(SC.GB_stAGYW15t24);
      break;

    case pisc.genPopMPMstID:
      name = RS(SC.GB_stGeneralPopulation);
      break;

    case pisc.dropInCenterMPMstID:
      name = RS(SC.GB_stDropInCenters);
      break;

    case pisc.pubHlthCenterMPMstID:
      name = RS(SC.GB_stPublicHealthCenters);
      break;

    case pisc.pubHospMPMstID:
      name = RS(SC.GB_stPublicHospitals);
      break;

    case pisc.privClinicMPMstID:
      name = RS(SC.GB_stPrivateClinics);
      break;

    default:
      break;
  }

  return name;
}

export function getDefMinPatArray() {
  let minPat1DStrArray = [];

  for (let mp = 1; mp <= pic.numMinPatterns; mp++) {
    const minPatMstID = getDefMinPatMstID(mp);
    minPat1DStrArray.push(getDefMinPatNameFromMstID(minPatMstID));
  }

  return minPat1DStrArray;
}

export function getDefMinPatMstID(currIDInt) {
  let mstID = 0;

  switch (currIDInt) {
    case pic.SDC_PPCurrID:
      mstID = pisc.SDCsMPMstID;
      break;

    case pic.keyPopsMPCurrID:
      mstID = pisc.keyPopsMPMstID;
      break;

    case pic.AGYWMPCurrID:
      mstID = pisc.AGYWMPMstID;
      break;

    case pic.genPopMPCurrID:
      mstID = pisc.genPopMPMstID;
      break;

    case pic.dropInCenterMPCurrID:
      mstID = pisc.dropInCenterMPMstID;
      break;

    case pic.pubHlthCenterMPCurrID:
      mstID = pisc.pubHlthCenterMPMstID;
      break;

    case pic.pubHospMPCurrID:
      mstID = pisc.pubHospMPMstID;
      break;

    case pic.privClinicMPCurrID:
      mstID = pisc.privClinicMPMstID;
      break;

    default:
      break;
  }

  return mstID;
}

export function getDefMinPatCurrID(mstIDInt) {
  let currID = 0;

  switch (mstIDInt) {
    case pisc.SDCsMPMstID:
      currID = pic.SDCsMPCurrID;
      break;

    case pisc.keyPopsMPMstID:
      currID = pic.keyPopsMPCurrID;
      break;

    case pisc.AGYWMPMstID:
      currID = pic.AGYWMPCurrID;
      break;

    case pisc.genPopMPMstID:
      currID = pic.genPopMPCurrID;
      break;

    case pisc.dropInCenterMPMstID:
      currID = pic.dropInCenterMPCurrID;
      break;

    case pisc.pubHlthCenterMPMstID:
      currID = pic.pubHlthCenterMPCurrID;
      break;

    case pisc.pubHospMPMstID:
      currID = pic.pubHospMPCurrID;
      break;

    case pisc.privClinicMPMstID:
      currID = pic.privClinicMPCurrID;
      break;

    default:
      break;
  }

  return currID;
}

export function getThreshCritMstID(currIDInt) {
  let mstID = "";

  switch (currIDInt) {
    case pic.totalCostSavingsAGYWCurrID:
      mstID = pisc.totalCostSavingsAGYWMstID;
      break;

    case pic.PrEPCostInfAvtdAGYWCurrID:
      mstID = pisc.PrEPCostInfAvtdAGYWMstID;
      break;

    default:
      break;
  }

  return mstID;
}

export function getThreshCritCurrID(mstIDStr) {
  let currID = 0;

  switch (mstIDStr) {
    case pisc.totalCostSavingsAGYWMstID:
      currID = pic.totalCostSavingsAGYWCurrID;
      break;

    case pisc.PrEPCostInfAvtdAGYWMstID:
      currID = pic.PrEPCostInfAvtdAGYWCurrID;
      break;

    default:
      break;
  }

  return currID;
}

export function itemVariesByMethod(itemTypeByte) {
  let itemVariesByMethodBool = false;

  switch (itemTypeByte) {
    case pic.servDelivStratItems:
    case pic.minutePatternItems:
    case pic.labTestItems:
      break;

    case pic.persTypeItems:
      itemVariesByMethodBool = true;
      break;

    default:
      break;
  }

  return itemVariesByMethodBool;
}

export function getItemObjMethodMstIDField(itemTypeByte) {
  let fieldNameStr = false;

  switch (itemTypeByte) {
    case pic.contVisitSchedItems:
      fieldNameStr = pisc.contVisitSchedMethodMstID;
      break;

    case pic.servDelivStratItems:
      fieldNameStr = pisc.servDelivStratMethodMstID;
      break;

    case pic.minutePatternItems:
      fieldNameStr = pisc.minPatMethodMstID;
      break;

    case pic.labTestItems:
      fieldNameStr = pisc.labTestMethodMstID;
      break;

    case pic.contVisitItems:
      fieldNameStr = pisc.contVisitMethodMstID;
      break;

    case pic.persTypeItems:
      fieldNameStr = pisc.persTypeMethodMstID;
      break;

    default:
      break;
  }

  return fieldNameStr;
}

export function getItemObjMstIDField(itemTypeByte) {
  let fieldNameStr = false;

  switch (itemTypeByte) {
    case pic.contVisitSchedItems:
      fieldNameStr = pisc.contVisitSchedMstID;
      break;

    case pic.servDelivStratItems:
      fieldNameStr = pisc.servDelivStratMstID;
      break;

    case pic.minutePatternItems:
      fieldNameStr = pisc.minPatMstID;
      break;

    case pic.labTestItems:
      fieldNameStr = pisc.labTestMstID;
      break;

    case pic.contVisitItems:
      fieldNameStr = pisc.contVisitMstID;
      break;

    case pic.persTypeItems:
      fieldNameStr = pisc.persTypeMstID;
      break;

    default:
      break;
  }

  return fieldNameStr;
}

export function usingCostsLite(costingModeMstID) {
  return costingModeMstID === pisc.costsLiteModeMstID;
}

export function getCostingModeStr(costingModeMstID) {
  let name = "";

  switch (costingModeMstID) {
    case pisc.detailedCostModeMstID:
      name = RS(SC.GB_stDetailedCosts);
      break;

    case pisc.costsLiteModeMstID:
      name = RS(SC.GB_stCostsLite);
      break;

    case pisc.noCostingModeMstID:
      name = RS(SC.GB_stNone);
      break;

    default:
      break;
  }

  return name;
}

export function getCostingModeSchedMVTag(costingModeMstID) {
  let modVarTag;
  if (usingCostsLite(costingModeMstID)) {
    modVarTag = pisc.contVisitSchedLiteMVTag;
  } else {
    modVarTag = pisc.contVisitSchedMVTag;
  }

  return modVarTag;
}

export function resourceClick(resourceStr) {
  window.open("./resources/" + resourceStr, "_blank", "noopener,noreferrer");
}

export function URL_Click(URL_Str, openTarget = "_blank") {
  window.open(URL_Str, openTarget, "noopener,noreferrer");
}

export function serverCallSuccessful(serverResponse) {
  return typeof serverResponse[pisc.exceptionMsg] === "undefined";
}

export function notifyUserOfError(serverResponse, onDialogChange) {
  console.log(serverResponse);

  let dialogObj = pias.getDefaultDialogObj();
  dialogObj[pias.contentStr] = "The following error occurred on the server: " + serverResponse[pisc.exceptionMsg];
  dialogObj[pias.headerStr] = RS(SC.GB_stError);
  dialogObj[pias.maxWidthStr] = "sm";
  dialogObj[pias.showBool] = true;
  dialogObj[pias.styleObj] = { width: 500 };

  onDialogChange(dialogObj);
}

export function getAGYWAreaTypeMstID(currIDInt) {
  let mstIDStr = "";

  switch (currIDInt) {
    case pic.nationalArea:
      mstIDStr = pisc.AGYW_NationalAreaMstID;
      break;

    case pic.regionalArea:
      mstIDStr = pisc.AGYW_RegionalAreaMstID;
      break;

    case pic.districtArea:
      mstIDStr = pisc.AGYW_DistrictAreaMstID;
      break;

    default:
  }

  return mstIDStr;
}

export function getAGYWAreaTypeCurrID(mstIDStr) {
  let currIDInt = pic.noAreaSelected;

  switch (mstIDStr) {
    case pisc.AGYW_NationalAreaMstID:
      currIDInt = pic.nationalArea;
      break;

    case pisc.AGYW_RegionalAreaMstID:
      currIDInt = pic.regionalArea;
      break;

    case pisc.AGYW_DistrictAreaMstID:
      currIDInt = pic.districtArea;
      break;

    default:
  }

  return currIDInt;
}

export function getAGYWCostPerPYPrEPSrcMstID(currIDInt) {
  let mstIDStr = "";

  switch (currIDInt) {
    case pic.AGYW_PerPYPrEPFromCostingSrcCurrID:
      mstIDStr = pisc.AGYW_PerPYPrEPFromCostingSrcMstID;
      break;

    case pic.AGYW_EnterPerPYPrEPSrcCurrID:
      mstIDStr = pisc.AGYW_EnterPerPYPrEPSrcMstID;
      break;

    default:
  }

  return mstIDStr;
}

export function getAGYWCostPerPYPrEPSrcCurrID(mstIDStr) {
  let currIDInt = pic.noMstID;

  switch (mstIDStr) {
    case pisc.AGYW_PerPYPrEPFromCostingSrcMstID:
      currIDInt = pic.AGYW_PerPYPrEPFromCostingSrcCurrID;
      break;

    case pisc.AGYW_EnterPerPYPrEPSrcMstID:
      currIDInt = pic.AGYW_EnterPerPYPrEPSrcCurrID;
      break;

    default:
  }

  return currIDInt;
}

export function getAGYWCostPerPYARTEntryTypeMstID(currIDInt) {
  let mstIDStr = "";

  switch (currIDInt) {
    case pic.AGYW_SingleCostPerPerPYARTCurrID:
      mstIDStr = pisc.AGYW_SingleCostPerPerPYARTMstID;
      break;

    case pic.AGYW_CostPerPerPYByAreaARTCurrID:
      mstIDStr = pisc.AGYW_CostPerPerPYByAreaARTMstID;
      break;

    default:
  }

  return mstIDStr;
}

export function getAGYWCostPerPYARTEntryTypeCurrID(mstIDStr) {
  let currIDInt = pic.noMstID;

  switch (mstIDStr) {
    case pisc.AGYW_SingleCostPerPerPYARTMstID:
      currIDInt = pic.AGYW_SingleCostPerPerPYARTCurrID;
      break;

    case pisc.AGYW_CostPerPerPYByAreaARTMstID:
      currIDInt = pic.AGYW_CostPerPerPYByAreaARTCurrID;
      break;

    default:
  }

  return currIDInt;
}

/*********************************************************************
 *
 *    Warnings
 *
 ********************************************************************/

export function getWarning(warningMstIDStr) {
  let warningStr = "";

  switch (warningMstIDStr) {
    case pisc.targetStartDateWarning:
      warningStr = RS(SC.GB_stTargetStartDateWarning);
      break;

    default:
      break;
  }

  return warningStr;
}
