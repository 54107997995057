import * as PropTypes from "prop-types";

import { useState } from "react";

import PIDataByMethodPopMonthGraph from "./PIDataByMethodPopMonthGraph";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";
import TComboBox from "../../common/TComboBox";
import TLabel from "../../common/TLabel";
import TCheckBox from "../../common/TCheckBox";

import * as piasu from "../NonComponents/PIAppStateUtil";

const PITargetsOnPrEPGraph = (props) => {
  const modVarObjList = props.modVarObjList;

  /** @type {number[][][]} */
  const inits = piasu.getModVarValue(modVarObjList, "PI_Initiations");
  /** @type {number[][][]} */
  const restarts = piasu.getModVarValue(modVarObjList, "PI_Restarts");
  /** @type {number[][][]} */
  const currOnPrEP = piasu.getModVarValue(modVarObjList, "PI_CurrOnPrEP");
  /** @type {number[][][]} */
  // const coverage = piasu.getModVarValue(modVarObjList, "PI_Coverage");

  /** @type {string[]} */
  const popNames = piasu.getModVarValue(modVarObjList, "PI_PriorityPop").map((v) => v.name);

  /** @type {number[]} */
  const potentialUsers = piasu.getModVarValue(modVarObjList, "PI_PotentialUsers");
  const totalPotUsers = potentialUsers.reduce((acc, cur) => acc + cur, 0);

  const [selectedPop, setSelectedPop] = useState(0);
  const [showProgData, setShowProgData] = useState(false);

  /**
   * Population change callback
   * @param {number} value Selected item index
   * @param {string} text Item text
   * @param {any} info User data
   */
  const onPopChange = (value, text, info) => {
    setSelectedPop(value);
  };

  /**
   * Show program data flag callback
   * @param {boolean} value Show program data flag
   */
  const onShowProgDataChange = (value) => {
    setShowProgData(value);
  };

  // Matt said to disable show indicators for population for now, when he wants it enabled again either set this to true or remove the conditional.
  const enableShowIndicatorsForPop = false;

  return (
    <div>
      <div style={{ marginBottom: Theme.contentMarginTop }}>
        <div style={{ ...Theme.labelStyle, fontSize: 24 }}>{RS(SC.GB_stTargetsByMethod)}</div>
        {enableShowIndicatorsForPop && (
          <div style={{ display: "flex", alignItems: "baseline", gap: "1rem" }}>
            <TLabel
              caption={`${RS(SC.GB_stIncludeIndicatorsForPop)}:`}
              key={"selectPopLabel"}
              style={Theme.labelStyle}
            />
            <TComboBox
              key={"selectPop"}
              onChange={onPopChange}
              items={[RS(SC.GB_stNone), ...popNames]}
              itemIndex={selectedPop}
              outerStyle={{
                marginTop: Theme.ctrlSpacing,
                fontFamily: Theme.fontFamily,
                width: 150,
              }}
            />
          </div>
        )}

        <TCheckBox
          caption={RS(SC.GB_stShowProgDataPeriod)}
          onClick={onShowProgDataChange}
          enabled={true}
          key={"showProgDataPeriod"}
          style={{
            color: Theme.PI_SecondaryColor,
            height: "auto",
            marginBottom: 10,
            marginLeft: 0,
            marginTop: Theme.ctrlSpacing,
          }}
          value={showProgData}
        />
      </div>

      <PIDataByMethodPopMonthGraph
        modVarObjList={modVarObjList}
        id={"byMethodChart"}
        series={[
          {
            name: RS(SC.GB_stPrEPInitiations),
            data: inits,
            byMethod: true,
          },
          {
            name: RS(SC.GB_stRestarts),
            data: restarts,
            byMethod: true,
          },
          {
            name: RS(SC.GB_stCurrentlyOnPrEP),
            data: currOnPrEP,
            byMethod: true,
          },
          selectedPop && {
            name: `${popNames[selectedPop - 1]} - ${RS(SC.GB_stPrEPInitiations)}`,
            data: inits,
            byPop: true,
            population: selectedPop - 1,
          },
          selectedPop && {
            name: `${popNames[selectedPop - 1]} - ${RS(SC.GB_stRestarts)}`,
            data: restarts,
            byPop: true,
            population: selectedPop - 1,
          },
          selectedPop && {
            name: `${popNames[selectedPop - 1]} - ${RS(SC.GB_stCurrentlyOnPrEP)}`,
            data: currOnPrEP,
            byPop: true,
            population: selectedPop - 1,
          },
        ]}
        title={RS(SC.GB_stInitOnPrEPByMonth)}
        showPDP={showProgData}
      />
      <PIDataByMethodPopMonthGraph
        modVarObjList={modVarObjList}
        id={"coverageChart"}
        series={[
          {
            name: RS(SC.GB_stCoverageByMethod),
            data: currOnPrEP,
            byMethod: true,
            divisor: totalPotUsers,
            multiplier: 100,
          },
        ]}
        title={RS(SC.GB_stCoverageByMethod)}
        showPDP={showProgData}
      />
    </div>
  );
};

PITargetsOnPrEPGraph.propTypes = {
  /** Mod vars */
  modVarObjList: PropTypes.array.isRequired,
};

export default PITargetsOnPrEPGraph;
