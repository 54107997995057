import * as React from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import TSlideDrawer from "../../common/TSlideDrawer";

import * as gbu from "../../GB/GBUtil";

import PIProgDataForm from "../Forms/PIProgDataForm";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as php from "../NonComponents/PIHelp";
import * as pip from "../NonComponents/PIProps";
//import * as pieh from "../NonComponents/PIEventHandlers";
import THelpButton from "../../common/THelpButton";

/* PIInitiationSlideDrawer state */
const applyBtnEnabledC = "applyBtnEnabled";

class PIProgDataSlideDrawer extends React.Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    //[pip.onContSlideDrawerChange]        : PropTypes.func,

    //[pias.onDialogChange]                : PropTypes.func,

    [pias.onDrawerChange]: PropTypes.func,

    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelpAreaChange]: PropTypes.func,
    [pias.onHelp]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    //[pias.onPageChange]                  : PropTypes.func,

    [pip.onNextAreaChange]: PropTypes.func,
    [pip.onPrevAreaChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    //[pias.onDialogChange]               : () => console.log(pip.onDialogChange),

    [pias.onDrawerChange]: () => console.log(pias.onDrawerChange),

    [pias.helpAreaStr]: php.config_ProgDataSD_HP,
    [pias.onHelpAreaChange]: () => console.log(pias.onHelpAreaChange),
    [pias.onHelp]: () => console.log(pias.onHelp),

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    //[pias.onPageChange]                 : () => console.log(pias.onPageChange),

    [pip.onNextAreaChange]: () => console.log(pip.onNextAreaChange),
    [pip.onPrevAreaChange]: () => console.log(pip.onPrevAreaChange),
  };

  /* Since the data is all saved by clicking Apply in the slide drawer component instead of on the form
       component, and the slide drawer component exists in this component, we have to save intermediary state for any
       state the user can change in this drawer component and pass intermediate state and onChange events to the form component.
       Changes in state will then be passed back up to this component via callback functions for each piece of state
       the user can change. */
  constructor(props) {
    super(props);

    /* _D = Drawer */
    this.state = {
      /* Passed down to further components and passed back up later via callbacks. */
      [pias.modVarObjList]: gbu.cloneObj(props[pias.modVarObjList]),

      /* Not passed down to further components. */
      [applyBtnEnabledC]: true, //false, Matt change
    };
  }

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                                 Methods
  //
  //==================================================================================================================

  /**
   * Returns array of any populations added since template was uploaded
   * @returns {Array<string>}
   */
  getPopulationsWithoutProgramData = (modVarObjArr) => {
    const uploadPriPops = piasu.getModVarValue(modVarObjArr, pisc.priorPopsUploadMVTag);
    const currentPriPops = piasu.getModVarValue(modVarObjArr, pisc.priorPopsMVTag);

    const popsMissingData = []
    for (let pop of currentPriPops)
      if (uploadPriPops.find(v => v.mstID === pop.mstID) === undefined)
        popsMissingData.push(pop.name)

    return popsMissingData
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onHelpBtnClick = () => {
    this.props.onHelp();
  };

  /**
   * @returns {boolean} - Return false to cancel close of drawer
   */
  onSlideDrawerSaveBtnClick = async () => {
    const editorValid = true;

    if (!editorValid) return false;

    const saveAndClose = () => {
      /* Pass true for recalc in case user changed the program data time period dropdowns. This will
          make the server pass back certain data structures with the updated time periods (such as the
          initiation object for program data). */
      this.props.onModVarsChange(this.state.modVarObjList, true, () => {
        this.setState(
          {
            [applyBtnEnabledC]: false,
          },
          () => {
            this.props.onHelpAreaChange(php.configFM_HP, () => {
              this.props.onDrawerChange();
            });
          }
        );
      });
    }

    const pops = this.getPopulationsWithoutProgramData(this.state.modVarObjList)
    if (pops.length > 0) {
      const modalResult = await new Promise((resolve, _reject) => {
        let dialogObj = pias.getDefaultDialogObj();
        dialogObj[pias.actions1DStrArr] = ["mrOK", "mrCancel"]
        dialogObj[pias.contentStr] = (
          <div>
            <p>{RS("GB_stProgDataNotUploadedForPopulations")}</p>
            <ul>
              {pops.map((popName) => <li>{popName}</li>)}
            </ul>
          </div>
        )
        dialogObj[pias.headerStr] = RS(SC.GB_stWarning);
        dialogObj[pias.maxWidthStr] = "sm";
        dialogObj[pias.showBool] = true;
        dialogObj[pias.styleObj] = { width: 500 };
        dialogObj[pias.onCloseEvent] = (modalResult) => {resolve(modalResult)}

        this.props.onDialogChange(dialogObj);
      })

      if (modalResult === "mrCancel")
        return false
    }

    // Timeout to allow the drawer to smoothly close before updating state to remove it
    setTimeout(() => saveAndClose(), 0)

    return true;
  };

  /* Put in recalc param to match param list in PIApp's onModVarsChange */
  onModVarsChange = (modVarObjList, recalc, successFn) => {
    this.setState(
      {
        [pias.modVarObjList]: modVarObjList,
        [applyBtnEnabledC]: true,
      },
      () => gbu.safeCallFn(successFn)
    );
  };

  //==================================================================================================================
  //
  //                                              Render
  //
  //==================================================================================================================

  render() {
    /* props */

    const props = this.props;
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onDialogChange = props[pias.onDialogChange];
    //const onContSlideDrawerChange = props[pip.onContSlideDrawerChange];
    //const onDrawerChange = props[pias.onDrawerChange];
    //const onPageChange = props[pias.onPageChange];
    const onNextAreaChange = props[pip.onNextAreaChange];
    const onPrevAreaChange = props[pip.onPrevAreaChange];
    const origModVarObjArr = props[pias.origModVarObjArr];

    /* state */

    const state = this.state;
    const applyBtnEnabled = state[applyBtnEnabledC];
    const modVarObjList = state[pias.modVarObjList];

    const onModVarsChange = this[pias.onModVarsChange];

    const PIProgDataFormComp = (
      <PIProgDataForm
        {...{
          //[pip.onContSlideDrawerChange]      : onContSlideDrawerChange,

          [pias.onCalculatingChange]: onCalculatingChange,

          [pias.modVarObjList]: modVarObjList,
          [pias.onModVarsChange]: onModVarsChange,
          [pias.origModVarObjArr]: origModVarObjArr,

          [pias.onDialogChange]: onDialogChange,

          //[pias.onPageChange]                : onPageChange,

          [pip.onSlideDrawerSaveBtnClick]: this[pip.onSlideDrawerSaveBtnClick],

          [pip.onNextAreaChange]: onNextAreaChange,
          [pip.onPrevAreaChange]: onPrevAreaChange,
        }}
      />
    );

    const slideDrawer = (
      <TSlideDrawer
        anchor={"right"}
        onClose={this.onSlideDrawerSaveBtnClick}
        content={PIProgDataFormComp}
        headerTitleStyle={{
          color: Theme.PI_PrimaryColor,
        }}
        saveButton={false} // to match continuation drawer
        saveBtnEnabled={applyBtnEnabled}
        title={RS(SC.GB_stProgramData)}
        helpBtn={<THelpButton Theme={Theme} onClick={this.onHelpBtnClick} />}
      />
    );

    return slideDrawer;
  }
}

export default PIProgDataSlideDrawer;
