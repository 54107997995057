import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  component: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "& > *": {
      marginTop: "0 !important",
    },
  },
});

const DrawerButtonGroup = (props) => {
  const classes = useStyles();

  return <div className={classes.component}>{props.children}</div>;
};

DrawerButtonGroup.propTypes = {
  children: PropTypes.node,
};

export default DrawerButtonGroup;
