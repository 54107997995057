import * as GBConst from "./GBConst";

export const getPyramidOptions = (title, packTable) => {
  let maleColStart = 1;
  let femaleColStart = 2;
  let startCol = 0;
  let maxY = 0;
  let mySeries = [
    {
      name: "Male",
      pointWidth: 7,
      data: [],
      totalPop: 0,
    },
    {
      name: "Female",
      pointWidth: 7,
      data: [],
      totalPop: 0,
    },
  ];

  const getTotalPopulation = (s) => {
    let sum = 0;

    switch (s) {
      case 1:
        startCol = maleColStart;
        break;
      case 2:
        startCol = femaleColStart;
        break;
      default:
        startCol = 0;
    }

    for (let a = 1; a <= 17; a++) {
      sum = sum + packTable.tableData.value[a][startCol];
    }

    return sum;
  };

  const getAgeGroupPopulation = (s, totalPop) => {
    let arr = [];
    let sum = 0;
    for (let a = 1; a <= 17; a++) {
      switch (s) {
        case 1:
          startCol = maleColStart;
          break;
        case 2:
          startCol = femaleColStart;
          break;
        default:
          startCol = 0;
      }

      sum = sum + packTable.tableData.value[a][startCol];

      let population = sum;
      let percentage = (population / totalPop) * 100;

      // totalPop = Math.round(totalPop);
      // population = Math.round(sum);
      // percentage = (population / totalPop) * 100;

      arr.push({
        y: percentage,
        value: percentage,
        population: population,
      });

      sum = 0;
    }

    return arr;
  };

  const getData = () => {
    mySeries = [
      {
        name: "Male",
        pointWidth: 7,
        data: [],
        totalPop: 0,
      },
      {
        name: "Female",
        pointWidth: 7,
        data: [],
        totalPop: 0,
      },
    ];

    /* Get Total Population by 5-Year AgeGroup and Sex */
    mySeries[0].totalPop = getTotalPopulation(1);
    mySeries[1].totalPop = getTotalPopulation(2);

    /* Set Total Population and Percentage by AgeGroups */
    mySeries[0].data = getAgeGroupPopulation(1, mySeries[0].totalPop);
    mySeries[1].data = getAgeGroupPopulation(2, mySeries[1].totalPop);

    // Find the max y axis value.  For pyramids, its nice to have both sides have the same max value
    for (let i = 0; i < mySeries[0].data.length; i++) {
      if (mySeries[0].data[i].value > maxY) {
        maxY = mySeries[0].data[i].value;
      }
    }
    for (let i = 0; i < mySeries[1].data.length; i++) {
      if (mySeries[1].data[i].value > maxY) {
        maxY = mySeries[1].data[i].value;
      }
    }

    return mySeries;
  };

  if (typeof packTable !== "undefined") {
    mySeries = getData();
  }

  let options = {
    title: {
      text: title,
      style: {
        fontSize: "12px",
      },
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: [
        "0-4",
        "5-9",
        "10-14",
        "15-19",
        "20-24",
        "25-29",
        "30-34",
        "35-39",
        "40-44",
        "45-49",
        "50-54",
        "55-59",
        "60-64",
        "65-69",
        "70-74",
        "75-79",
        "80+",
      ],
    },
    yAxis: {
      min: -maxY,
      max: maxY,
    },
    series: mySeries,
  };

  return options;
};

export const getChartOptions = (title, packTable, chartType, row, col, lastRow, lastCol, reverse, stacked) => {
  let data;

  if (typeof packTable === "undefined") {
    packTable = {
      GBRowCount: 3,
      GBColCount: 3,
      tableData: {
        value: [
          ["", "", ""],
          ["", 1, 2],
          ["", 3, 4],
        ],
      },
    };

    row = 1;
    col = 1;
    lastRow = 2;
    lastCol = 2;
  }

  data = packTable["tableData"].value;

  //let calcYear = DataStore.GBData.CalcYear[proj];
  //let finalYear = DataStore.GBData.FinalYear[proj];

  if (lastRow === null) {
    lastRow = packTable["GBRowCount"] - 1;
  }

  if (lastCol === null) {
    lastCol = packTable["GBColCount"] - 1;
  }

  //----------------------------------------------------------------------------
  //                              Validation
  //----------------------------------------------------------------------------

  let dataLastRow = data.length;
  let dataLastCol = data[0].length;

  if (dataLastRow <= lastRow) {
    console.error("function getChartOptions says dataLastRow <= lastRow");
    return;
  }

  if (dataLastCol <= lastCol) {
    console.error("function getChartOptions says dataLastCol <= lastCol");
    return;
  }

  //----------------------------------------------------------------------------

  let r;
  let c;

  let categories = [];
  let mySeries = [];

  if (reverse) {
    for (r = row; r <= lastRow; r++) {
      categories.push(data[r][col - 1]);
    }

    for (c = col; c <= lastCol; c++) {
      mySeries.push({ data: [], value: [], name: data[row - 1][c] });
    }

    let x = 0;
    for (c = col; c <= lastCol; c++) {
      for (r = row; r <= lastRow; r++) {
        mySeries[x].data.push(data[r][c]);
        mySeries[x].value.push(data[r][c]);
      }
      x++;
    }
  } else {
    for (c = col; c <= lastCol; c++) {
      categories.push(data[row - 1][c]);
    }

    for (r = row; r <= lastRow; r++) {
      try {
        mySeries.push({ data: [], value: [], name: data[r][col - 1] });
      } catch (e) {
        debugger;
      }
    }

    let x = 0;
    for (r = row; r <= lastRow; r++) {
      for (c = col; c <= lastCol; c++) {
        mySeries[x].data.push(data[r][c]);
        mySeries[x].value.push(data[r][c]);
      }
      x++;
    }
  }

  let isStacked = null;
  if (typeof stacked !== "undefined") {
    if (stacked) {
      isStacked = true;
    }
  }

  let chartTypeNEW;
  switch (chartType) {
    case "pyramid":
      chartTypeNEW = "bar";
      break;
    default:
      chartTypeNEW = chartType;
      break;
  }

  let options = {
    chart: {
      type: chartTypeNEW,
    },
    title: {
      text: title,
      style: {
        fontSize: "12px",
      },
    },
    exporting: {
      enabled: false,
    },
    credits: {
      text: "",
    },
    // exporting: {
    //     buttons: {
    //         contextButton: {
    //             symbol: 'download'
    //         }
    //     }
    // }
    tooltip: {
      enabled: true,
      shared: true,
      crosshairs: true,
      valueDecimals: 2,
      formatter: function () {
        let s = "<b>" + this.x + "</b>";

        const numberWithCommas = (x) => {
          let parts = x.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return parts.join(".");
        };

        this.points.forEach((item) => {
          s += "<br/><b>" + item.series.name + "</b>: " + numberWithCommas(item.y.toFixed(2));
          // s += '<br/><b>' + item.point.pName + '</b>: ' + (item.y).toFixed(2);
          // s += '<br/><b>' + item.point.sName + '</b>: ' + (item.y).toFixed(2);
          // s += '<br/><b>' + item.point.aName + '</b>: ' + (item.y).toFixed(2);
          // s += '<br/><b>' + item.point.tName + '</b>: ' + (item.y).toFixed(2);
        });

        return s;
      },
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: categories,
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
      series: {
        stacking: isStacked,
      },
    },
    series: mySeries,
  };

  return options;
};

/* array.sort varies with the browser and can be slow. */

/* Swaps two elements in an array. */
export function swap(array, leftIdx, rightIdx) {
  let temp = array[leftIdx];
  array[leftIdx] = array[rightIdx];
  array[rightIdx] = temp;
}

export function partition(array, left, right) {
  let pivot = array[Math.floor((right + left) / 2)]; // middle element
  let i = left; // left pointer
  let j = right; // right pointer

  while (i <= j) {
    while (array[i] < pivot) {
      i++;
    }
    while (array[j] > pivot) {
      j--;
    }
    if (i <= j) {
      swap(array, i, j);
      i++;
      j--;
    }
  }

  return i;
}

export function quickSort(array, left, right) {
  /* Allows us to avoid passing left and right parameters when we first call quickSort. Make sure you
       pass left and right parameters otherwise. */
  if (typeof left === "undefined" || typeof right === "undefined") {
    left = 0;
    right = array.length - 1;
  }

  let idx;

  if (array.length > 1) {
    idx = partition(array, left, right);

    if (left < idx - 1) {
      quickSort(array, left, idx - 1);
    }

    if (idx < right) {
      quickSort(array, idx, right);
    }
  }

  return array;
}

export const createArray = (n, bounds, value) => {
  let len = bounds.shift();

  if (n > 1) {
    return Array(len).fill(createArray(n - 1, bounds, value));
  } else {
    return Array(len).fill(value);
  }
};

// stole from internet
export function decimalPlaces(num) {
  let match = ("" + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);

  if (!match) {
    return 0;
  }

  return Math.max(
    0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0) -
      // Adjust for scientific notation.
      (match[2] ? +match[2] : 0)
  );
}

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
// function getTextWidth(text, font) {
//     // re-use canvas object for better performance
//     var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
//     var context = canvas.getContext("2d");
//     context.font = font;
//     var metrics = context.measureText(text);
//     return metrics.width;
// }

/* WARNING: Does not seem to work; values assigned to the array get copied over all the dimensions! */
// export const createArray = (n, bounds, value) => {
//
//     let len = bounds[0];
//     bounds.shift();
//
//     if (n > 1) {
//         return Array(len).fill( createArray(n-1, bounds, value) );
//     }
//     else {
//         return Array(len).fill( value );
//     }
// }

export function equalObj(object1, object2) {
  // for (var property in object1) {
  //
  //     if (!object2.hasOwnProperty(property)) {
  //
  //         return false;
  //
  //     }
  //
  // }
  //
  // return true;

  return JSON.stringify(object1) === JSON.stringify(object2);
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function getDelphiColorToHex(color) {
  if (color >= 0) {
    let hexString = color.toString(16);
    hexString = hexString.padStart(6, 0);

    let R = hexString.slice(-2);
    let G = hexString.slice(-4, -2);
    let B = hexString.slice(-6, -4);

    return "#" + (R + G + B);
  } else {
    return "";
  }
}

// not tested
export function getHexFromDelphiHexColor(hexStr) {
  let RGB;

  let R = hexStr.substr(5, 2);
  let G = hexStr.substr(3, 2);
  let B = hexStr.substr(1, 2);

  RGB = "#" + R + G + B;

  return RGB;
}

// not tested
export function getDelphiHexFromHexColor(hexStr) {
  let BGR;

  let R = hexStr.substr(1, 2);
  let G = hexStr.substr(3, 2);
  let B = hexStr.substr(5, 2);

  BGR = "#" + B + G + R;

  return BGR;
}

/* Converts a number in one base to base 10.  If the base is not specified, the base will be 16 (hex).
   Currently works up to base 16. */
export function toBase10(baseXStr, baseFrom) {
  let numBase10 = 0;

  if (typeof baseFrom === "undefined") {
    baseFrom = 16;
  }

  /* Remove #, if it exists. */
  let baseXStrNoHash = baseXStr.replace("#", "");

  /* Pos = position of current character in number, with the rightmost position being 0. */
  let pos = baseXStrNoHash.length - 1;
  let exp = 0;

  if (pos > -1) {
    while (pos !== -1) {
      /* Get the current character. */
      let n = baseXStrNoHash.substr(pos, 1);
      const nAsInt = parseInt(n);

      /* Convert n to a number if it's a letter. */
      if (isNaN(nAsInt)) {
        switch (n.toUpperCase()) {
          case "A":
            n = 10;
            break;
          case "B":
            n = 11;
            break;
          case "C":
            n = 12;
            break;
          case "D":
            n = 13;
            break;
          case "E":
            n = 14;
            break;
          case "F":
            n = 15;
            break;
          default:
            n = 1;
            break;
        }
      }

      numBase10 += n * Math.pow(baseFrom, exp);

      pos--;
      exp++;
    }
  }

  return numBase10;
}

export function shouldComponentUpdateGB(currProps, nextProps, currState, nextState) {
  const nextPropsStr = JSON.stringify(nextProps);
  const currPropsStr = JSON.stringify(currProps);

  return nextPropsStr !== currPropsStr || nextState !== currState;
}

export function equal(n1, n2, tolerance) {
  let value;

  if (typeof n1 === "string" && typeof n2 === "string") {
    value = n1 === n2;
  } else if (
    typeof n1 !== "number" ||
    typeof n2 !== "number" ||
    (typeof tolerance !== "undefined" && typeof tolerance !== "number")
  ) {
    value = false;
  } else if (typeof tolerance !== "undefined") {
    value = Math.abs(n1 - n2) <= tolerance;
  } else {
    value = !(n1 < n2 || n1 > n2);
  }

  return value;
}

export function getSexStr(currID) {
  let sexStr = "";

  switch (currID) {
    case GBConst.sex.male:
      sexStr = "Male";
      break;

    case GBConst.sex.female:
      sexStr = "Female";
      break;

    default:
      break;
  }

  return sexStr;
}

export function allZeros(arr) {
  let allZerosBool = true;

  let i = 0;
  while (i < arr.length && allZerosBool) {
    allZerosBool = equal(arr[i], 0);
    i++;
  }

  return allZerosBool;
}

export function tryFn(fn, errorFn) {
  try {
    return safeCallFn(fn);
  } catch (exception) {
    return safeCallFn(errorFn);
  }
}

export const safeCallFn = (fn) => {
  if (typeof fn == "function") {
    return fn();
  } else {
    return undefined;
  }
};

export const safeModify = (dataStructure, successFn, errorFn) => {
  if (typeof dataStructure !== "undefined") {
    safeCallFn(successFn);
  } else {
    safeCallFn(errorFn);
  }
};

export const tryRenderFn = (fn, fnName, errorFn) => {
  try {
    return safeCallFn(fn);
  } catch (exception) {
    console.log('Exception from "' + fnName + '" - ' + exception.name + ": " + exception.message);
    console.log("");

    return safeCallFn(errorFn);
  }
};

export const cloneObj = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const createUniqueKey = () => Math.trunc(Math.random() * 1e9).toString();

export const normalize = (arr, total) => {
  let sum = 0;

  for (let i = 0; i < arr.length; i++) {
    sum += arr[i];
  }

  let multiplier;
  if (equal(sum, 0)) {
    multiplier = 0;
  } else {
    multiplier = total / sum;
  }

  for (let i = 0; i < arr.length; i++) {
    arr[i] += arr[i] * multiplier;
  }
};

export const currentYear = () => {
  return new Date().getFullYear();
};

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export function formatDate(date) {
  return [padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate()), date.getFullYear()].join("/");
}

export const interpolate = (arr) => {
  if (arr.length > 2) {
    const startVal = arr[0];
    const endVal = arr[arr.length - 1];
    const diff = endVal - startVal;

    /* Linear interpolation:

           current value = first value + ((final value - first value) /
           (final index - first index)) * (current index - first index)

        */
    for (let i = 1; i <= arr.length - 2; i++) {
      const val = startVal + (diff / (arr.length - 1)) * i;
      arr[i] = val;
    }
  }

  return arr;
};

export const avg = (arr) => {
  let sum = 0;

  for (let i = 0; i < arr.length; i++) {
    sum += arr[i];
  }

  return sum / arr.length;
};

export function getFileExt(fileNameStr) {
  return fileNameStr.split(".").pop();
}

export function resourceClick(resourceStr) {
  let option = "_self";

  if (getFileExt(resourceStr).toUpperCase() === "PDF") {
    option = "_blank";
  }

  window.open("./resources/" + resourceStr, option);
}

export function URL_Click(URL_Str) {
  window.open(URL_Str, "_blank", "noopener,noreferrer");
}

export function emailAddressValid(emailAddress) {
  return /\S+@\S+\.\S+/.test(emailAddress);
}

export function sumArray(numArray) {
  let total = 0;

  for (let i = 0; i < numArray.length; i++) {
    total += numArray[i];
  }

  return total;
}

export const MergeArraysNoDuplicates = (array1, array2) => {
  return [...new Set([...array1, ...array2])];
};

export const MergeArraysWithDuplicates = (array1, array2) => {
  return [...array1, ...array2];
};

export const RemoveDuplicates = (array) => {
  return [...new Set(array)];
};

export const FindDuplicates = (array) => {
  return array.filter((item, index) => array.indexOf(item) !== index);
};

export const GBPrettyPrint = (json) => {
  // https://github.com/lydell/json-stringify-pretty-compact/blob/master/index.js + custom changes

  function stringify(obj, options) {
    options = options || {};
    var indent = JSON.stringify([1], null, get(options, "indent", 2)).slice(2, -3);
    var addMargin = get(options, "margins", false);
    var maxLength = indent === "" ? Infinity : get(options, "maxLength", 80);

    return (function _stringify(obj, currentIndent, reserved) {
      if (obj && typeof obj.toJSON === "function") {
        obj = obj.toJSON();
      }

      var string = JSON.stringify(obj);

      if (string === undefined) {
        return string;
      }

      var length = maxLength - currentIndent.length - reserved;

      if (string.length <= length) {
        if (string[0] !== "{") {
          // TGP
          if (!(string[0] === "[" && string[1] === "[")) {
            // TGP
            if (!(string[0] === "[" && string[1] === "{")) {
              // TGP
              var prettified = prettify(string, addMargin);
              if (prettified.length <= length) {
                return prettified;
              }
            }
          }
        }
      }

      if (typeof obj === "object" && obj !== null) {
        var nextIndent = currentIndent + indent;
        var items = [];
        var delimiters;
        var comma = function (array, index) {
          return index === array.length - 1 ? 0 : 1;
        };

        if (Array.isArray(obj)) {
          for (var index = 0; index < obj.length; index++) {
            items.push(_stringify(obj[index], nextIndent, comma(obj, index)) || "null");
          }
          delimiters = "[]";
        } else {
          Object.keys(obj).forEach(function (key, index, array) {
            var keyPart = JSON.stringify(key) + ": ";
            var value = _stringify(obj[key], nextIndent, keyPart.length + comma(array, index));
            if (value !== undefined) {
              items.push(keyPart + value);
            }
          });
          delimiters = "{}";
        }

        if (items.length > 0) {
          return [delimiters[0], indent + items.join(",\n" + nextIndent), delimiters[1]].join("\n" + currentIndent);
        }
      }

      return string;
    })(obj, "", 0);
  }

  // Note: This regex matches even invalid JSON strings, but since we’re
  // working on the output of `JSON.stringify` we know that only valid strings
  // are present (unless the user supplied a weird `options.indent` but in
  // that case we don’t care since the output would be invalid anyway).
  var stringOrChar = /("(?:[^\\"]|\\.)*")|[:,\][}{]/g;

  function prettify(string, addMargin) {
    var m = addMargin ? " " : "";
    var tokens = {
      "{": "{" + m,
      "[": "[" + m,
      "}": m + "}",
      "]": m + "]",
      ",": ", ",
      ":": ": ",
    };
    return string.replace(stringOrChar, function (match, string) {
      return string ? match : tokens[match];
    });
  }

  function get(options, name, defaultValue) {
    return name in options ? options[name] : defaultValue;
  }

  var str = stringify(json, { indent: 4, maxLength: Infinity });
  return str;
};

export const ConvertCSVToJSON = (csvText) => {
  var csvRows = [];
  var objArr = [];

  function parseCSVLine(line) {
    let i;
    line = line.split(",");

    // check for splits performed inside quoted strings and correct if needed
    for (i = 0; i < line.length; i++) {
      var chunk = line[i].replace(/^[\s]*|[\s]*$/g, "");
      var quote = "";
      if (chunk.charAt(0) === '"' || chunk.charAt(0) === "'") quote = chunk.charAt(0);
      if (quote !== "" && chunk.charAt(chunk.length - 1) === quote) quote = "";

      if (quote !== "") {
        var j = i + 1;

        if (j < line.length) chunk = line[j].replace(/^[\s]*|[\s]*$/g, "");

        while (j < line.length && chunk.charAt(chunk.length - 1) !== quote) {
          line[i] += "," + line[j];
          line.splice(j, 1);
          chunk = line[j].replace(/[\s]*$/g, "");
        }

        if (j < line.length) {
          line[i] += "," + line[j];
          line.splice(j, 1);
        }
      }
    }

    for (i = 0; i < line.length; i++) {
      // remove leading/trailing whitespace
      line[i] = line[i].replace(/^[\s]*|[\s]*$/g, "");

      // remove leading/trailing quotes
      if (line[i].charAt(0) === '"') line[i] = line[i].replace(/^"|"$/g, "");
      else if (line[i].charAt(0) === "'") line[i] = line[i].replace(/^'|'$/g, "");
    }

    return line;
  }

  function csvToJson(csvText) {
    let i;
    let j;
    var error = false;

    if (csvText === "") {
      error = true;
    }

    if (!error) {
      csvRows = csvText.split(/[\r\n]/g); // split into rows

      // get rid of empty rows
      for (i = 0; i < csvRows.length; i++) {
        if (csvRows[i].replace(/^[\s]*|[\s]*$/g, "") === "") {
          csvRows.splice(i, 1);
          i--;
        }
      }

      if (csvRows.length < 2) {
        error = true;
      } else {
        objArr = [];

        for (i = 0; i < csvRows.length; i++) {
          csvRows[i] = parseCSVLine(csvRows[i]);
        }

        for (i = 1; i < csvRows.length; i++) {
          if (csvRows[i].length > 0) objArr.push({});

          for (j = 0; j < csvRows[i].length; j++) {
            objArr[i - 1][csvRows[0][j]] = csvRows[i][j];
          }
        }

        // jsonText = JSON.stringify(objArr, null, "\t");

        return objArr;
      }
    }
  }

  let json = csvToJson(csvText);
  return json;
};

export const ConvertCSVTo2DArray = (csvText) => {
  var csvRows = [];

  function parseCSVLine(line) {
    let i;
    line = line.split(",");

    // check for splits performed inside quoted strings and correct if needed
    for (i = 0; i < line.length; i++) {
      var chunk = line[i].replace(/^[\s]*|[\s]*$/g, "");
      var quote = "";
      if (chunk.charAt(0) === '"' || chunk.charAt(0) === "'") quote = chunk.charAt(0);
      if (quote !== "" && chunk.charAt(chunk.length - 1) === quote) quote = "";

      if (quote !== "") {
        var j = i + 1;

        if (j < line.length) chunk = line[j].replace(/^[\s]*|[\s]*$/g, "");

        while (j < line.length && chunk.charAt(chunk.length - 1) !== quote) {
          line[i] += "," + line[j];
          line.splice(j, 1);
          chunk = line[j].replace(/[\s]*$/g, "");
        }

        if (j < line.length) {
          line[i] += "," + line[j];
          line.splice(j, 1);
        }
      }
    }

    for (i = 0; i < line.length; i++) {
      // remove leading/trailing whitespace
      line[i] = line[i].replace(/^[\s]*|[\s]*$/g, "");

      // remove leading/trailing quotes
      if (line[i].charAt(0) === '"') line[i] = line[i].replace(/^"|"$/g, "");
      else if (line[i].charAt(0) === "'") line[i] = line[i].replace(/^'|'$/g, "");
    }

    return line;
  }

  function csvTo2DArray(csvText) {
    let i;
    var error = false;

    if (csvText === "") {
      error = true;
    }

    if (!error) {
      csvRows = csvText.split(/[\r\n]/g); // split into rows

      // get rid of empty rows
      for (i = 0; i < csvRows.length; i++) {
        if (csvRows[i].replace(/^[\s]*|[\s]*$/g, "") === "") {
          csvRows.splice(i, 1);
          i--;
        }
      }

      if (csvRows.length < 2) {
        error = true;
      } else {
        for (i = 0; i < csvRows.length; i++) {
          csvRows[i] = parseCSVLine(csvRows[i]);
        }

        return csvRows;
      }
    }
  }

  let array2D = csvTo2DArray(csvText);
  return array2D;
};

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
