import { makeStyles } from "@material-ui/styles";
import { PropTypes } from "prop-types";
import { useLayoutEffect, useState } from "react";
import SuperTableUndo from "./SuperTableUndo";

const useStyles = makeStyles({
  superTable: {
    "&.ag-theme-alpine.super-table": {
      fontFamily: "Lato, sans-serif",
    },
    "& .ag-header-group-cell, & .ag-header-cell": {
      backgroundColor: "rgb(0, 55, 77)",
      color: "white",
    },
    "& .ag-header-group-cell:hover, & .ag-header-cell:hover": {
      backgroundColor: "rgb(0, 55, 77)",
      color: "white",
    },
    "& .ag-header-cell:hover": {
      backgroundColor: "rgb(0, 55, 77) !important",
    },
    "& .ag-header-cell-text": {
      whiteSpace: "normal",
    },
    "& .ag-row-odd": {
      backgroundColor: "#e6f8ff",
    },
    "& .ag-row-hover": {
      backgroundColor: "#e6f3ff",
    },
    "& .ag-cell-wrapper": {
      lineHeight: "1.2em",
    },
    "& select": {
      border: "1px solid #c3e5fd",
    },
  },
  warning: {
    border: "4px solid red",
  },
});

const parseNumber = (value) => {
  const numericValue = parseFloat(value);

  return Number.isNaN(numericValue) ? value : numericValue;
};

const reshapeGbStdTablePackTable = ({ GBUseTriangle, ...rest }, comboBoxItems, comboBoxIndices) => {
  let data = rest.tableData.value;

  if (rest.types && rest.CheckBoxState) {
    data = data.map((row, rowIndex) =>
      row.map((value, columnIndex) =>
        rest.types[rowIndex][columnIndex] !== "cb" ? value : rest.CheckBoxState[rowIndex][columnIndex]
      )
    );
  }

  if (rest.types && comboBoxIndices) {
    data = data.map((row, rowIndex) =>
      row.map((value, columnIndex) =>
        rest.types[rowIndex][columnIndex] !== "dd"
          ? value
          : comboBoxItems[rowIndex][columnIndex][comboBoxIndices[rowIndex][columnIndex]]
      )
    );
  }

  return {
    ...rest,
    types: rest.types?.map((row, rowIndex) =>
      row.map((type, columnIndex) => {
        if (type === "cb" && !rest.HasCheckBox?.[rowIndex][columnIndex]) {
          return "s";
        }

        if (type === "dd" && !rest.HasComboBox?.[rowIndex][columnIndex]) {
          return "s";
        }

        return type;
      })
    ),
    tableData: {
      value: data,
    },
    BGColors: rest.BGColors.map((row) => row.map((value) => (typeof value !== "boolean" ? value : value ? 1 : 0))),
    ComboBoxValues: comboBoxItems ?? data.map((row) => row.map((_) => 0)),
    FontBold: rest.FontStyles.map((row) =>
      row.map(({ intArray }) => (intArray?.length > 0 && intArray[0] === 0 ? 1 : 0))
    ),
    IndentRow: rest.IndentRowText?.map((value) => value.xoffset),
    LockedCells: rest.LockedCells?.map((row) =>
      row.map((value, columnIndex) => (columnIndex < rest.GBFixedCols ? true : value))
    ),
    MaxAllowedVal: rest.MaxAllowedVal?.map((row, rowIndex) =>
      row.map((value, columnIndex) => (!rest.LockedCells[rowIndex][columnIndex] ? value : Number.MIN_SAFE_INTEGER))
    ),
    MergedCells: rest.MergedCells?.map((value) => ({
      ...value,
      startCol: Math.max(value.startCol, rest.GBFixedCols),
      numCols: value.startCol < rest.GBFixedCols ? value.numCols - (rest.GBFixedCols - value.startCol) : value.numCols,
    })),
    percentage: data.map((row) => row.map((_) => 0)),
    showThousands: data.map((row) => row.map((_) => 1)),
    WordWrappedCols: rest.WordWrappedCols?.map((value) => (value ? 1 : 0)),
  };
};

const cleanValues = (packTable, replaceNanWithZero) => {
  const data = packTable.tableData.value;

  return data.map((row, rowIndex) =>
    row
      .map((value, columnIndex) =>
        replaceNanWithZero && typeof value !== "number" && packTable.types[rowIndex][columnIndex] === "n" ? 0 : value
      )
      .map((value, columnIndex) =>
        typeof value === "string" && packTable.types[rowIndex][columnIndex] === "n" ? parseNumber(value) : value
      )
  );
};

const reshapeSuperTablePackTable = (packTable, replaceNanWithZero) => {
  const data = packTable.tableData.value;

  return {
    ...packTable,
    tableData: {
      value: cleanValues(packTable, replaceNanWithZero),
    },
    CheckBoxState: data.map((row, rowIndex) =>
      row.map((value, columnIndex) => packTable.types[rowIndex][columnIndex] === "cb" && value)
    ),
  };
};

const GbStdTable = () => <div>This was an old GbStdTable that needs to be updated to Super Table</div>;

const SuperTableShim = ({ types, undo, debug, ...rest }) => {
  const [contextMenuParentSelector, setContextMenuParentSelector] = useState("body");

  const classes = useStyles();

  useLayoutEffect(() => {
    if (document.querySelector(".level-2-slide-drawer-content")) {
      setContextMenuParentSelector(".level-2-slide-drawer-content");
    } else if (document.querySelector(".level-1-slide-drawer-content")) {
      setContextMenuParentSelector(".level-1-slide-drawer-content");
    } else {
      setContextMenuParentSelector("body");
    }
  }, []);

  if (debug) {
    console.log("GBStdTable props:");
    console.log(rest);
  }

  const superTablePackTable =
    types &&
    reshapeGbStdTablePackTable(
      {
        ...rest.packTable,
        types: types,
      },
      rest.comboBoxItems,
      rest.comboBoxIndices
    );

  if (debug) {
    console.log("Super Table props:");
    console.log(superTablePackTable);
  }

  return (
    <div>
      {!types || debug ? (
        <div className={classes.warning}>
          <GbStdTable
            {...rest}
            onPackTableChanged={(packTable) => {
              if (debug) {
                console.log("GbStdTable pack table changed:");
                console.log(packTable);
              }

              rest.onPackTableChanged?.(packTable);
            }}
          />
        </div>
      ) : null}

      {types ? (
        <div style={rest.style}>
          <SuperTableUndo
            gridKey={rest.gridKey}
            width={rest.width}
            height={rest.height}
            limitWidthToContainer={rest.limitWidthToContainer}
            packTable={superTablePackTable}
            disableColumnAutosizing={true}
            activeContextMenuItems={
              !undo ? rest.activeContextMenuItems : [...(rest.activeContextMenuItems ?? []), "undo", "redo"]
            }
            contextMenuParentSelector={contextMenuParentSelector}
            undoDisabled={rest.undoDisabled}
            canUndo={undo?.canUndo()}
            canRedo={undo?.canRedo()}
            externalThousandsSeparator=","
            className={classes.superTable}
            onPackTableChanged={(packTable) => {
              if (debug) {
                console.log("Super Table pack table changed:");
                console.log(packTable);
              }

              rest.onPackTableChanged?.(reshapeSuperTablePackTable(packTable));
            }}
            onCellValueChanged={(params) => {
              const type = types[params.rowIndex][params.columnIndex];

              rest.onCellValueChanged?.({
                ...params,
                type,
              });

              if (type === "cb") {
                rest.onCheckboxToggled?.({
                  ...params,
                  columnId: `field${params.columnIndex + 1}`,
                });
              }
            }}
            onUndo={() => {
              rest.onPackTableChanged(undo?.undo());
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

SuperTableShim.propTypes = {
  gridKey: PropTypes.string,
  limitWidthToContainer: PropTypes.bool,
  tableData: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.array),
  }),
  types: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  LockedCells: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.bool)),
  CheckBoxState: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.bool)),
  BGColors: PropTypes.arrayOf(PropTypes.array),
  replaceNanWithZero: PropTypes.bool,
  activeContextMenuItems: PropTypes.arrayOf(PropTypes.string),
  undoDisabled: PropTypes.bool,
  debug: PropTypes.bool,
};

SuperTableShim.defaultProps = {
  limitWidthToContainer: false,
  replaceNanWithZero: false,
  activeContextMenuItems: ["copy", "copy-all", "copy-to-json", "decrement-decimal", "increment-decimal"],
  debug: false,
};

export default SuperTableShim;
