import { PropTypes } from "prop-types";

import * as gbu from "../../GB/GBUtil";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import TButton from "../../common/TButton";
import * as Theme from "../../../app/Theme";

import { promisify } from "../../../utilities";

import { getProjDefaultsAsync } from "../../../api/server_calls";
import { onCalculateAsync } from "../NonComponents/PICalc";

import { includeCostList } from "../Tables/PICostCatLiteTable";

// Note: This object is also used to determine what keys to copy when resetting to default
const defaultCategory = {
  monthlyCost: 0,
  ARVs: 0,
  adherenceSupport: 0,
  initiation: {
    personnel: 0,
    visitLabs: 0,
    recurrent: 0,
    capital: 0,
    total: 0,
  },
  continuation: {
    personnel: 0,
    visitLabs: 0,
    recurrent: 0,
    capital: 0,
    total: 0,
  },
  annual: 0,
};

const PIResetDefaultCostsBtn = (props) => {
  const onModVarsChange = promisify(props.onModVarsChange);
  const onCalculatingChange = promisify(props.onCalculatingChange);

  const onResetClick = async () => {
    try {
      await onCalculatingChange(true);

      const modVarObjList = gbu.cloneObj(props.modVarObjList);

      // Fetch defaults from server, inc. hoop jumping
      const countryCode = piasu.getModVarValue(modVarObjList, "PI_CountryISO");
      const { modvars: defaults } = await getProjDefaultsAsync({ countryCode });

      const defModVars = await onCalculateAsync(defaults, "", props.onDialogChange);

      // Adjust methods to include any changes in the session
      const methods = piasu.getModVarValue(modVarObjList, "PI_Methods");
      let defMethods = piasu.getModVarValue(defModVars, "PI_Methods");
      defMethods = defMethods.map((v) => methods.find((m) => m.mstID === v.mstID) ?? v);
      piasu.setModVarValue(defModVars, "PI_Methods", defMethods);

      piasu.setCostCategoriesLiteValues(defModVars, new Set(defMethods.map((v) => v.mstID)));

      // Fixup categories
      const curCatLite = piasu.getModVarValue(modVarObjList, "PI_CostCategories_Lite");
      const defCatLite = piasu.getModVarValue(defModVars, "PI_CostCategories_Lite");

      for (const method of curCatLite) {
        if (method.categories === undefined || method.categories.length === 0) {
          console.error(`Method ${method.mstID} has no categories`);
          continue;
        }

        if (method.mstID === pisc.includedCCLite) {
          continue;
        }

        const defMethod = defCatLite.find((v) => v.mstID === method.mstID);
        for (const cat of method.categories) {
          const defCat = defMethod?.categories?.find((v) => v.mstID === cat.mstID) ?? defaultCategory;

          for (const k in defaultCategory) {
            cat[k] = defCat[k];
          }
        }

        let costCatObjList = piasu.getModVarValue(modVarObjList, pisc.costCategoriesLiteMVTag);
        for (const id in includeCostList) {
          if (id === includeCostList["skip"]) {
            continue;
          }
          piasu.setCostIncludedCostsLite(includeCostList[id], method.mstID, costCatObjList, true);
        }
      }

      // Re-calculate to fix anything caused by the above
      const newModVars = await onCalculateAsync(modVarObjList, "", props.onDialogChange);

      // Update mod vars
      await onModVarsChange(newModVars, true);
    } catch (err) {
      console.log(err);
    } finally {
      await onCalculatingChange(false);
    }
  };

  return (
    <TButton
      caption={RS(SC.GB_stResetToDefault)}
      key={"defaultCostsBtn"}
      onClick={onResetClick}
      containerStyle={{
        display: "inline-block",
        marginRight: 10,
        marginTop: 0,
      }}
      style={{
        backgroundColor: Theme.PI_TertiaryColor,
        padding: 0,
      }}
      {...props}
    />
  );
};

PIResetDefaultCostsBtn.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
  onModVarsChange: PropTypes.func,
  onDialogChange: PropTypes.func,
  onCalculatingChange: PropTypes.func,
};

PIResetDefaultCostsBtn.defaultProps = {
  onModVarsChange: () => {},
  onDialogChange: () => {},
  onCalculatingChange: () => {},
};

export default PIResetDefaultCostsBtn;
